import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";

// API
import { getDogWithPedigree } from "../../api/publicRoutes";

// DATA
import { kuvaServer } from "../../constants/values";

// COMPONENTS
import PlaceholderImage from "../../images/placeholder.png";
import {
  Typography,
  Grid,
  Paper,
  Chip,
  Link,
  Box,
  Table,
  TableRow,
} from "@mui/material";
import { Pedigree4g } from "./pedigreeDataTemplate";
import { useTranslation } from "react-i18next";

function Cell({ dog, emphasize = false, image = false, generation }) {
  const { name, regNumber, hasPhoto, _id, regClass, additionalInfo } = dog;
  const img = hasPhoto ? `${kuvaServer}${_id}.jpg` : PlaceholderImage;

  const style = {
    height: (200 / (generation + 1)) * 2,
    border: "1px solid black",
  };

  // Return empty Grid element so the layout isn't affected.
  // I'm assuming the column items are given constant height.
  if (!_id) return <Grid className="cell" sx={style}></Grid>;

  // Render Chip when dog is a treedog
  if (!regClass)
    return (
      <Grid item className="cell" display="flex" sx={style}>
        <Box display="flex" alignItems="center" alignContent="center">
          <Chip
            label="Sukupuukoira"
            size="small"
            variant="filled"
            style={{
              fontSize: "0.7rem",
              float: "right",
            }}
          />
          <Typography>{name}</Typography>
          <Typography variant="caption">
            {regNumber}
            <br />
            {typeof dog.additionalInfo === "string" && dog.additionalInfo}
          </Typography>
        </Box>
      </Grid>
    );

  // Render the full cell
  return (
    <Grid item className="cell" display="flex" sx={style}>
      <Box display="flex" alignItems="center" alignContent="center">
        {/* Disabled image rendering because it's huge without styles */}
        {false && emphasize && image && (
          <Paper component="img" src={img} alt={`Koiran ${name} kuva`} />
        )}
        <Typography
          component={Link}
          href={`/dog/${regNumber}`}
          variant={emphasize ? "h5" : "h6"}
        >
          {name}
        </Typography>
        <Typography variant="caption">
          {regNumber}
          <br />
          {typeof additionalInfo === "string" && additionalInfo}
        </Typography>
      </Box>
    </Grid>
  );
}

function Column({ dogs, emphasize, generation }) {
  return (
    <>
      {dogs.map((dog, i) => (
        <Cell key={i} dog={dog} emphasize={emphasize} generation={generation} />
      ))}
    </>
  );
}

function Row({ generations, emphasizeFirst, imageInFirst }) {
  return (
    <Table>
      {generations.map((dogs, gen) => (
        <TableRow key={gen}>
          <Column
            emphasize={gen <= emphasizeFirst}
            image={gen <= imageInFirst}
            dogs={dogs}
            generation={gen}
          />
        </TableRow>
      ))}
    </Table>
  );
}

export default function PedigreeTable({
  generations = 4,
  emphasizeFirst = 1,
  imageInFirst = 1,
  dog: rootDog,
  nodeSize,
  translate,
  zoom,
  renderCustomNodeElement,
}) {
  const { _id } = rootDog;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dog, setDog] = useState();

  useEffect(() => {
    if (!loading && _id != dog?._id) return setLoading(true);
    if (!loading || !_id) return;
    getDogWithPedigree(_id, { maxDepth: generations })
      .then((res) => setDog(res))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [loading, rootDog?._id]);

  if (loading) return <Typography>Loading...</Typography>;

  const hasPedigree = !!dog?.sire || !!dog?.dam;
  const gens = groupByGeneration(dog, generations);
  const data = Pedigree4g(dog);

  return (
    <>
      {hasPedigree ? (
        <>
          <Tree
            pathFunc="step"
            orientation="horizontal"
            nodeSize={nodeSize}
            separation={{ siblings: 2, nonSiblings: 2 }}
            zoom={zoom}
            translate={translate}
            renderCustomNodeElement={renderCustomNodeElement}
            data={data}
          />
          {/* <Container>
            <Row
              generations={gens}
              emphasizeFirst={emphasizeFirst}
              imageInFirst={imageInFirst}
            />
          </Container> */}
        </>
      ) : (
        <Typography variant="caption" align="center">
          {t("noPedigree")}
        </Typography>
      )}
    </>
  );
}

function groupByGeneration(
  dog,
  generations,
  generationsArray = [],
  iteration = 0
) {
  if (iteration === generations) return generationsArray;
  if (!generationsArray[iteration]) generationsArray[iteration] = [];

  const sire = dog?.sire || {};
  const dam = dog?.dam || {};

  generationsArray[iteration].push(sire);
  generationsArray[iteration].push(dam);

  generationsArray = groupByGeneration(
    sire,
    generations,
    generationsArray,
    iteration + 1
  );
  generationsArray = groupByGeneration(
    dam,
    generations,
    generationsArray,
    iteration + 1
  );
  return generationsArray;
}
