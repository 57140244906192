import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import PentueCard from "../elements/LitterCard";
import BreederInfoBox from "../components/breederProfileComponents/BreederInfoBox";
import BreederStatistics from "../components/breederProfileComponents/BreederStatistics";

// MUI
import { Typography, Grid, Paper, Divider } from "@mui/material";

// Recoil
import { breederState, littersByBreederIdState } from "../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function BreederProfile() {
  const { t } = useTranslation();
  const { id } = useParams();

  const breeder = useRecoilValue(breederState(id));
  const litters = useRecoilValue(littersByBreederIdState(id));

  return (
    <Grid container direction={{ xs: "column", sm: "row" }} spacing={5}>
      <Grid item xs={12}>
        <BreederInfoBox breeder={breeder} />
      </Grid>
      <Grid item xs={12} md={4}>
        <BreederStatistics breeder={breeder} litters={litters} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("breedersLitters")}</Typography>
        </Divider>
        {litters.length ? (
          litters?.map((litter) => (
            <PentueCard litter={litter} key={litter._id} />
          ))
        ) : (
          <Paper square sx={{ p: 2 }}>
            <Typography variant="body">{t("noLitters")}</Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
