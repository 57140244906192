import { Search } from "@mui/icons-material";
import { Button, Link, Menu } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StyledMenuItem from "../../../elements/styledMenuItem";

export default function BrowseMenu() {
  const { t } = useTranslation();
  //const [items, setItems] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const items = [
    <StyledMenuItem key={0} divider component={Link} href="/dogs">
      {t("Dogs")}
    </StyledMenuItem>,
    <StyledMenuItem key={1} divider component={Link} href="/litters">
      {t("Litters")}
    </StyledMenuItem>,
    <StyledMenuItem key={2} component={Link} href="/breeders">
      {t("Breeders")}
    </StyledMenuItem>,
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        name="browse"
        color="white"
        onClick={handleClick}
        startIcon={<Search />}
      >
        {t("browse")}
      </Button>
      <Menu id="browse" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items}
      </Menu>
    </>
  );
}
