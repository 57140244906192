import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  getKruValueLabel,
  getOrganizationNamesById,
} from "../../constants/helpers";

// MUI
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";

import { error } from "../../constants/texts";
import {
  getCertificateById,
  getOrganizationById,
} from "../../api/publicRoutes";
import { useRecoilValue } from "recoil";
import {
  populatedCertsArrayState,
  populatedOrgsArrayState,
} from "../../recoil/publicStates";

export default function RegistrationInfo({ dog }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const orgs = useRecoilValue(populatedOrgsArrayState(dog.organizations));
  const certs = useRecoilValue(populatedCertsArrayState(dog.certificates));

  return (
    orgs && (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell variant="head">{t("registrationNumber")}</TableCell>
              <TableCell>{dog.regNumber || error}</TableCell>
              <TableCell variant="head">{t("microchipNumber")}</TableCell>
              <TableCell>{dog.microchipNumber || error}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("registry")}</TableCell>
              <TableCell>
                {orgs ? orgs.map((v) => v.name).join(", ") : error}
              </TableCell>
              <TableCell variant="head">{t("registrationType")}</TableCell>
              <TableCell>
                {getKruValueLabel(dog?.regType, lang) || error}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("registrationDate")}</TableCell>
              <TableCell colSpan={3}>
                {dog.createdAt
                  ? dayjs(dog.createdAt).format("DD.MM.YYYY")
                  : error}
              </TableCell>
            </TableRow>
            <TableRow hover></TableRow>
            <TableRow hover></TableRow>
            <TableRow hover>
              {dog.regOther?.length ? (
                <>
                  <TableCell variant="head">{t("inOtherRegistries")}</TableCell>
                  <TableCell colSpan="3">{dog?.regOther?.join(", ")}</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("certificates")}</TableCell>
              <TableCell>
                {certs ? certs.map((v) => v.name).join(", ") : error}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
