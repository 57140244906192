import React, { useState } from "react";

import { getOrganizationColumns } from "../../../components/adminDashboardComponents/adminDatagridColumns";

// Components
import FormModal from "../../../components/adminDashboardComponents/formModal";

// Routes
import { createNewOrganization } from "../../../api/adminRoutes";

// MUI
import { Typography, Divider, Grid, Button, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";
import OrganizationForm from "../forms/organizationForm";

import { snackBarState } from "../../../recoil/globalStates";
import { adminAllOrgsState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

function AdminAllOrganizations() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const results = useRecoilValue(adminAllOrgsState);

  const handleRowClick = (params) => {
    window.location = `/admin/organization/${params.row._id}`;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    if (confirm(t("confirmModalClose"))) {
      setModalOpen(false);
    }
  };

  const handleSubmit = (accessToken, updatedOrg) => {
    createNewOrganization(updatedOrg, { accessToken }).then((res) => {
      if (res.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("creationSuccess", { docType: "Organization" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">All Organizations</Typography>
          </Divider>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={handleModalOpen}
            >
              Add Organization
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={results}
            getRowId={(row) => row._id}
            columns={getOrganizationColumns("organization")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
      <FormModal open={modalOpen} onClose={handleModalClose}>
        <OrganizationForm
          action="add"
          handleModalClose={handleModalClose}
          emitHandleSubmit={handleSubmit}
        />
      </FormModal>
    </>
  );
}

export default AdminAllOrganizations;
