import * as React from "react";
import { useTranslation } from "react-i18next";

import { verifyUser } from "../../api/privateRoutes";

import {
  Typography,
  Grid,
  Alert,
  AlertTitle,
  Button,
  Checkbox,
} from "@mui/material";

export default function VerifyPerson({ user, token }) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleCheckBox = (e) => setChecked(e.target.checked);

  const errorNoData = t("noData");

  const handleSubmit = () => {
    const fetchData = async () => {
      await verifyUser(checked, { accessToken: token }).then((res) => {
        if (res.error) {
          setError({ error: true, msg: res.message });
        } else {
          setError(false);
          setTimeout((window.location = `/user`), 3000);
        }
      });
    };
    fetchData();
  };

  return (
    <>
      <Grid item xs={12}>
        <Alert severity="warning" sx={{ p: 2, mb: 2 }}>
          <AlertTitle>{t("confirmContactInformation")}</AlertTitle>
          {t("confirmContactInformationText")}
          <br />
          <br />
          {t("confirmContactInformationText2")}
        </Alert>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h5">{t("name")}</Typography>
        <Typography variant="body">{user?.name || errorNoData}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">{t("customerNumber")}</Typography>
        <Typography variant="body">{user?.asiakasnro || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">{t("email")}</Typography>
        <Typography variant="body">{user?.email || errorNoData}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">{t("phoneNumber")}</Typography>
        <Typography variant="body">{user?.phone || errorNoData}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{t("address")}</Typography>
        <Typography variant="body">{user?.address || errorNoData}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">{t("postalCode")}</Typography>
        <Typography variant="body">
          {user?.postalCode || errorNoData}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5">{t("city")}</Typography>
        <Typography variant="body">{user?.city || errorNoData}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          variant="contained"
          color="primary"
          onClick={handleCheckBox}
        />{" "}
        <Typography variant="body">
          {t("confirmContractInformationConfirmationText")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {error.error && (
          <Alert severity="error" sx={{ width: "100%" }}>
            {t("error")}: {error.msg}
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!checked}
          sx={{ float: "right" }}
        >
          {t("confirm")}
        </Button>
      </Grid>
    </>
  );
}
