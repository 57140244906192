import React from "react";

// Routes
import { updateGeneticResult } from "../../../api/adminRoutes";

// Components
import GenetictestForm from "../forms/genetictestForm";

// MUI
import { Button, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { useRecoilState, useRecoilValue } from "recoil";
import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";

import { useTranslation } from "react-i18next";

function AdminGeneticResultView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const result = useRecoilValue(selectedDocByIdState("geneticResult"));

  const handleSubmit = (accessToken, updatedResult, id) => {
    const updateResultData = async (accessToken, updatedResult, id) => {
      await updateGeneticResult(id, updatedResult, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Genetic result" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateResultData(accessToken, updatedResult, id);
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/allgeneticresults"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <GenetictestForm
            action="edit"
            result={result}
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminGeneticResultView;
