import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useTranslation } from "react-i18next";

// MUI
import { Grid, Paper, Typography, Button, Stack } from "@mui/material";
import TempLittersList from "../components/usersRegistrationsComponents/templittersList";
import TempDogsList from "../components/usersRegistrationsComponents/tempdogsList";

import { useRecoilValue } from "recoil";
import {
  usersTempDogsState,
  usersTempLittersState,
} from "../recoil/privateStates";

function Registrations() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  const tempDogs = useRecoilValue(usersTempDogsState);
  const tempLitters = useRecoilValue(usersTempLittersState);

  return (
    <>
      {isAuthenticated && (
        <Paper sx={{ p: 3 }}>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, md: 4 }}
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h2">{t("registrationStatuses")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Button
                  href="/litter-registration"
                  variant="outlined"
                  color="primary"
                >
                  {t("registerLitter")}
                </Button>
                <Button
                  href="/dog-registration"
                  variant="outlined"
                  color="primary"
                >
                  {t("registerDog")}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TempLittersList tempLitters={tempLitters} />
            </Grid>
            <Grid item xs={12}>
              <TempDogsList tempDogs={tempDogs} />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default Registrations;
