// PRIVATE API ADMIN ROUTES
import { request, adminUrl } from "./common";

// ------------------------  ATTACHMENTS  ---------------------------

export async function getAttachments({ accessToken, query }) {
  const url = adminUrl`/attachments/query`;
  return request({ url, accessToken, body: { query } });
}

// --------------------------- TEMPLITTERS ---------------------------

export async function getAllTempLitters({ accessToken, query = {}, options = { populate: "sire dam puppies breeder verifications" } }) {
  const url = adminUrl`/templitters/query`;
  return request({ url, accessToken, body: { query, options }, });
}

export async function getTempLitterById(_id, { accessToken, options = { populate: "sire dam puppies breeder verifications" } }) {
  const url = adminUrl`/templitters/byId/${_id}`;
  return request({ url, accessToken, body: { options }, });
}

export async function updateTempLitter(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/templitters/byId/${_id}`;
  return request({ url, accessToken, body: { update, options }, });
}

export async function createNewTempLitter(document, { accessToken }) {
  const url = adminUrl`/templitters/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteTempLitter(_id, { accessToken }) {
  const url = adminUrl`/templitters/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

export async function acceptTempLitter(_id, { accessToken }) {
  const url = adminUrl`/templitters/acceptById/${_id}`;
  return request({ url, accessToken, });
}

// --------------------------- LITTERS ------------------------------

export async function getAllLitters({ accessToken, query, options = { populate: "sire dam puppies breeder" } }) {
  const url = adminUrl`/litters/query`;
  return request({ url, accessToken, body: { query, options }, });
}

export async function getLitterById(_id, { accessToken, options = { populate: "sire dam puppies breeder" } }) {
  const url = adminUrl`/litters/byId/${_id}`;
  return request({ url, accessToken, body: { options }, });
}

export async function updateLitter(_id, litter, { accessToken, options = {} }) {
  const url = adminUrl`/litters/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update: litter, options }, });
}

export async function deleteLitter(_id, { accessToken }) {
  const url = adminUrl`/litters/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// --------------------------- TEMPDOGS ------------------------------

export async function getAllTempDogs({ accessToken, query, options = {
  populate: "breeder owners sire dam color organizations certificates.certificate"
} }) {
  const url = adminUrl`/tempdogs/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getTempDogById(_id, { accessToken, options = { populate: "sire dam organizations certificates.certificate" } }) {
  const url = adminUrl`/tempdogs/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateTempDog(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/tempdogs/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewTempDog(document, { accessToken }) {
  const url = adminUrl`/tempdogs/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteTempDog(_id, { accessToken }) {
  const url = adminUrl`/tempdogs/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

export async function acceptTempDog(_id, { accessToken }) {
  const url = adminUrl`/tempdogs/acceptById/${_id}`;
  return request({ url, accessToken, });
}

// --------------------------- DOGS ------------------------------

export async function getAllDogs({ accessToken, query = {}, options = {
  populate: "breeder owners sire dam color organizations certificates.certificate"
} }) {
  const url = adminUrl`/dogs/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getDogById(_id, { accessToken, options = { populate: "sire dam organizations certificates.certificate" } }) {
  const url = adminUrl`/dogs/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateDog(_id, update, { accessToken, options }) {
  const url = adminUrl`/dogs/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function deleteDog(_id, { accessToken }) {
  const url = adminUrl`/dogs/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// --------------------------- USERS ------------------------------

export async function getAllUsers({ accessToken, query = {}, options = {
  lean: true, populate: "organizations certificates.certificate",
} }) {
  const url = adminUrl`/users/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getUserById(_id, { accessToken, options = {
  lean: true, populate: "organizations certificates.certificate",
} }) {
  const url = adminUrl`/users/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateUser(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/users/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewUser(document, { accessToken }) {
  const url = adminUrl`/users/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteUser(_id, { accessToken }) {
  const url = adminUrl`/users/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  HEALTH RESULTS  ---------------------------

export async function getAllHealthResults({ accessToken, query = {}, options = {
  populate: "parentDoc",
} }) {
  const url = adminUrl`/healthtests/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getHealthResultById(_id, { accessToken, options = {} }) {
  const url = adminUrl`/healthtests/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateHealthResult(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/healthtests/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewHealthResult(document, { accessToken }) {
  const url = adminUrl`/healthtests/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteHealthResult(_id, { accessToken }) {
  const url = adminUrl`/healthtests/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  GENETIC RESULTS  ---------------------------

export async function getAllGeneticResults({ accessToken, query = {}, options = {
  populate: "parentDoc",
} }) {
  const url = adminUrl`/geneticstests/query`;
  return request({ url, accessToken, body: { query, options } });
}

// GET GENETIC RESULT BY ID
export async function getGeneticResultById(_id, { accessToken, options = {} }) {
  const url = adminUrl`/geneticstests/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

// UPDATE GENETIC RESULT
export async function updateGeneticResult(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/geneticstests/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

// CREATE NEW GENETIC RESULT
export async function createNewGeneticResult(document, { accessToken }) {
  const url = adminUrl`/geneticstests/new`;
  return request({ url, accessToken, body: { document } });
}

// DELETE GENETIC RESULT
export async function deleteGeneticResult(_id, { accessToken }) {
  const url = adminUrl`/geneticstests/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  COMPETITION RESULTS  ---------------------------

export async function getAllCompetitionResults({ accessToken, query = {}, options = {
  populate: "parentDoc",
} }) {
  const url = adminUrl`/competitionresults/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getCompetitionResultById(_id, { accessToken, options = {} }) {
  const url = adminUrl`/competitionresults/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateCompetitionResult(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/competitionresults/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewCompetitionResult(document, { accessToken }) {
  const url = adminUrl`/competitionresults/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteCompetitionResult(_id, { accessToken }) {
  const url = adminUrl`/competitionresults/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  TREEDOGS  ---------------------------

export async function getAllTreedogs({ accessToken, query = {}, options = {
  populate: "sire dam",
} }) {
  const url = adminUrl`/treedogs/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getTreedogById(_id, { accessToken, options = { populate: "sire dam" } }) {
  const url = adminUrl`/treedogs/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateTreedog(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/treedogs/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewTreedog(document, { accessToken }) {
  const url = adminUrl`/treedogs/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteTreedog(_id, { accessToken }) {
  const url = adminUrl`/treedogs/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  ORGANIZATIONS  ---------------------------

export async function getAllOrganizations({ accessToken, query = {}, options = {
  populate: "parentOrg",
} }) {
  const url = adminUrl`/organizations/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getOrganizationById(_id, { accessToken, options = { populate: "parentOrg", } }) {
  const url = adminUrl`/organizations/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateOrganization(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/organizations/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewOrganization(document, { accessToken }) {
  const url = adminUrl`/organizations/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteOrganization(_id, { accessToken }) {
  const url = adminUrl`/organizations/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}

// ------------------------  CERTIFICATES  ---------------------------

export async function getAllCertificates({ accessToken, query = {}, options = {
  populate: "issuerOrg",
} }) {
  const url = adminUrl`/certificates/query`;
  return request({ url, accessToken, body: { query, options } });
}

export async function getCertificateById(_id, { accessToken, options = { populate: "issuerOrg", } }) {
  const url = adminUrl`/certificates/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function updateCertificate(_id, update, { accessToken, options = {} }) {
  const url = adminUrl`/certificates/byId/${_id}`;
  return request({ url, method: "PATCH", accessToken, body: { update, options } });
}

export async function createNewCertificate(document, { accessToken }) {
  const url = adminUrl`/certificates/new`;
  return request({ url, accessToken, body: { document } });
}

export async function deleteCertificate(_id, { accessToken }) {
  const url = adminUrl`/certificates/byId/${_id}`;
  return request({ url, method: "DELETE", accessToken });
}