import React, { Component } from "react";
import dayjs from "dayjs";
import { useTranslation, withTranslation } from "react-i18next";

// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function CompetitionInfo({ dog }) {
  const { t } = useTranslation();
  const results = dog.competition;
  const error = " ";

  const resultsHeaders =
    results && results?.length > 0 ? (
      <TableRow>
        <TableCell>{t("date")}</TableCell>
        <TableCell>{t("location")}</TableCell>
        <TableCell>{t("eventType")}</TableCell>
        <TableCell>{t("class")}</TableCell>
        <TableCell>{t("result")}</TableCell>
        <TableCell>{t("additionalInfo")}</TableCell>
      </TableRow>
    ) : null;

  const resultsMarkup =
    results && results?.length > 0 ? (
      results.map((result) => (
        <TableRow key={result._id} hover>
          <TableCell>
            {dayjs(result?.date).format("DD.MM.YYYY") || error}
          </TableCell>
          <TableCell>{result?.city || error}</TableCell>
          <TableCell>{result?.sport || error}</TableCell>
          <TableCell>{result?.class || error}</TableCell>
          <TableCell>{result?.result || error}</TableCell>
          <TableCell>{result?.additionalInfo || error}</TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={4}>{t("noResults")}</TableCell>
      </TableRow>
    );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>{resultsHeaders}</TableHead>
        <TableBody>{resultsMarkup}</TableBody>
      </Table>
    </TableContainer>
  );
}
