export const countries = [
  {
    en: "Afghanistan",
    fi: "Afghanistan",
    code: "AF",
  },
  {
    en: "Åland Islands",
    fi: "Ahvenanmaa",
    code: "AX",
  },
  {
    en: "Albania",
    fi: "Albania",
    code: "AL",
  },
  {
    en: "Algeria",
    fi: "Algeria",
    code: "DZ",
  },
  {
    en: "Andorra",
    fi: "Andorra",
    code: "AD",
  },
  {
    en: "Angola",
    fi: "Angola",
    code: "AO",
  },
  {
    en: "Anguilla",
    fi: "Anguilla",
    code: "AI",
  },
  {
    en: "Argentina",
    fi: "Argentina",
    code: "AR",
  },
  {
    en: "Armenia",
    fi: "Armenia",
    code: "AM",
  },
  {
    en: "Aruba",
    fi: "Aruba",
    code: "AW",
  },
  {
    en: "Australia",
    fi: "Australia",
    code: "AU",
  },
  {
    en: "Austria",
    fi: "Itävalta",
    code: "AT",
  },
  {
    en: "Azerbaijan",
    fi: "Azerbaijan",
    code: "AZ",
  },
  {
    en: "Bahamas",
    fi: "Bahamasaaret",
    code: "BS",
  },
  {
    en: "Bahrain",
    fi: "Bahrain",
    code: "BH",
  },
  {
    en: "Bangladesh",
    fi: "Bangladesh",
    code: "BD",
  },
  {
    en: "Barbados",
    fi: "Barbados",
    code: "BB",
  },
  {
    en: "Belarus",
    fi: "Valko-Venäjä",
    code: "BY",
  },
  {
    en: "Belgium",
    fi: "Belgia",
    code: "BE",
  },
  {
    en: "Belize",
    fi: "Belize",
    code: "BZ",
  },
  {
    en: "Benin",
    fi: "Benin",
    code: "BJ",
  },
  {
    en: "Bermuda",
    fi: "Bermuda",
    code: "BM",
  },
  {
    en: "Bhutan",
    fi: "Bhutan",
    code: "BT",
  },
  {
    en: "Bolivia",
    fi: "Bolivia",
    code: "BO",
  },
  {
    en: "Bosnia and Herzegovina",
    fi: "Bosnia ja Herzegovina",
    code: "BA",
  },
  {
    en: "Botswana",
    fi: "Botswana",
    code: "BW",
  },
  {
    en: "Brazil",
    fi: "Brasilia",
    code: "BR",
  },
  {
    en: "Brunei Darussalam",
    fi: "Brunei Darussalam",
    code: "BN",
  },
  {
    en: "Bulgaria",
    fi: "Bulgaria",
    code: "BG",
  },
  {
    en: "Burkina Faso",
    fi: "Burkina Faso",
    code: "BF",
  },
  {
    en: "Burundi",
    fi: "Burundi",
    code: "BI",
  },
  {
    en: "Cambodia",
    fi: "Kambodža",
    code: "KH",
  },
  {
    en: "Cameroon",
    fi: "Kamerun",
    code: "CM",
  },
  {
    en: "Canada",
    fi: "Kanada",
    code: "CA",
  },
  {
    en: "Cape Verde",
    fi: "Cape Verde",
    code: "CV",
  },
  {
    en: "Cayman Islands",
    fi: "Caymansaaret",
    code: "KY",
  },
  {
    en: "Central African Republic",
    fi: "Keski-Afrikan tasavalta",
    code: "CF",
  },
  {
    en: "Chad",
    fi: "Chad",
    code: "TD",
  },
  {
    en: "Chile",
    fi: "Chile",
    code: "CL",
  },
  {
    en: "China",
    fi: "Kiina",
    code: "CN",
  },
  {
    en: "Colombia",
    fi: "Kolumbia",
    code: "CO",
  },
  {
    en: "Comoros",
    fi: "Comoros",
    code: "KM",
  },
  {
    en: "Congo",
    fi: "Kongo",
    code: "CG",
  },
  {
    en: "Cook Islands",
    fi: "Cooksaaret",
    code: "CK",
  },
  {
    en: "Costa Rica",
    fi: "Costa Rica",
    code: "CR",
  },
  {
    en: "Cote D'Ivoire",
    fi: "Cote D'Ivoire",
    code: "CI",
  },
  {
    en: "Croatia",
    fi: "Kroatia",
    code: "HR",
  },
  {
    en: "Cuba",
    fi: "Kuuba",
    code: "CU",
  },
  {
    en: "Cyprus",
    fi: "Kypros",
    code: "CY",
  },
  {
    en: "Czech Republic",
    fi: "Tšekki",
    code: "CZ",
  },
  {
    en: "Denmark",
    fi: "Tanska",
    code: "DK",
  },
  {
    en: "Djibouti",
    fi: "Djibouti",
    code: "DJ",
  },
  {
    en: "Dominica",
    fi: "Dominica",
    code: "DM",
  },
  {
    en: "Dominican Republic",
    fi: "Dominikaaninen tasavalta",
    code: "DO",
  },
  {
    en: "Ecuador",
    fi: "Ecuador",
    code: "EC",
  },
  {
    en: "Egypt",
    fi: "Egypti",
    code: "EG",
  },
  {
    en: "El Salvador",
    fi: "El Salvador",
    code: "SV",
  },
  {
    en: "Equatorial Guinea",
    fi: "Päiväntasaajan Guinea",
    code: "GQ",
  },
  {
    en: "Eritrea",
    fi: "Eritrea",
    code: "ER",
  },
  {
    en: "Estonia",
    fi: "Viro",
    code: "EE",
  },
  {
    en: "Ethiopia",
    fi: "Etiopia",
    code: "ET",
  },
  {
    en: "Falkland Islands (Malvinas)",
    fi: "Falklandinsaaret (Malvinassaaret)",
    code: "FK",
  },
  {
    en: "Faroe Islands",
    fi: "Färsaaret",
    code: "FO",
  },
  {
    en: "Fiji",
    fi: "Fiji",
    code: "FJ",
  },
  {
    en: "Finland",
    fi: "Suomi",
    code: "FI",
  },
  {
    en: "France",
    fi: "Ranska",
    code: "FR",
  },
  {
    en: "French Guiana",
    fi: "Ranskan Guayana",
    code: "GF",
  },
  {
    en: "French Polynesia",
    fi: "Ranskan Polynesia",
    code: "PF",
  },
  {
    en: "Gabon",
    fi: "Gabon",
    code: "GA",
  },
  {
    en: "Gambia",
    fi: "Gambia",
    code: "GM",
  },
  {
    en: "Georgia",
    fi: "Georgia",
    code: "GE",
  },
  {
    en: "Germany",
    fi: "Saksa",
    code: "DE",
  },
  {
    en: "Ghana",
    fi: "Ghana",
    code: "GH",
  },
  {
    en: "Gibraltar",
    fi: "Gibraltar",
    code: "GI",
  },
  {
    en: "Greece",
    fi: "Kreikka",
    code: "GR",
  },
  {
    en: "Greenland",
    fi: "Grönlanti",
    code: "GL",
  },
  {
    en: "Grenada",
    fi: "Grenada",
    code: "GD",
  },
  {
    en: "Guadeloupe",
    fi: "Guadeloupe",
    code: "GP",
  },
  {
    en: "Guam",
    fi: "Guam",
    code: "GU",
  },
  {
    en: "Guatemala",
    fi: "Guatemala",
    code: "GT",
  },
  {
    en: "Guernsey",
    fi: "Guernsey",
    code: "GG",
  },
  {
    en: "Guinea",
    fi: "Guinea",
    code: "GN",
  },
  {
    en: "Guinea-Bissau",
    fi: "Guinea-Bissau",
    code: "GW",
  },
  {
    en: "Guyana",
    fi: "Guyana",
    code: "GY",
  },
  {
    en: "Haiti",
    fi: "Haiti",
    code: "HT",
  },
  {
    en: "Vatican City State",
    fi: "Vatikaani",
    code: "VA",
  },
  {
    en: "Honduras",
    fi: "Honduras",
    code: "HN",
  },
  {
    en: "Hong Kong",
    fi: "Hong Kong",
    code: "HK",
  },
  {
    en: "Hungary",
    fi: "Unkari",
    code: "HU",
  },
  {
    en: "Iceland",
    fi: "Islanti",
    code: "IS",
  },
  {
    en: "India",
    fi: "Intia",
    code: "IN",
  },
  {
    en: "Indonesia",
    fi: "Indonesia",
    code: "ID",
  },
  {
    en: "Iran",
    fi: "Iran",
    code: "IR",
  },
  {
    en: "Iraq",
    fi: "Irak",
    code: "IQ",
  },
  {
    en: "Ireland",
    fi: "Irlanti",
    code: "IE",
  },
  {
    en: "Isle of Man",
    fi: "Mansaari",
    code: "IM",
  },
  {
    en: "Israel",
    fi: "Israel",
    code: "IL",
  },
  {
    en: "Italy",
    fi: "Italia",
    code: "IT",
  },
  {
    en: "Jamaica",
    fi: "Jamaika",
    code: "JM",
  },
  {
    en: "Japan",
    fi: "Japani",
    code: "JP",
  },
  {
    en: "Jersey",
    fi: "Jersey",
    code: "JE",
  },
  {
    en: "Jordan",
    fi: "Jordan",
    code: "JO",
  },
  {
    en: "Kazakhstan",
    fi: "Kazakstan",
    code: "KZ",
  },
  {
    en: "Kenya",
    fi: "Kenia",
    code: "KE",
  },
  {
    en: "Kiribati",
    fi: "Kiribati",
    code: "KI",
  },
  {
    en: "North Korea",
    fi: "Pohjois-Korea",
    code: "KP",
  },
  {
    en: "South Korea",
    fi: "Etelä-Korea",
    code: "KR",
  },
  {
    en: "Kuwait",
    fi: "Kuwait",
    code: "KW",
  },
  {
    en: "Kyrgyzstan",
    fi: "Kirgisia",
    code: "KG",
  },
  {
    en: "Laos",
    fi: "Laos",
    code: "LA",
  },
  {
    en: "Latvia",
    fi: "Latvia",
    code: "LV",
  },
  {
    en: "Lebanon",
    fi: "Libanon",
    code: "LB",
  },
  {
    en: "Lesotho",
    fi: "Lesotho",
    code: "LS",
  },
  {
    en: "Liberia",
    fi: "Liberia",
    code: "LR",
  },
  {
    en: "Libya",
    fi: "Libya",
    code: "LY",
  },
  {
    en: "Liechtenstein",
    fi: "Liechtenstein",
    code: "LI",
  },
  {
    en: "Lithuania",
    fi: "Liettua",
    code: "LT",
  },
  {
    en: "Luxembourg",
    fi: "Luxemburg",
    code: "LU",
  },
  {
    en: "Macao",
    fi: "Macao",
    code: "MO",
  },
  {
    en: "North Macedonia",
    fi: "Pohjois-Makedonia",
    code: "MK",
  },
  {
    en: "Madagascar",
    fi: "Madagaskar",
    code: "MG",
  },
  {
    en: "Malawi",
    fi: "Malawi",
    code: "MW",
  },
  {
    en: "Malaysia",
    fi: "Malesia",
    code: "MY",
  },
  {
    en: "Maldives",
    fi: "Malediivit",
    code: "MV",
  },
  {
    en: "Mali",
    fi: "Mali",
    code: "ML",
  },
  {
    en: "Malta",
    fi: "Malta",
    code: "MT",
  },
  {
    en: "Martinique",
    fi: "Martinique",
    code: "MQ",
  },
  {
    en: "Mauritania",
    fi: "Mauritania",
    code: "MR",
  },
  {
    en: "Mauritius",
    fi: "Mauritius",
    code: "MU",
  },
  {
    en: "Mayotte",
    fi: "Mayotte",
    code: "YT",
  },
  {
    en: "Mexico",
    fi: "Meksiko",
    code: "MX",
  },
  {
    en: "Moldova",
    fi: "Moldova",
    code: "MD",
  },
  {
    en: "Monaco",
    fi: "Monako",
    code: "MC",
  },
  {
    en: "Mongolia",
    fi: "Mongolia",
    code: "MN",
  },
  {
    en: "Montserrat",
    fi: "Montserrat",
    code: "MS",
  },
  {
    en: "Morocco",
    fi: "Marokko",
    code: "MA",
  },
  {
    en: "Mozambique",
    fi: "Mozambik",
    code: "MZ",
  },
  {
    en: "Myanmar",
    fi: "Myanmar",
    code: "MM",
  },
  {
    en: "Namibia",
    fi: "Namibia",
    code: "NA",
  },
  {
    en: "Nauru",
    fi: "Nauru",
    code: "NR",
  },
  {
    en: "Nepal",
    fi: "Nepal",
    code: "NP",
  },
  {
    en: "Netherlands",
    fi: "Alankomaat",
    code: "NL",
  },
  {
    en: "New Caledonia",
    fi: "Uusi-Kaledonia",
    code: "NC",
  },
  {
    en: "New Zealand",
    fi: "Uusi-Seelanti",
    code: "NZ",
  },
  {
    en: "Nicaragua",
    fi: "Nicaragua",
    code: "NI",
  },
  {
    en: "Niger",
    fi: "Niger",
    code: "NE",
  },
  {
    en: "Nigeria",
    fi: "Nigeria",
    code: "NG",
  },
  {
    en: "Niue",
    fi: "Niue",
    code: "NU",
  },
  {
    en: "Norway",
    fi: "Norja",
    code: "NO",
  },
  {
    en: "Oman",
    fi: "Oman",
    code: "OM",
  },
  {
    en: "Pakistan",
    fi: "Pakistan",
    code: "PK",
  },
  {
    en: "Palau",
    fi: "Palau",
    code: "PW",
  },
  {
    en: "Palestine",
    fi: "Palestiina",
    code: "PS",
  },
  {
    en: "Panama",
    fi: "Panama",
    code: "PA",
  },
  {
    en: "Papua New Guinea",
    fi: "Papua-Uusi-Guinea",
    code: "PG",
  },
  {
    en: "Paraguay",
    fi: "Paraguay",
    code: "PY",
  },
  {
    en: "Peru",
    fi: "Peru",
    code: "PE",
  },
  {
    en: "Philippines",
    fi: "Filippiinit",
    code: "PH",
  },
  {
    en: "Poland",
    fi: "Puola",
    code: "PL",
  },
  {
    en: "Portugal",
    fi: "Portugali",
    code: "PT",
  },
  {
    en: "Puerto Rico",
    fi: "Puerto Rico",
    code: "PR",
  },
  {
    en: "Qatar",
    fi: "Qatar",
    code: "QA",
  },
  {
    en: "Reunion",
    fi: "Reunion",
    code: "RE",
  },
  {
    en: "Romania",
    fi: "Romania",
    code: "RO",
  },
  {
    en: "Russia",
    fi: "Venäjä",
    code: "RU",
  },
  {
    en: "Rwanda",
    fi: "Ruanda",
    code: "RW",
  },
  {
    en: "Samoa",
    fi: "Samoa",
    code: "WS",
  },
  {
    en: "San Marino",
    fi: "San Marino",
    code: "SM",
  },
  {
    en: "Saudi Arabia",
    fi: "Saudi-Arabia",
    code: "SA",
  },
  {
    en: "Senegal",
    fi: "Senegal",
    code: "SN",
  },
  {
    en: "Serbia and Montenegro",
    fi: "Serbia ja Montenegro",
    code: "CS",
  },
  {
    en: "Seychelles",
    fi: "Seychellit",
    code: "SC",
  },
  {
    en: "Sierra Leone",
    fi: "Sierra Leone",
    code: "SL",
  },
  {
    en: "Singapore",
    fi: "Singapore",
    code: "SG",
  },
  {
    en: "Slovakia",
    fi: "Slovakia",
    code: "SK",
  },
  {
    en: "Slovenia",
    fi: "Slovenia",
    code: "SI",
  },
  {
    en: "Solomon Islands",
    fi: "Salomonsaaret",
    code: "SB",
  },
  {
    en: "Somalia",
    fi: "Somalia",
    code: "SO",
  },
  {
    en: "South Africa",
    fi: "Etelä-Afrikka",
    code: "ZA",
  },
  {
    en: "Spain",
    fi: "Espanja",
    code: "ES",
  },
  {
    en: "Sri Lanka",
    fi: "Sri Lanka",
    code: "LK",
  },
  {
    en: "Sudan",
    fi: "Sudan",
    code: "SD",
  },
  {
    en: "Suriname",
    fi: "Suriname",
    code: "SR",
  },
  {
    en: "Swaziland",
    fi: "Swasimaa",
    code: "SZ",
  },
  {
    en: "Sweden",
    fi: "Ruotsi",
    code: "SE",
  },
  {
    en: "Switzerland",
    fi: "Sveitsi",
    code: "CH",
  },
  {
    en: "Syria",
    fi: "Syyria",
    code: "SY",
  },
  {
    en: "Taiwan",
    fi: "Taiwan",
    code: "TW",
  },
  {
    en: "Tajikistan",
    fi: "Tajikistan",
    code: "TJ",
  },
  {
    en: "Tanzania",
    fi: "Tansania",
    code: "TZ",
  },
  {
    en: "Thailand",
    fi: "Thaimaa",
    code: "TH",
  },
  {
    en: "Timor-Leste",
    fi: "Timor-Leste",
    code: "TL",
  },
  {
    en: "Togo",
    fi: "Togo",
    code: "TG",
  },
  {
    en: "Tokelau",
    fi: "Tokelau",
    code: "TK",
  },
  {
    en: "Tonga",
    fi: "Tonga",
    code: "TO",
  },
  {
    en: "Trinidad and Tobago",
    fi: "Trinidad ja Tobago",
    code: "TT",
  },
  {
    en: "Tunisia",
    fi: "Tunisia",
    code: "TN",
  },
  {
    en: "Turkey",
    fi: "Turkki",
    code: "TR",
  },
  {
    en: "Turkmenistan",
    fi: "Turkmenistan",
    code: "TM",
  },
  {
    en: "Tuvalu",
    fi: "Tuvalu",
    code: "TV",
  },
  {
    en: "Uganda",
    fi: "Uganda",
    code: "UG",
  },
  {
    en: "Ukraine",
    fi: "Ukraina",
    code: "UA",
  },
  {
    en: "United Arab Emirates",
    fi: "Arabiemiirikunnat",
    code: "AE",
  },
  {
    en: "United Kingdom",
    fi: "Iso-Britannia",
    code: "GB",
  },
  {
    en: "United States",
    fi: "Yhdysvallat",
    code: "US",
  },
  {
    en: "Uruguay",
    fi: "Uruguay",
    code: "UY",
  },
  {
    en: "Uzbekistan",
    fi: "Uzbekistan",
    code: "UZ",
  },
  {
    en: "Vanuatu",
    fi: "Vanuatu",
    code: "VU",
  },
  {
    en: "Venezuela",
    fi: "Venezuela",
    code: "VE",
  },
  {
    en: "Vietnam",
    fi: "Vietnam",
    code: "VN",
  },
  {
    en: "Yemen",
    fi: "Jemen",
    code: "YE",
  },
  {
    en: "Zambia",
    fi: "Zambia",
    code: "ZM",
  },
  {
    en: "Zimbabwe",
    fi: "Zimbabwe",
    code: "ZW",
  },
];
