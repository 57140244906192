import React, { useState } from "react";
import dayjs from "dayjs";

// Routes
import { deleteTreedog } from "../../../api/adminRoutes";

// Components
import ParentAutocomplete from "../../../components/formComponents/parentAutocomplete";

// MUI
import {
  Grid,
  TextField,
  Divider,
  Typography,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Stack,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { DeleteForever, Save } from "@mui/icons-material";

import {
  accessTokenState,
  showSubmitState,
  snackBarState,
} from "../../../recoil/globalStates";
import {
  adminAllDogsState,
  adminAllTreedogsState,
} from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

// This form is also used for tempDogs
function TreedogForm({ dog, action, emitHandleSubmit, handleModalClose }) {
  const accessToken = useRecoilValue(accessTokenState);
  const [showSubmit, setShowSubmit] = useRecoilState(showSubmitState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const [initialDog, setInitialDog] = useState(action === "edit" ? dog : null);
  const [updatedDog, setUpdatedDog] = useState(false);

  const dogs = useRecoilValue(adminAllDogsState);
  const treedogs = useRecoilValue(adminAllTreedogsState);

  const [selectedSire, setSelectedSire] = useState(dog?.sire || null);
  const [selectedDam, setSelectedDam] = useState(dog?.dam || null);
  const [parentType, setParentType] = useState({
    sire: dog?.sireDocType || "",
    dam: dog?.damDocType || "",
  });

  // most of the fields that do not require special treatment are handled here
  // initial dog is the "original" full dog document, while updatedDog contains only the
  // updated data we want to send to patch api endpoint
  const handleChange = (event) => {
    setInitialDog({
      ...initialDog,
      [event.target.name]: event.target.value,
    });
    setUpdatedDog({
      ...updatedDog,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
  };

  const handleParentTypeChange = (event) => {
    const docType = event.target.name + "DocType";
    setParentType({
      ...parentType,
      [event.target.name]: event.target.value,
    });
    setUpdatedDog({
      ...updatedDog,
      [docType]: event.target.value,
    });
  };

  const handleAutocompleteChange = (event, newValue, name) => {
    if (name === "sire") {
      setSelectedSire(newValue);
      if (newValue) {
        setUpdatedDog({
          ...updatedDog,
          sire: newValue._id,
        });
      }
      setShowSubmit(true);
    } else if (name === "dam") {
      setSelectedDam(newValue);
      if (newValue) {
        setUpdatedDog({
          ...updatedDog,
          dam: newValue._id,
        });
      }
      setShowSubmit(true);
    }
  };

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedDog, initialDog._id);
    setShowSubmit(false);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this treedog? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteTreedog(initialDog._id, { accessToken }).then((res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            alert(`Treedog has been removed.`);
            setTimeout((window.location = `/admin/alltreedogs`), 3000);
          }
        });
      };
      deleteData();
    }
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} TREEDOG{" "}
              {action === "edit" && initialDog?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        {action === "edit" && (
          <>
            <Grid item xs={7}>
              <Typography variant="caption">
                Created on{" "}
                {initialDog?.createdAt &&
                  dayjs(initialDog?.createdAt).format("DD.MM.YYYY")}
              </Typography>
              <br />
              <Typography variant="caption">
                Updated on{" "}
                {initialDog?.updatedAt &&
                  dayjs(initialDog?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Registration number"
            name="regNumber"
            placeholder="Registration number"
            value={initialDog?.regNumber || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Registry"
            name="registry"
            placeholder="Registry"
            value={initialDog?.registry || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Microchip number"
            name="microchipNumber"
            placeholder="Microchip number"
            value={initialDog?.microchipNumber || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Dog's name"
            name="name"
            placeholder="Dog's name"
            value={initialDog?.name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Additional information"
            name="additionalInfo"
            placeholder="Additional information"
            value={initialDog?.additionalInfo || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Family</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <FormLabel>{"Sire's document type"}</FormLabel>
          <RadioGroup
            row
            name="sire"
            value={parentType.sire}
            onChange={handleParentTypeChange}
            sx={{ pb: 2 }}
          >
            <FormControlLabel
              value="dog"
              control={<Radio />}
              label={<Typography variant="body">Dog</Typography>}
            />
            <FormControlLabel
              value="treedog"
              control={<Radio />}
              label={<Typography variant="body">Treedog</Typography>}
            />
          </RadioGroup>
          <ParentAutocomplete
            label="Sire"
            name="sire"
            handleChange={handleAutocompleteChange}
            options={parentType.sire === "dog" ? dogs.males : treedogs}
            value={selectedSire}
          />
          <List sx={{ mt: 2 }}>
            <ListItem>
              <Typography variant="h5">Sire preview</Typography>
            </ListItem>
            {selectedSire && (
              <ListItem>
                <ListItemText
                  primary={`${selectedSire.name} ${selectedSire.regNumber}`}
                  secondary={`${selectedSire._id}`}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={6}>
          <FormLabel>{"Dam's document type"}</FormLabel>
          <RadioGroup
            row
            name="dam"
            value={parentType.dam}
            onChange={handleParentTypeChange}
            sx={{ pb: 2 }}
          >
            <FormControlLabel
              value="dog"
              control={<Radio />}
              label={<Typography variant="body">Dog</Typography>}
            />
            <FormControlLabel
              value="treedog"
              control={<Radio />}
              label={<Typography variant="body">Treedog</Typography>}
            />
          </RadioGroup>
          <ParentAutocomplete
            label="Dam"
            name="dam"
            handleChange={handleAutocompleteChange}
            options={parentType.dam === "dog" ? dogs.females : treedogs}
            value={selectedDam}
          />
          <List sx={{ mt: 2 }}>
            <ListItem>
              <Typography variant="h5">Dam preview</Typography>
            </ListItem>
            {selectedDam && (
              <ListItem>
                <ListItemText
                  primary={`${selectedDam.name} ${selectedDam.regNumber}`}
                  secondary={`${selectedDam._id}`}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedDog
              ? JSON.stringify(updatedDog, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <Save fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default TreedogForm;
