import React from "react";
import { useTranslation } from "react-i18next";

// Components
import NewestDogs from "../components/homeComponents/NewestDogs";
import NewestLitters from "../components/homeComponents/NewestLitters";
import InfoBox from "../components/homeComponents/InfoBox";

// MUI
import { Typography, Divider, Grid } from "@mui/material";

export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <InfoBox />
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        <Grid item xs={7}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Newest dogs")}</Typography>
          </Divider>
          <NewestDogs />
        </Grid>
        <Grid item xs={5}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Newest litters")}</Typography>
          </Divider>
          <NewestLitters />
        </Grid>
      </Grid>
    </>
  );
}
