import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
  Stack,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { deleteOrganization } from "../../../api/adminRoutes";

import {
  accessTokenState,
  showSubmitState,
  snackBarState,
} from "../../../recoil/globalStates";
import { adminAllOrgsState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

function OrganizationForm({ action, org, emitHandleSubmit, handleModalClose }) {
  const accessToken = useRecoilValue(accessTokenState);
  const [showSubmit, setShowSubmit] = useRecoilState(showSubmitState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const organizations = useRecoilValue(adminAllOrgsState);
  const [selectedOrg, setSelectedOrg] = useState(org.parentOrg || null);

  const [initialOrg, setInitialOrg] = useState(org);
  const [updatedOrg, setUpdatedOrg] = useState(false);

  const handleChange = (event) => {
    setUpdatedOrg({
      ...updatedOrg,
      [event.target.name]: event.target.value,
    });
    setInitialOrg({
      ...initialOrg,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
  };

  const handleParentOrgChange = (event, newValue) => {
    setSelectedOrg(newValue);
    setUpdatedOrg({
      ...updatedOrg,
      parentOrg: newValue._id,
    });
    setShowSubmit(true);
  };

  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedOrg, initialOrg._id);
    setShowSubmit(false);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this organization? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteOrganization(initialOrg._id, { accessToken }).then((res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            alert(`Organization has been removed.`);
            setTimeout((window.location = `/admin/allorganizations`), 3000);
          }
        });
      };
      deleteData();
    }
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} ORGANIZATION{" "}
              {action === "edit" && initialOrg?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        {action === "edit" && (
          <Grid item xs={12}>
            <Stack direction="column" alignItems="flex-start" sx={{ pb: 2 }}>
              <Typography variant="caption">
                Created{" "}
                {initialOrg?.createdAt &&
                  dayjs(initialOrg?.createdAt).format("DD.MM.YYYY")}
              </Typography>

              <Typography variant="caption">
                Updated{" "}
                {initialOrg?.updatedAt &&
                  dayjs(initialOrg?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Name of organization"
            name="name"
            placeholder="Name of organization"
            value={initialOrg?.name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              fullWidth
              label="Type"
              name="type"
              placeholder="Type"
              value={initialOrg?.type || ""}
              onChange={handleChange}
            >
              <MenuItem key={0} value="member">
                Member
              </MenuItem>
              <MenuItem key={1} value="partner">
                Partner
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            freeSolo
            label="Parent organization"
            name="parentOrg"
            placeholder="Parent organization"
            options={organizations}
            isOptionEqualToValue={(option, value) => option._id == value}
            getOptionLabel={(option) => `${option.name}`}
            value={selectedOrg}
            onChange={handleParentOrgChange}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Parent organization" />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Organization URL"
            name="url"
            placeholder="Organization URL"
            value={initialOrg?.url || ""}
            onChange={handleChange}
            helperText="Include https:// in the url"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedOrg
              ? JSON.stringify(updatedOrg, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <SaveIcon fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default OrganizationForm;
