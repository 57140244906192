import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  Fab,
  ListItemText,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

import { Controller, useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";
import {
  attachmentsState,
  filepathsState,
} from "../../../recoil/registrationStates";

export default function LitterAttachments() {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const { verificationNeeded } = watch();

  const [attachments, setAttachments] = useRecoilState(attachmentsState);
  const [filePaths, setFilePaths] = useRecoilState(filepathsState);

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    // Create FormData objects ready to send
    let newAttachments = [];
    for (const file of files) {
      const formData = new FormData();
      formData.set(`attachment`, file);
      formData.set(`category`, name);
      newAttachments.push(formData);
    }
    setAttachments({ ...attachments, [name]: newAttachments });

    // Handle file name display
    const path = !files.length
      ? t("noFileSelected")
      : files.length === 1
      ? `${files[0].name}`
      : `${files.length} ${t("filesSelected")}`;

    setFilePaths({ ...filePaths, [name]: path });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("attachmentsInfoText")}
            <br />
            <br />
            {t("attachmentsInfoText2")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <List disablePadding>
            {verificationNeeded?.sire === "attachment" && (
              <ListItem
                disablePadding
                secondaryAction={
                  lessThanSmall ? (
                    <Fab color="primary" size="small" component="label">
                      <Upload />
                      <Controller
                        name="sireCertificate"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            hidden
                            accept="image/*,.pdf"
                            type="file"
                          />
                        )}
                      />
                    </Fab>
                  ) : (
                    <Button variant="contained" component="label">
                      {t("uploadFile")}
                      <input
                        hidden
                        accept="image/*,.pdf"
                        name="sireCertificate"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Button>
                  )
                }
              >
                <ListItemText
                  primary={`${t("siresMatingCertificate")} *`}
                  secondary={filePaths.sireCertificate || t("noFileSelected")}
                ></ListItemText>
              </ListItem>
            )}
            {verificationNeeded?.dam === "attachment" && (
              <ListItem
                disablePadding
                secondaryAction={
                  lessThanSmall ? (
                    <Fab color="primary" size="small" component="label">
                      <Upload />
                      <input
                        hidden
                        accept="image/*,.pdf"
                        name="damCertificate"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Fab>
                  ) : (
                    <Button variant="contained" component="label">
                      {t("uploadFile")}
                      <input
                        hidden
                        accept="image/*,.pdf"
                        name="damCertificate"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Button>
                  )
                }
              >
                <ListItemText
                  primary={`${t("damsMatingCertificate")} *`}
                  secondary={filePaths.damCertificate || t("noFileSelected")}
                ></ListItemText>
              </ListItem>
            )}
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="microchipCertificates"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="microchipCertificates"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={`${t("puppiesMicrochipCertificates")} *`}
                secondary={
                  filePaths.microchipCertificates || t("noFileSelected")
                }
              ></ListItemText>
            </ListItem>
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={`${t("otherAttachments")}`}
                secondary={filePaths.misc || t("noFileSelected")}
              ></ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
