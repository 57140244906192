import React from "react";

import { Grid } from "@mui/material";
// Steps
import LitterBreeder from "../formComponents/litterBreeder";
import LitterParents from "../formComponents/litterParents";
import LitterPuppies from "../formComponents/litterPuppies";
import LitterRegistry from "../formComponents/litterRegistry";

export default function LitterRegistrationForm() {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LitterBreeder />
        </Grid>
        <Grid item xs={12}>
          <LitterRegistry />
        </Grid>
        <Grid item xs={12}>
          <LitterParents />
        </Grid>
        <Grid item xs={12}>
          <LitterPuppies />
        </Grid>
      </Grid>
    </>
  );
}
