import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import { LoaderOverlay } from "../../elements/Loader";

// MUI
import {
  Alert,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorAlert } from "../../elements/alerts";
import { emailValidation } from "../../constants/regex";
import { useTranslation } from "react-i18next";
import { createVerification } from "../../api/privateRoutes";
import { useRecoilState, useRecoilValue } from "recoil";
import { accessTokenState, snackBarState } from "../../recoil/globalStates";

function OwnershipChangeForm({ id, setModalOpen, handleModalClose }) {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const accessToken = useRecoilValue(accessTokenState);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");

  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    if (!emailValidation.test(email)) {
      setEmailError({ error: true, msg: t("invalidEmail") });
    } else {
      setEmailError(false);

      const verification = {
        operation: "verifyOwnerReceive",
        parentDoc: id,
        parentDocType: "dog",
        email: email,
      };

      createVerification(verification, { accessToken }).then((res) => {
        if (res.error) {
          const errorMsg =
            res.message === "User not found" ? t("emailNotFound") : res.message;
          setError({ error: true, msg: errorMsg });
        } else {
          setSnackbarState({
            message: t("ownershipChangeSent"),
            severity: "success",
            open: true,
          });
          setModalOpen(false);
        }
      });
    }
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2">{t("changeOwnership")}</Typography>
                <Button size="small" color="gray" onClick={handleModalClose}>
                  {t("close")}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info" sx={{ mb: 2 }}>
                {t("ownershipChangeInfoText4")}
              </Alert>
              <Typography variant="body">
                {t("ownershipChangeInfoText")}
              </Typography>
              <br />
              <br />
              <Typography variant="body">
                {t("ownershipChangeInfoText3")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("email")}
                name="email"
                placeholder={t("email")}
                value={email}
                onChange={handleChange}
                error={emailError.error}
                helperText={emailError.error ? emailError.msg : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Box>{error.error && <ErrorAlert msg={error.msg} />}</Box>
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ mr: 3 }}
                    onClick={handleModalClose}
                  >
                    {t("cancel")}
                  </Button>
                </Box>
                <Box>
                  <Button variant="contained" onClick={handleSubmit}>
                    {t("submit")}
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default OwnershipChangeForm;
