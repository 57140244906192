import React, { useState } from "react";

// MUI
import { Button, Menu, MenuItem, Stack, Divider } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

function AdminNav() {
  const [items, setItems] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // TODO: Add keys
  const dogItems = [
    <MenuItem divider key={"alldogs"} component={Button} href="/admin/alldogs">
      Dogs
    </MenuItem>,
    <MenuItem
      divider
      key={"alltempdogs"}
      component={Button}
      href="/admin/alltempdogs"
    >
      Temp Dogs
    </MenuItem>,
    <MenuItem key={"alltreedogs"} component={Button} href="/admin/alltreedogs">
      Tree Dogs
    </MenuItem>,
  ];
  const litterItems = [
    <MenuItem key={0} component={Button} divider href="/admin/alllitters">
      Litters
    </MenuItem>,
    <MenuItem key={1} component={Button} href="/admin/alltemplitters">
      Temp Litters
    </MenuItem>,
  ];
  const resultItems = [
    <MenuItem key={0} component={Button} divider href="/admin/allhealthresults">
      Health results
    </MenuItem>,
    <MenuItem
      key={1}
      component={Button}
      divider
      href="/admin/allgeneticresults"
    >
      Genetic results
    </MenuItem>,
    <MenuItem key={2} component={Button} href="/admin/allcompetitionresults">
      Competition results
    </MenuItem>,
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (event.target.name === "dogs") {
      setItems(dogItems);
    } else if (event.target.name === "litters") {
      setItems(litterItems);
    } else if (event.target.name === "results") {
      setItems(resultItems);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        divider={<Divider orientation="vertical" flexItem />}
        direction="row"
        spacing={1}
        sx={{ alignItems: "center", justifyContent: "flex-end" }}
      >
        <Button name="dashboard" href="/admin/dashboard">
          Dashboard
        </Button>
        <Button name="dogs" onClick={handleClick} endIcon={<ArrowDropDown />}>
          Dogs
        </Button>
        <Button
          name="litters"
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
        >
          Litters
        </Button>
        <Button name="users" href="/admin/allusers">
          Users
        </Button>
        <Button name="organizations" href="/admin/allorganizations">
          Organizations
        </Button>
        <Button name="certificates" href="/admin/allcertificates">
          Certificates
        </Button>
        <Button
          name="results"
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
        >
          Results
        </Button>
        <Button name="pedigreetool" href="/admin/pedigreetool">
          Pedigree Tool
        </Button>

        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ p: 6 }}
        >
          {items}
        </Menu>
      </Stack>
    </>
  );
}
export default AdminNav;
