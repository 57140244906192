import React, { Component } from "react";
import dayjs from "dayjs";
import i18next from "i18next";

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { withTranslation } from "react-i18next";
class LitterInfoBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      litter: this.props.litter,
    };
  }

  // Calculate dogs age in yrs and months between parents bd and litters bd
  calculateAge(syntaika) {
    const litter = this.props.litter;
    const vanhsyntaika = dayjs(syntaika);
    const pentuesyntaika = dayjs(litter.dateOfBirth);
    const ikäkuukausina = pentuesyntaika.diff(vanhsyntaika, "months");

    const vuodet = ikäkuukausina / 12;
    const kuukaudet = ikäkuukausina % 12;
    return (
      Math.floor(vuodet) +
      " " +
      i18next.t("yrs") +
      " " +
      kuukaudet +
      " " +
      i18next.t("mos")
    );
  }
  render() {
    const { t } = this.props;
    const litter = this.state.litter;
    const error = " ";

    const uroksia = litter?.puppies?.reduce(
      (total, puppy) => (total += puppy.sex === "male"),
      0
    );
    const narttuja = litter?.puppies?.reduce(
      (total, puppy) => (total += puppy.sex === "female"),
      0
    );
    const sirenikä = litter?.sire
      ? this.calculateAge(litter?.sire?.dateOfBirth)
      : " ";
    const damnikä = litter?.dam
      ? this.calculateAge(litter?.dam?.dateOfBirth)
      : " ";

    return (
      <>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("litterInformation")}</Typography>
        </Divider>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell variant="head">{t("dob")}</TableCell>
                <TableCell>
                  {" "}
                  {dayjs(litter?.dateOfBirth).format("DD.MM.YYYY") || error}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell variant="head">{t("males")}</TableCell>
                <TableCell>{uroksia || 0}</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell variant="head">{t("females")}</TableCell>
                <TableCell>{narttuja || 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">{t("siresAgeAtDob")}</TableCell>
                <TableCell>{sirenikä || error}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">{t("damsAgeAtDob")}</TableCell>
                <TableCell>{damnikä || error}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default withTranslation()(LitterInfoBox);
