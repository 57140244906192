import * as React from "react";

import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";

export default function MyDogsList({ dogs, handleSelection }) {
  return (
    dogs && (
      <>
        <List
          sx={{
            maxHeight: { xs: "none", sm: 600 },
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {dogs.map((dog, i) => (
            <div key={i}>
              <ListItemButton onClick={() => handleSelection(dog)}>
                <ListItem>
                  <ListItemText
                    sx={{ flex: "none", width: "200px", whiteSpace: "nowrap" }}
                    primary={
                      <Typography
                        variant="h5"
                        sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {dog?.name || ""}
                      </Typography>
                    }
                    secondary={dog?.regNumber || ""}
                  ></ListItemText>
                </ListItem>
              </ListItemButton>
              <Divider variant="middle" />
            </div>
          ))}
        </List>
      </>
    )
  );
}
