import React from "react";
import dayjs from "dayjs";
import { getKruValueLabel } from "../../constants/helpers";
import i18next from "i18next";

import { Link, Typography, Tooltip } from "@mui/material";

export const breederColumns = [
  { field: "_id", headerName: "ID", hide: true },
  {
    field: "breederId",
    headerName: i18next.t("breederId"),
    renderCell: (params) => (
      <Link
        style={{
          textDecoration: "none",
          color: "#004569",
        }}
        to={`/breeder/${params.row._id}`}
      >
        {params.value}
      </Link>
    ),
    width: 140,
  },
  {
    field: "name",
    headerName: i18next.t("breedersName"),
    width: 200,
    renderCell: (params) => (
      <Link
        style={{
          textDecoration: "none",
          color: "#004569",
        }}
        href={`/breeder/${params.row._id}`}
      >
        {params.value}
      </Link>
    ),
  },
  {
    field: "breedingPrefix",
    headerName: i18next.t("breederPrefix"),
    width: 200,
    renderCell: (params) => (
      <Link
        style={{
          textDecoration: "none",
          color: "#004569",
        }}
        href={`/breeder/${params.row._id}`}
      >
        {params.value}
      </Link>
    ),
  },
  {
    field: "certificates",
    headerName: i18next.t("certificates"),
    width: 200,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const mappedValues = params.value.map((val) => val.certificate.name);

      return mappedValues.join(", ");
    },
  },
  {
    field: "city",
    headerName: i18next.t("location"),
    width: 150,
  },
  /*  {
    field: "country",
    headerName: i18next.t("country"),
    width: 150,
  }, */
];
