import { publicUrl, request } from "./common";

// ---------------------------- SPECIALS ------------------------------

export async function getDbStats() {
  const url = publicUrl`/`;
  return request({ url, method: "GET" });
}

// ---------------------------- KRU VALUES ------------------------------

export async function getKruvalues({ query = {}, options = {} } = {}) {
  const url = publicUrl`/kruvalues`;
  return request({ url, body: { query, options } });
}

export async function getKruValuesByCategory(category, { options = { limit: 0 } } = {}) {
  return getKruvalues({ query: { category: { $in: category } }, options });
}

// ---------------------------------- DOGS ------------------------------

export async function getDogs({ query = {}, options = {} } = {}) {
  const url = publicUrl`/dogs`;
  return request({ url, body: { query, options } });
}

export async function getDogByRegNumber(regNumber) {
  const res = await getDogs({ query: { regNumber }, options: { limit: 1 }, });
  return res?.[0] || res;
}

export async function getDogBySex(sex) {
  return getDogs({ query: { sex } });
}

// ----------------------------- ORGANIZATIONS ------------------------------

export async function getAllOrganizations({ query = {}, options = {} } = {}) {
  const url = publicUrl`/organizations`;
  return request({ url, body: { query, options } });
}

export async function getOrganizationById(_id, { options = {} } = {}) {
  if (!_id) return;
  const url = publicUrl`/organizations/byId/${_id}`;
  return request({ url, body: { options } });
}

// ----------------------------- CERTIFICATES ------------------------------

export async function getCertificateById(_id, { options = {} } = {}) {
  const url = publicUrl`/certificates/byId/${_id}`;
  return request({ url, body: { options, } });
}

// ---------------------------------- BREEDER ------------------------------

export async function getAllBreeders() {
  const url = publicUrl`/users`;
  return request({
    url, body: {
      isBreeder: true,
      options: { limit: 0, },
    }
  });
}

export async function getBreederById(_id, { options = {} } = {}) {
  const url = publicUrl`/users/byId/${_id}`;
  return request({ url, body: { options } });
}

export async function getBreedersWithLitters({ options = {} } = {}) {
  const url = publicUrl`/breedersWithLitters`;
  return request({ url, body: { options } });
}

// ---------------------------------- LITTERS ------------------------------

export async function getLitters({ query = {}, options = {} } = {}) {
  const url = publicUrl`/litters`;
  return request({ url, body: { query, options } });
}

export async function getLitterById(_id, { options = {} } = {}) {
  const url = publicUrl`/litters/byId/${_id}`;
  return request({ url, body: { options } });
}

export async function getLittersByBreederId(id) {
  const url = publicUrl`/litters`;
  return request({ url, body: { query: { breeder: id } } });
}

export async function getLittersByParentId(id, sex) {
  const url = publicUrl`/litters`;
  const key = sex === "female" ? "dam" : "sire";
  return request({ url, body: { query: { [key]: id } } });
}

export async function getLitterBySiblingId(_id) {
  const url = publicUrl`/litters`;
  return request({ url, body: { query: { puppies: _id, } } });
}

// ---------------------------------- PEDIGREE ------------------------------

export async function getDogById(_id, { options = {} } = {}) {
  const url = publicUrl`/dogs/byId/${_id}`;
  return request({ url, body: { options }, });
}

export async function getDogWithPedigree(_id, maxDepth = 4) {
  return getDogById(_id, { options: { autopopulate: true, maxDepth, lean: false, } });
}

// ---------------------------------- SEARCH ------------------------------

export async function getSearchResults(query, options = {}) {
  const url = publicUrl`/dogs`;
  return request({ url, body: { options, query: { $text: { $search: query } } } });
}
