import React from "react";
import dayjs from "dayjs";
import { getKruValueLabel } from "../../constants/helpers";
import i18next from "i18next";

import { Link, Typography, Tooltip } from "@mui/material";

// Images
import ProfImg from "../../images/placeholder.png";
import { kuvaThumbServer } from "../../constants/values";
import { countries } from "../../constants/countries";

export function GetDogColumns() {
  const koiratColumns = [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "kuva",
      headerName: i18next.t("photo"),
      width: 80,
      renderCell: (params) => (
        <Link href={`/dog/${params.row.regNumber}`}>
          <img
            alt={i18next.t("photoDesc")}
            src={
              params.row?.hasPhoto
                ? kuvaThumbServer + params.row._id + ".jpg"
                : ProfImg
            }
            style={{ width: "55px", marginTop: "10%" }}
          />
        </Link>
      ),
    },
    {
      field: "regNumber",
      headerName: i18next.t("regNumber"),
      width: 150,
    },
    {
      field: "organizations",
      headerName: i18next.t("registry"),
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedVals = params.value.map((v) => v.name);
        return mappedVals.join(", ");
      },
    },
    {
      field: "name",
      headerName: i18next.t("dogsName"),
      minWidth: 270,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/dog/${params.row.regNumber}`}
        >
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="body2">{params.value}</Typography>
              </React.Fragment>
            }
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "sex",
      headerName: i18next.t("sex"),
      renderCell: (params) => i18next.t(params.value),
      width: 90,
    },

    {
      field: "dateOfBirth",
      headerName: i18next.t("dob"),
      renderCell: (params) => dayjs(params.value).format("DD.MM.YYYY"),
      width: 120,
    },

    {
      field: "countryOfOrigin",
      headerName: i18next.t("countryOfOrigin"),
      width: 140,
      renderCell: (params) => {
        if (params.value !== undefined) {
          const found = countries.find(
            (c) => c.code === params.value.toUpperCase()
          );
          if (found) {
            return (
              <Typography
                variant="body2"
                style={{
                  textOverflow: "ellipsis !important",
                  overflow: "hidden !important",
                }}
              >
                {
                  countries.find((c) => c.code === params.value.toUpperCase())[
                    i18next.language
                  ]
                }
              </Typography>
            );
          }
        }
      },
    },
    {
      field: "microchipNumber",
      headerName: i18next.t("microchipNumber"),
      width: 170,
    },
    {
      field: "color",
      headerName: i18next.t("color"),
      width: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params?.value?.map((value, i) => {
          return getKruValueLabel(value, i18next.language);
        });
        const joinedValues = mappedValues.join(", ");
        return joinedValues;
      },
    },
    {
      field: "coat",
      headerName: i18next.t("coat"),
      width: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params?.value?.map((value, i) => {
          return getKruValueLabel(value, i18next.language);
        });
        const joinedValues = mappedValues.join(", ");
        return joinedValues;
      },
    },
  ];
  return koiratColumns;
}
