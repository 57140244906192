import { useAuth0 } from "@auth0/auth0-react";
import { atom, selector } from "recoil";
import { getCurrentUser } from "../api/privateRoutes";
import { getKruvalues } from "../api/publicRoutes";

export const snackBarState = atom({
  key: "snackBarState",
  default: {
    message: "",
    severity: "success",
    open: false,
  },
});

export const loadingState = atom({
  key: "loadingState",
  default: false,
});

export const showSubmitState = atom({
  key: "showSubmitState",
  default: false,
});

// KRUVALUES
export const kruValuesState = selector({
  key: "kruValuesState",
  get: async () => {
    const options = { limit: 0 };
    const res = await getKruvalues(options);
    return res;
  },
});

// CURRENT USER & ACCESS TOKEN
export const accessTokenForCurrentUserState = selector({
  key: "accessTokenForCurrentUserState",
  get: async () => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const authorizationParams = {
      audience: `${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
      scope: "read:current_user",
    };
    try {
      return await getAccessTokenSilently({ authorizationParams });
    } catch (e) {
      loginWithRedirect({ authorizationParams });
    }
  },
});
export const accessTokenState = atom({
  key: "accessTokenState",
  default: accessTokenForCurrentUserState,
});

export const userState = selector({
  key: "userState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getCurrentUser({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});
