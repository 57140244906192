import React, { Component } from "react";
import dayjs from "dayjs";
import { useTranslation, withTranslation } from "react-i18next";

// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getKruValueLabel } from "../../constants/helpers";

export default function HealthInfo({ dog }) {
  const { t, i18n } = useTranslation();
  const results = dog.health;
  const error = t("noData");
  const lang = i18n.language;

  const healthHeaders =
    results && results?.length > 0 ? (
      <TableRow>
        <TableCell>{t("date")}</TableCell>
        <TableCell>{t("subject")}</TableCell>
        <TableCell>{t("result")}</TableCell>
        <TableCell>{t("additionalInfo")}</TableCell>
      </TableRow>
    ) : null;

  const healthMarkup =
    results && results?.length > 0 ? (
      results.map((result, index) => (
        <TableRow key={index} hover>
          <TableCell>
            {dayjs(result?.date).format("DD.MM.YYYY") || error}
          </TableCell>
          <TableCell>
            {getKruValueLabel(result?.subject, lang) || result?.subject}
          </TableCell>
          <TableCell>{result?.result || error}</TableCell>
          <TableCell>{result?.additionalInfo || null}</TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={4}>{t("noResults")}</TableCell>
      </TableRow>
    );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>{healthHeaders}</TableHead>
        <TableBody>{healthMarkup}</TableBody>
      </Table>
    </TableContainer>
  );
}
