import React from "react";

// Routes
import { updateUser } from "../../../api/adminRoutes";

// Components
import UserForm from "../forms/userForm";

// MUI
import { Button, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

function AdminUserView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const user = useRecoilValue(selectedDocByIdState("user"));

  const handleSubmit = (accessToken, updatedUser, id) => {
    const updateUserData = async (accessToken, updatedUser, id) => {
      await updateUser(id, updatedUser, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "User" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateUserData(accessToken, updatedUser, id);
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/allusers"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <UserForm action="edit" user={user} emitHandleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminUserView;
