import React, { useRef, useState, Suspense } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import CertificateTemplate from "../registrationCertificates/certificateTemplate";
import PhotoModal from "../dogProfileComponents/PhotoModal";
import OwnershipChangeForm from "./ownershipChangeForm";
import OwnershipFormModal from "./formModal";

import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Chip,
  Stack,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import LaunchIcon from "@mui/icons-material/Launch";
import { ChangeCircle } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { userState } from "../../recoil/globalStates";
import Loader from "../../elements/Loader";

export default function DogView({ selectedDog }) {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);

  const [modalOpen, setModalOpen] = useState(false);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleOwnershipModal = () => {
    if (modalOpen === true) {
      if (confirm(t("confirmModalCloseText"))) {
        setModalOpen(false);
      }
    } else {
      setModalOpen(true);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Grid
        container
        spacing={{ xs: 2 }}
        sx={{ p: { xs: 2, sm: 4 } }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2">{selectedDog?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <PhotoModal dog={selectedDog} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <List>
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("regNumber")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.regNumber)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("microchipNumber")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.microchipNumber)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("dob")}`} />
              </ListItemIcon>
              <ListItemText
                primary={dayjs(selectedDog?.dateOfBirth).format("DD.MM.YYYY")}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("sex")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.sex)} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <List>
        <ListItemButton href={`/dog/${selectedDog?.regNumber}`} target="_blank">
          <ListItem>
            <ListItemIcon>
              <LaunchIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="h6">{t("showInDatabase")}</Typography>
              }
            />
          </ListItem>
        </ListItemButton>
        <Divider variant="middle" />
        <ListItemButton onClick={handlePrint}>
          <ListItem>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h6">
                      {t("downloadDogsRegistrationCertificate")}
                    </Typography>
                  </Stack>
                </>
              }
            />
          </ListItem>
        </ListItemButton>
        <Divider variant="middle" />
        <ListItemButton onClick={handleOwnershipModal}>
          <ListItem>
            <ListItemIcon>
              <ChangeCircle />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h6">{t("changeOwnership")}</Typography>
                  </Stack>
                </>
              }
            />
          </ListItem>
        </ListItemButton>
      </List>
      <Box sx={{ display: "none" }}>
        <CertificateTemplate dog={selectedDog} ref={componentRef} />
      </Box>
      <OwnershipFormModal open={modalOpen} onClose={handleOwnershipModal}>
        <OwnershipChangeForm
          id={selectedDog._id}
          setModalOpen={setModalOpen}
          handleModalClose={handleOwnershipModal}
        />
      </OwnershipFormModal>
    </Suspense>
  );
}
