import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Typography, Stack } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

export default function VerifySuccessComponent({ verification }) {
  const { t } = useTranslation();

  const successHeader =
    verification?.operation === "verifyLitterSire" ||
    verification?.operation === "verifyLitterDam"
      ? t("litterParentSuccessfullyVerified")
      : verification?.operation === "verifyOwnerReceive"
      ? t("ownershipChangeSuccessfullyVerified")
      : "Error";

  const successText =
    verification?.operation === "verifyLitterSire" ||
    verification?.operation === "verifyLitterDam"
      ? t("litterParentSuccessfullyVerifiedText")
      : verification?.operation === "verifyOwnerReceive"
      ? t("ownershipChangeSuccessfullyVerifiedText")
      : "Error";

  return (
    <>
      <Grid item xs={12}>
        <Stack direction="row">
          <CheckCircle color="primary" sx={{ mr: 1 }} />
          <Typography variant="h2">{successHeader}</Typography>
        </Stack>

        <br />
        <Typography variant="body">{successText}</Typography>
      </Grid>
    </>
  );
}
