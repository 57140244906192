import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function DogAutocomplete({
  label,
  name,
  handleChange,
  options,
  value,
}) {
  return (
    <Autocomplete
      fullWidth
      label={label}
      name={name}
      placeholder={label}
      onChange={(event, value) => handleChange(event, value, name)}
      options={options}
      isOptionEqualToValue={(option, value) => option._id == value}
      getOptionLabel={(option) =>
        `${option.name} ${option.regNumber || option.microchipNumber}`
      }
      value={value}
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          {option.name} {option.regNumber || option.microchipNumber}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
