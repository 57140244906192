import React from "react";

// MUI
import { Paper, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "auto",
  maxHeight: "80%",
  overflowX: "scroll",
  overflowX: "hidden",
  minWidth: "500px",
  minHeight: "300px",
};

function OwnershipFormModal(props) {
  return (
    <div>
      <Modal open={props.open} onClose={props.handleClose}>
        <Paper sx={style}>{props.children}</Paper>
      </Modal>
    </div>
  );
}

export default OwnershipFormModal;
