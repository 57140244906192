import React from "react";

import { GetDogColumns } from "../../../../../components/adminDashboardComponents/adminDatagridColumns";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { adminTempDogsState } from "../../../../../recoil/adminStates";
import { useRecoilValue } from "recoil";

export default function TempDogsWidget() {
  const tempDogs = useRecoilValue(adminTempDogsState);

  const handleRowClick = (params) => {
    window.location = `/admin/tempdog/${params.row._id}`;
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} id="tempDogs">
          <Typography variant="h3">
            Pending dog registrations
            <Chip
              color="primary"
              size="small"
              label={tempDogs.length}
              sx={{ ml: 2 }}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={tempDogs}
            getRowId={(row) => row._id}
            columns={GetDogColumns("tempdog")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
