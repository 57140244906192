import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

// Components
import { LoaderOverlay } from "../elements/Loader";
import PersonData from "../components/userProfileComponents/personData";
import LoggedInUserBox from "../components/userProfileComponents/loggedInUserBox";
import BreederData from "../components/userProfileComponents/breederData";
import VerifyPerson from "../components/userProfileComponents/verifyPerson";

// MUI
import { Grid, Paper, Alert, AlertTitle } from "@mui/material";
import CertificatesData from "../components/userProfileComponents/certificatesData";
import OrganizationsData from "../components/userProfileComponents/organizationsData";

import { accessTokenState, userState } from "../recoil/globalStates";
import { constSelector, useRecoilValue } from "recoil";

function UserProfile() {
  const { t } = useTranslation();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const accessToken = useRecoilValue(accessTokenState);
  const dbUser = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  return (
    <>
      {isAuthenticated && dbUser ? (
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, md: 4 }}
          justifyContent="center"
        >
          <Grid item xs={12} md={4}>
            <LoggedInUserBox userDocument={dbUser} />
            <OrganizationsData userPerson={dbUser} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2 }}>
              <Grid
                container
                spacing={{ xs: 2, sm: 4 }}
                alignItems="center"
                justifyContent="center"
                sx={{ p: { xs: 0, sm: 3 } }}
              >
                {user?.email_verified ? (
                  <>
                    {dbUser?.newUser && (
                      <Grid item xs={12}>
                        <Alert severity="info" sx={{ p: 2, mb: 2 }}>
                          <AlertTitle>{t("welcome")}</AlertTitle>
                          {t("fillInContactInformation")}
                        </Alert>
                      </Grid>
                    )}
                    {!dbUser?.isVerified ? (
                      <VerifyPerson user={dbUser} token={accessToken} />
                    ) : (
                      <>
                        <PersonData user={dbUser} accessToken={accessToken} />
                        <BreederData userPerson={dbUser} />
                        <CertificatesData userPerson={dbUser} />
                      </>
                    )}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Alert severity="warning" sx={{ p: 2 }}>
                      <AlertTitle>{t("confirmYourEmailAddress")}</AlertTitle>
                      {t("confirmYourEmailAddressText")}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <LoaderOverlay />
      )}
    </>
  );
}

export default UserProfile;
