import React from "react";
import { useTranslation } from "react-i18next";

// Routes
import { updateTempDog } from "../../../api/adminRoutes";

// Components
import DogForm from "../forms/dogForm";

// MUI
import { Grid, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";

function AdminTempDogView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const dog = useRecoilValue(selectedDocByIdState("tempDog"));

  const handleSubmit = (accessToken, updatedDog, id) => {
    const updateDogData = async (accessToken, updatedDog, id) => {
      await updateTempDog(id, updatedDog, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "TempDog" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateDogData(accessToken, updatedDog, id);
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/alltempdogs"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <DogForm
            dog={dog}
            formType="tempdog"
            action="edit"
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminTempDogView;
