export const geneticTestSources = [
  "Wisdom Panel", // Previously Genoscoper
  "Embark",
  "Movet",
  "Laboklin",
];

export const geneticTestSubjects = [
  "Breed ID Kit", // Embark
  "Breed + Health Kit", // Embark
  "MyDogDNA", // Wisdom panel
  "Canine Multifocal Retinopathy (CMR1)",
  "Canine Multifocal Retinopathy (CMR2)",
  "Canine Multifocal Retinopathy (CMR3)",
  "Cerebellar Ataxia (NCL)",
  "Cerebellar Ataxia (NCL-A)",
  "Chondrodysplasia (CDPA)",
  "Chondrodystrophy and Intervertebral Disc Disease (CDDY-IVDD)",
  "Collie Eye Anomaly (CEA)",
  "Degenerative Myelopathy",
  "Degenerative Myelopathy (SOD1)",
  "Exercise Induced Collapse (EIC)",
  "Hyperuricosuria",
  "Multi Drug Resistance (MDR1)",
  "Neuronal Ceroid Lipofuscinosis (NCL)",
  "Primary Lens Luxation (PLL)",
  "Progressive Rod Cone Degeneration (prcd-PRA)",
  "Progressive Retinal Atrophy (rcd1)",
  "Spinocerebellar Ataxia (SCA)",
  "Von Willebrands Type I",
  "Von Willebrands Type II",
  "Von Willebrands Type III",
  "Ichthyosis (ICH1)",
];

export const geneticTestResults = [
  "affected",
  "carrier",
  "clear",
  "all clear",
  "clear by parentage",
];
export const geneticTestAdditionalInfo = [
  "sampling verified",
  "link to profile",
];

export const breedTestSources = [
  "Wisdom Panel", // Previously Genoscoper
  "Embark",
];

export const breedTestSubjects = [
  "Breed ID Kit", // Embark
  "Breed + Health Kit", // Embark
  "MyDogDNA", // Wisdom panel
];
