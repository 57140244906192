import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Typography, Paper } from "@mui/material";

// Components
import PentueCard from "../../elements/LitterCard";
import Loader from "../../elements/Loader";

// Recoil
import { littersByParentIdState } from "../../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function LittersInfo({ parent }) {
  const { t } = useTranslation();
  const litters = useRecoilValue(
    littersByParentIdState({ id: parent._id, sex: parent.sex })
  );

  return (
    <>
      <Suspense fallback={<Loader />}>
        {litters.length ? (
          litters.map((litter) => (
            <PentueCard litter={litter} key={litter._id} />
          ))
        ) : (
          <Paper square sx={{ p: 2 }}>
            <Typography variant="body">{t("noLitters")}</Typography>
          </Paper>
        )}
      </Suspense>
    </>
  );
}
