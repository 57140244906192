import React from "react";
import dayjs from "dayjs";

import { getCountryByCode, getKruValueLabel } from "../../../constants/helpers";
import { dogRegRules } from "../../../constants/rules";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  TableContainer,
  FormControl,
  FormControlLabel,
  Stack,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Launch } from "@mui/icons-material";
import { userState } from "../../../recoil/globalStates";
import { useRecoilValue } from "recoil";

export default function DogSummary({
  dog,
  attachments,
  checkboxes,
  setCheckboxes,
}) {
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(userState);

  const language = i18n.language;

  const hasAttachments = Object.values(attachments).reduce(
    (has, attachmentArr) => has || !!attachmentArr.length,
    false
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">{t("summary")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left">
            <Typography variant="h3">{t("owner")}</Typography>
          </Divider>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {" "}
              <Typography variant="body1">
                {user?.name}
                <br />
                {user?.address || t("noData")} {user?.postalCode || " "}{" "}
                {user?.city || " "} <br />
                {user?.phone || t("noData")}
                <br />
                {user?.email || t("noData")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                {t("permissionToPublishName")}:{" "}
                {user?.permissions?.name ? t("yes") : t("no")}
                <br />
                {t("permissionToPublishCity")}:{" "}
                {user?.permissions?.city ? t("yes") : t("no")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 3 }}>
            <Typography variant="h3">{t("registry")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body">{dog.registry.name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button href={dog.registry.url} target="_blank" endIcon={<Launch />}>
            {t("registryGuidelines")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 3 }}>
            <Typography variant="h3">{t("dogsInformation")}</Typography>
          </Divider>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">{t("dogsName")}</TableCell>
                  <TableCell colSpan={2}>{dog.name || t("noData")}</TableCell>
                  <TableCell variant="head">{t("microchipNumber")}</TableCell>
                  <TableCell colSpan={2}>
                    {dog.microchipNumber || t("noData")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t("sex")}</TableCell>
                  <TableCell>{t(dog.sex) || t("noData")}</TableCell>
                  <TableCell variant="head">{t("dob")}</TableCell>
                  <TableCell>
                    {dayjs(dog.dateOfBirth).format("DD.MM.YYYY") || t("noData")}
                  </TableCell>
                  <TableCell variant="head">{t("countryOfOrigin")}</TableCell>
                  <TableCell>
                    {getCountryByCode(dog.countryOfOrigin, language) ||
                      t("noData")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t("color")}</TableCell>
                  <TableCell>
                    {getKruValueLabel(dog.color, language) || "-"}
                  </TableCell>
                  <TableCell variant="head">{t("markings")}</TableCell>
                  <TableCell colSpan={3}>
                    {dog.markings.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    }) || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t("coatType")}</TableCell>
                  <TableCell>
                    {getKruValueLabel(dog.coattype, language) || "-"}
                  </TableCell>
                  <TableCell variant="head">{t("coatQualities")}</TableCell>
                  <TableCell colSpan={3}>
                    {dog.coatqualities.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    }) || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t("height")}</TableCell>
                  <TableCell>{dog.height ? `${dog.height} cm` : "-"}</TableCell>
                  <TableCell variant="head">{t("weigth")}</TableCell>
                  <TableCell colSpan={3}>
                    {dog.weight ? `${dog.weight} kg` : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 3 }}>
            <Typography variant="h3">{t("attachments")}</Typography>
          </Divider>
          <Table>
            {hasAttachments ? (
              <TableBody>
                {attachments.microchipCert.length > 0 && (
                  <TableRow>
                    <TableCell>{t("dogsMicrochipCertificate")}</TableCell>
                    <TableCell>
                      {attachments.microchipCert.map((a, key) => (
                        <p key={key}>{a.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {attachments.pedigreeCert.length > 0 && (
                  <TableRow>
                    <TableCell>{t("dogsPedigree")}</TableCell>
                    <TableCell>
                      {attachments.pedigreeCert.map((a, key) => (
                        <p key={key}>{a.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {attachments.misc.length > 0 && (
                  <TableRow>
                    <TableCell>{t("otherAttachments")}</TableCell>
                    <TableCell>
                      {attachments.misc.map((a, key) => (
                        <p key={key}>{a.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>{t("noAttachments")}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h3">{t("termsAndPayment")}</Typography>
          </Divider>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("description")}</TableCell>
                <TableCell align="right">{t("total")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("dogRegistration")}</TableCell>
                <TableCell align="right">
                  {dogRegRules.pricePerDog.toFixed(2)} €
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={4}>
                  <Typography variant="caption">
                    {t("anyDiscountsCalculatedAtCheckout")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="dataVerified"
                    checked={checkboxes.dataVerified}
                    onChange={(e) => {
                      setCheckboxes({
                        ...checkboxes,
                        dataVerified: e.target.checked,
                      });
                    }}
                  />
                }
                label={t("ownerConfirmation")}
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="eulaAgreement"
                    checked={checkboxes.eulaAgreement}
                    onChange={(e) => {
                      setCheckboxes({
                        ...checkboxes,
                        eulaAgreement: e.target.checked,
                      });
                    }}
                  />
                }
                label={t("termsConfirmation", {
                  registryName: dog.registry.name,
                })}
              />
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
