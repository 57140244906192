import React, { useEffect } from "react";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  TextField,
  Checkbox,
  Alert,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { userState } from "../../../recoil/globalStates";
import { useRecoilValue } from "recoil";

export default function DogOwner({
  dog,
  setDog,
  formErrors,
  checkboxes,
  setCheckboxes,
}) {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (checkboxes.ownerVerified === true) {
      setDog({ ...dog, omistaja: user?._id });
    } else {
      setDog({ ...dog, omistaja: null });
    }
  }, [checkboxes]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("ownerInformation")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("ownerInfoText1")}
            <br />
            <br />
            {t("ownerInfoText2")}
            <b>{t("ownerInfoText3")}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("ownersName")}
            value={user?.name || ""}
            error={user?.name === null || user?.name === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("address")}
            value={user?.address || t("noData")}
            error={user?.address === undefined || user?.address === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("phoneNumber")}
            value={user?.phone || t("noData")}
            error={user?.phone === undefined || user?.phone === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("email")}
            value={user?.email || t("noData")}
            error={user?.email === undefined || user?.email === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("postalCode")}
            value={user?.postalCode || t("noData")}
            error={user?.postalCode === undefined || user?.postalCode === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("city")}
            value={user?.city || t("noData")}
            error={user?.city === undefined || user?.city === ""}
            disabled
          ></TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("permissionToPublishName")}
            value={user?.permissions?.name ? t("yes") : t("no")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("permissionToPublishCity")}
            value={user?.permissions?.city ? t("yes") : t("no")}
            disabled
          ></TextField>
        </Grid>
        <Grid key={formErrors} item xs={12} sm={7}>
          {formErrors.ownerIsNull === true ? (
            <Alert severity="error">{t("ownerInfoConfirmError")}</Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box sx={{ float: "right" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ownerVerified"
                    checked={checkboxes.ownerVerified}
                    onChange={(e) => {
                      setCheckboxes({
                        ...checkboxes,
                        ownerVerified: e.target.checked,
                      });
                    }}
                  />
                }
                label={t("ownerInfoConfirm")}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
