import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { getKruValueLabel } from "../../constants/helpers";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Stack,
  Chip,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";

import LaunchIcon from "@mui/icons-material/Launch";

import PhotoModal from "./PhotoModal";
import { useRecoilValue } from "recoil";
import { populatedOrgsArrayState } from "../../recoil/publicStates";

export default function OverviewCard({ dog, breeder, breederBadge }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const orgs = useRecoilValue(populatedOrgsArrayState(dog.organizations));

  return (
    <Grid
      container
      spacing={0}
      direction={{ xs: "column", sm: "row" }}
      alignContent="center"
      alignItems="center"
      sx={{ p: { sm: 2, md: 0 } }}
    >
      <Grid xs={12} md={3}>
        <PhotoModal dog={dog} />
      </Grid>
      <Grid xs={12} md={9}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <List disablePadding>
            <ListItem
              secondaryAction={
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, md: 2 }}
                  sx={{ display: { xs: "none" } }}
                >
                  <Chip size="small" variant="outlined" label={t(dog?.sex)} />
                  <Chip
                    size="small"
                    variant="outlined"
                    label={`${"DOB"} ${dayjs(dog?.dateOfBirth).format(
                      "DD.MM.YYYY"
                    )}`}
                  />
                </Stack>
              }
            >
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={<Typography variant="h2">{dog?.name} </Typography>}
                secondary={
                  <>
                    {dog?.regNumber}, {t("Microchip no")} {dog?.microchipNumber}{" "}
                    {dog?.isDeceased ? (
                      <>
                        <br />
                        <Chip
                          size="small"
                          color="primary"
                          variant="outlined"
                          label={`${t("deceased")} ${dayjs(
                            dog?.dateOfDeath
                          ).format("DD.MM.YYYY")}, ${t(
                            "causeOfDeath"
                          ).toLowerCase()}: ${dog?.causeOfDeath}`}
                          sx={{ mt: 1 }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                }
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, md: 4 }}
                  >
                    <Typography variant="body">{t("Registered")} </Typography>
                    <Chip
                      size="small"
                      sx={{ ml: 1 }}
                      label={
                        dayjs(dog?.createdAt).format("DD.MM.YYYY") ||
                        t("noData")
                      }
                    />

                    <Typography variant="body">{t("registry")} </Typography>
                    <Chip
                      size="small"
                      sx={{ ml: 1 }}
                      label={orgs ? orgs.map((v) => v.name).join(", ") : "-"}
                    />
                    <Typography variant="body">
                      {t("registrationType")}{" "}
                    </Typography>
                    <Chip
                      size="small"
                      sx={{ ml: 1 }}
                      label={
                        getKruValueLabel(dog?.regType, lang) || t("noData")
                      }
                    />
                  </Stack>
                }
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={
                  <>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, md: 4 }}
                    >
                      <Typography variant="body">
                        {t("healthTestResults")}
                        <Chip
                          size="small"
                          sx={{ ml: 1 }}
                          label={dog?.health?.length || "0"}
                        />
                      </Typography>
                      <Typography variant="body">
                        {t("geneticTestResults")}
                        <Chip
                          size="small"
                          sx={{ ml: 1 }}
                          label={dog?.genetics?.length || "0"}
                        />
                      </Typography>
                      <Typography variant="body">
                        {t("competitionResults")}
                        <Chip
                          size="small"
                          sx={{ ml: 1 }}
                          label={dog?.competition?.length || "0"}
                        />
                      </Typography>
                      <Typography variant="body">
                        {t("pedigree")}
                        <Chip
                          size="small"
                          sx={{ ml: 1 }}
                          label={dog?.dam || dog?.sire ? t("yes") : t("no")}
                        />
                      </Typography>
                    </Stack>
                  </>
                }
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem
              secondaryAction={
                dog?.breeder ? (
                  <>
                    <Button
                      href={`/breeder/${dog?.breeder?._id}`}
                      endIcon={<LaunchIcon />}
                      size="small"
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    >
                      {t("breedersProfile")}
                    </Button>
                    <IconButton
                      color="primary"
                      href={`/breeder/${dog?.breeder?._id}`}
                      size="small"
                      sx={{ display: { xs: "flex", sm: "none" } }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </>
                ) : null
              }
            >
              <ListItemText
                sx={{ pl: { xs: 0, md: 2 } }}
                primary={
                  <>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 2, md: 4 }}
                    >
                      <Typography variant="body">{t("breeder")}</Typography>{" "}
                      <Typography variant="body">
                        {breeder}
                        {/* {breederBadge} */}
                      </Typography>
                    </Stack>
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}
