import React, { useState } from "react";
import dayjs from "dayjs";

import AttachmentList from "../../../components/formComponents/attachmentList";
import PuppiesList from "../../../components/formComponents/puppiesList";
import VerificationsList from "../../../components/formComponents/verificationsList";

// Routes
import {
  acceptTempLitter,
  deleteLitter,
  deleteTempLitter,
} from "../../../api/adminRoutes";

// MUI
import {
  Grid,
  TextField,
  Divider,
  Typography,
  Autocomplete,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Stack,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add, DeleteForever, Launch, Save } from "@mui/icons-material";
import { countries } from "../../../constants/countries";
import ParentAutocomplete from "../../../components/formComponents/parentAutocomplete";
import CountryAutocomplete from "../../../components/formComponents/countryAutocomplete";
import { useTranslation } from "react-i18next";
import DogAutocomplete from "../../../components/formComponents/dogAutocomplete";

import {
  accessTokenState,
  showSubmitState,
} from "../../../recoil/globalStates";
import {
  adminAllUsersState,
  adminDogsByGenderState,
  adminGetBreederByIdState,
  adminTempDogsState,
} from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

// Component helpers
const dayFormat = (value) => dayjs(value).format("DD.MM.YYYY");

export default function LitterForm({
  litter: initialLitter,
  formType,
  action,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [showSubmit, setShowSubmit] = useRecoilState(showSubmitState);

  const users = useRecoilValue(adminAllUsersState);
  const femaleDogs = useRecoilValue(adminDogsByGenderState("female"));
  const maleDogs = useRecoilValue(adminDogsByGenderState("male"));

  const tempdogs = useRecoilValue(adminTempDogsState);

  const [litter, setLitter] = useState(initialLitter || { puppies: [] });
  const [updatedLitter, setUpdatedLitter] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBreeder, setSelectedBreeder] = useState(
    useRecoilValue(adminGetBreederByIdState(litter.breeder))
  );
  const [selectedSire, setSelectedSire] = useState(litter.sire);
  const [selectedDam, setSelectedDam] = useState(litter.dam);
  const [selectedPuppy, setSelectedPuppy] = useState(null);

  const handleAutocompleteChange = (event, newValue, name) => {
    if (name === "breeder") {
      setSelectedBreeder(newValue);
      if (newValue) {
        setUpdatedLitter({
          ...updatedLitter,
          breeder: newValue._id,
        });
      }
      setShowSubmit(true);
    } else if (name === "countryOfOrigin") {
      setSelectedCountry(newValue);
      if (newValue) {
        setUpdatedLitter({
          ...updatedLitter,
          countryOfOrigin: newValue.code,
        });
      }
      setShowSubmit(true);
    } else if (name === "sire") {
      setSelectedSire(newValue);
      if (newValue) {
        setUpdatedLitter({
          ...updatedLitter,
          sire: newValue._id,
        });
      }
      setShowSubmit(true);
    } else if (name === "dam") {
      setSelectedDam(newValue);
      if (newValue) {
        setUpdatedLitter({
          ...updatedLitter,
          dam: newValue._id,
        });
      }
      setShowSubmit(true);
    }
  };

  // handle datepickers separately, since it needs to be transformed to an ISO string
  // it also doesn't have a name prop, so the object key needs to be assigned manually
  const handleDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setLitter({ ...litter, dateOfBirth: dayjs(newValue).toISOString() });
      setUpdatedLitter({
        ...updatedLitter,
        dateOfBirth: dayjs(newValue).toISOString(),
      });
      setShowSubmit(true);
    }
  };

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedLitter, litter._id);
    setShowSubmit(false);
  };

  // Handle deletion, prompt for confirm before the api call is done,
  // after removal, redirect user to listing
  const handleDelete = () => {
    const confirmation = confirm(
      "Are you sure you want to delete this litter? This process can NOT be undone!"
    );
    if (!confirmation) alert("Cancelled deletion");

    const deletionRoutes = {
      tempLitter: deleteTempLitter,
      litter: deleteLitter,
    };
    const route = deletionRoutes[formType];

    if (!route) throw { error: true, msg: "Error in LitterForm Formtype" };

    route(litter._id, { accessToken }).then((response) => {
      if (response.error) {
        setSnackbarState({
          message: response.message,
          severity: "error",
          open: true,
        });
      } else {
        alert(`${formType} ${response._id} has been removed.`);
        window.location.replace(`/admin/all${formType}s`);
      }
    });
  };

  const handleAccept = () => {
    if (
      confirm(
        "Are you sure you want to accept this litter? Litter is going to be published in the public database."
      )
    ) {
      acceptTempLitter(litter._id, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          alert("Litter has been accepted and transferred to public database.");
          window.location.replace(`/admin/all${formType}s`);
        }
      });
    } else {
      alert(`Cancelled accepting litter`);
    }
  };

  // Handle deleting a puppy object from litter.puppies
  const onDeletePuppy = (i) => {
    if (confirm(t("deletePuppyConfirmation"))) {
      const puppies = litter.puppies;
      puppies.splice(i, 1);
      setLitter({ ...litter, puppies: puppies });
      setUpdatedLitter({ ...updatedLitter, puppies: puppies });
      setShowSubmit(true);
    }
  };

  const onChangePuppy = (e, newValue) => {
    setSelectedPuppy(newValue);
  };

  const onAddPuppy = () => {
    const newPuppies = [...litter.puppies];
    newPuppies.push(selectedPuppy);
    setLitter({ ...litter, puppies: newPuppies });
    setUpdatedLitter({ ...litter, puppies: newPuppies });
    setShowSubmit(true);
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={6}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} {formType.toUpperCase()} {litter?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        {action === "edit" && (
          <>
            <Grid item xs={7}>
              <Typography variant="caption">
                Created on{" "}
                {litter?.createdAt &&
                  dayjs(litter?.createdAt).format("DD.MM.YYYY")}
              </Typography>
              <br />
              <Typography variant="caption">
                Updated on{" "}
                {litter?.updatedAt &&
                  dayjs(litter?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                {formType === "tempLitter" && (
                  <>
                    <Typography variant="body">
                      <b>Registration status</b>
                      <br /> {litter?.registrationStatus || "-"}
                    </Typography>
                    <Button variant="contained" onClick={handleAccept}>
                      Accept registration
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Date of Birth"
            name="dateOfBirth"
            value={litter?.dateOfBirth || null}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <CountryAutocomplete
            label="Country of origin"
            name="countryOfOrigin"
            handleChange={handleAutocompleteChange}
            options={countries}
            value={selectedCountry}
          />
        </Grid> */}
        {/*   <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Registration</Typography>
            </Divider>
          </Grid>
          <Grid item xs={6}></Grid> */}
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Breeder</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            label="Breeder"
            name="breeder"
            placeholder="Breeder"
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, "breeder")
            }
            options={users}
            isOptionEqualToValue={(option, value) => option._id == value}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            value={selectedBreeder}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name} {" ("}
                {option.email}
                {") "}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Breeder"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Breeder preview</Typography>
          <List>
            {selectedBreeder && (
              <ListItem secondaryAction={selectedBreeder.regNumber}>
                <ListItemText
                  primary={`${selectedBreeder.name}, ${selectedBreeder.city}`}
                  secondary={`${selectedBreeder.email} (${selectedBreeder._id})`}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Parents</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <ParentAutocomplete
            label="Sire"
            name="sire"
            handleChange={handleAutocompleteChange}
            options={maleDogs}
            value={selectedSire}
          />
          <List sx={{ mt: 2 }}>
            <ListItem>
              <Typography variant="h5">Sire preview</Typography>
            </ListItem>
            {selectedSire && (
              <ListItem>
                <ListItemButton
                  href={`/admin/dog/${selectedSire._id}`}
                  target="_blank"
                >
                  <ListItemIcon>
                    <Launch />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${selectedSire.name} ${selectedSire.regNumber}`}
                    secondary={`${selectedSire._id}`}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={6}>
          <ParentAutocomplete
            label="Dam"
            name="dam"
            handleChange={handleAutocompleteChange}
            options={femaleDogs}
            value={selectedDam}
          />
          <List sx={{ mt: 2 }}>
            <ListItem>
              <Typography variant="h5">Dam preview</Typography>
            </ListItem>
            {selectedDam && (
              <ListItem>
                <ListItemButton
                  href={`/admin/dog/${selectedDam._id}`}
                  target="_blank"
                >
                  <ListItemIcon>
                    <Launch />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${selectedDam.name} ${selectedDam.regNumber}`}
                    secondary={`${selectedDam._id}`}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Puppies</Typography>
          </Divider>
          <PuppiesList
            puppies={litter?.puppies || []}
            onDeletePuppy={onDeletePuppy}
            formType={formType}
          />
        </Grid>

        {formType === "tempLitter" && (
          <>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <DogAutocomplete
                label="Add a puppy"
                name="puppy"
                handleChange={onChangePuppy}
                options={tempdogs}
                value={selectedPuppy}
              />{" "}
              <Button
                variant="outlined"
                onClick={onAddPuppy}
                startIcon={<Add />}
                sx={{ mt: 2 }}
              >
                Add puppy
              </Button>
            </Grid>
          </>
        )}

        {action === "edit" && formType === "tempLitter" && (
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Verifications</Typography>
            </Divider>
            <VerificationsList verifications={litter.verifications} />
          </Grid>
        )}
        {action === "edit" && (
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Attachments</Typography>
            </Divider>
            <AttachmentList parentDoc={litter} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedLitter
              ? JSON.stringify(updatedLitter, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <Save fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
