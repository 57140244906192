export const Pedigree4g = (dog) => {
  const getParents = (dog) => {
    let parents = [];
    if (dog.sire) {
      const sire = {
        name: dog.sire.name,
        id: dog.sire._id,
        regNumber: dog.sire.regNumber || null,
        regType: dog.sire.regType || null,
        hasPhoto: dog.sire.hasPhoto || false,
        children: getParents(dog.sire),
      };
      parents.push(sire);
    }
    if (dog.dam) {
      const dam = {
        name: dog.dam.name,
        id: dog.dam._id,
        regNumber: dog.dam.regNumber || null,
        regType: dog.dam.regType || null,
        hasPhoto: dog.dam.hasPhoto || false,
        children: getParents(dog.dam),
      };
      parents.push(dam);
    }
    return parents;
  };

  return {
    name: dog?.name || "",
    id: dog?._id || null,
    regNumber: dog?.regNumber || null,
    hasPhoto: dog?.hasPhoto || false,
    children: getParents(dog),
  };
};
