import React from "react";
import { useTranslation } from "react-i18next";

import { muiFi } from "../../constants/muiTranslations";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Components
import { GetDogColumns } from "../../components/datagridColumns/DogColumns";

// Recoil
import { allDogsState } from "../../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function DogList() {
  const { i18n } = useTranslation();

  const dogs = useRecoilValue(allDogsState);

  const handleRowClick = (params) => {
    window.location = `/dog/${params.row._id}`;
  };

  return (
    <DataGrid
      autoHeight
      pageSize={50}
      getRowId={(row) => row._id}
      onRowClick={handleRowClick}
      rows={dogs}
      columns={GetDogColumns()}
      localeText={i18n.language === "fi" ? muiFi : null}
      components={{ Toolbar: GridToolbar }}
      disableSelectionOnClick
    />
  );
}
