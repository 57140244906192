import { atom, atomFamily, selectorFamily } from "recoil";
import { getTempLitterById } from "../api/privateRoutes";
import { accessTokenState } from "./globalStates";

export const activeStepState = atom({
  key: "activeStepState",
  default: 0,
});

export const litterFormState = atom({
  key: "litterFormState",
  default: null,
});

export const litterObjectState = atomFamily({
  key: "litterObjectState",
  default: (id) => existingTempLitterState(id),
});

export const existingTempLitterState = selectorFamily({
  key: "existingTempLitterState",
  get:
    (id) =>
      async ({ get }) => {
        const accessToken = get(accessTokenState);
        const response = await getTempLitterById(id, { accessToken });
        if (response.error) {
          throw response;
        }
        return response;
      },
});

export const statsState = atom({
  key: "statsState",
  default: {
    mating: "",
    birth: "",
    deadPuppies: "",
  },
});

export const attachmentsState = atom({
  key: "attachmentsState",
  default: {
    sireCertificate: [],
    damCertificate: [],
    microchipCertificates: [],
    misc: [],
  },
});

export const filepathsState = atom({
  key: "filepathsState",
  default: {
    sireCertificate: null,
    damCertificate: null,
    microchipCertificates: null,
    misc: null,
  },
});

export const checkboxesState = atom({
  key: "checkboxesState",
  default: {
    breederVerified: false, // At form page
    dataVerified: false, // At summary page
    eulaAgreement: false, // At summary page
  },
});

export const formErrorsState = atom({
  key: "formErrorsState",
  default: {},
});

export const userIsSireOwnerState = atom({
  key: "userIsSireOwnerState",
  default: null,
});

export const userIsDamOwnerState = atom({
  key: "userIsDamOwnerState",
  default: null,
});
