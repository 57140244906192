import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import LitterInfoBox from "../components/litterProfileComponents/LitterInfoBox";
import ParentsComp from "../components/litterProfileComponents/ParentsComp";
import KoiraCard from "../elements/DogCard";
import LitterBreederInfoBox from "../components/litterProfileComponents/LitterBreederInfoBox";

// MUI
import { Typography, Divider, Box, Grid, Paper } from "@mui/material";

// Recoil
import { breederState, litterState } from "../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function LitterProfile() {
  const { t } = useTranslation();
  const { id } = useParams();

  const litter = useRecoilValue(litterState(id));
  const breeder = useRecoilValue(breederState(litter.breeder._id));

  return (
    <Grid container direction={{ xs: "column", sm: "row" }} spacing={5}>
      <Grid item xs={12} md={5}>
        <LitterInfoBox litter={litter} />
        <LitterBreederInfoBox breeder={breeder} />
      </Grid>
      <Grid item xs={12} md={7}>
        <Box sx={{ mb: 5 }}>
          <ParentsComp sire={litter?.sire} dam={litter?.dam} />
        </Box>

        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{t("litter")}</Typography>{" "}
        </Divider>
        {litter.puppies?.length > 0 ? (
          litter.puppies?.map((dog, i) => <KoiraCard key={i} dog={dog} />)
        ) : (
          <Paper>{t("noPuppies")}</Paper>
        )}
      </Grid>
    </Grid>
  );
}
