import React from "react";

// Routes
import { updateHealthResult } from "../../../api/adminRoutes";

// MUI
import { Button, Grid } from "@mui/material";
import HealthtestForm from "../forms/healthtestForm";
import { ArrowBack } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import { useRecoilState, useRecoilValue } from "recoil";
import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";

function AdminHealthResultView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const result = useRecoilValue(selectedDocByIdState("healthResult"));

  const handleSubmit = (accessToken, updatedResult, id) => {
    const updateResultData = async (accessToken, updatedResult, id) => {
      await updateHealthResult(id, updatedResult, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Health result" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateResultData(accessToken, updatedResult, id);
  };

  return (
    <>
      <Button
        color="gray"
        href="/admin/allhealthresults"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <HealthtestForm
            action="edit"
            result={result}
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminHealthResultView;
