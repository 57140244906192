import React from "react";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Fab,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Upload } from "@mui/icons-material";

export default function DogAttachments({
  attachments,
  setAttachments,
  filePaths,
  setFilePaths,
}) {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    // Create FormData objects ready to send
    let newAttachments = [];
    for (const file of files) {
      const formData = new FormData();
      formData.set(`attachment`, file);
      formData.set(`category`, name);
      newAttachments.push(formData);
    }
    setAttachments({ ...attachments, [name]: newAttachments });

    // Handle file name display
    const path = !files.length
      ? t("noFileSelected")
      : files.length === 1
      ? `${files[0].name}`
      : `${files.length} ${t("filesSelected")}`;

    setFilePaths({ ...filePaths, [name]: path });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("attachmentsInfoText")}
            <br />
            <br />
            {t("attachmentsInfoText2")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <List disablePadding>
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="microchipCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="microchipCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={`${t("dogsMicrochipCertificate")} *`}
                secondary={filePaths.microchipCert || t("noFileSelected")}
              ></ListItemText>
            </ListItem>
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="pedigreeCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="pedigreeCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t("dogsPedigree")}
                secondary={filePaths.pedigreeCert || t("noFileSelected")}
              ></ListItemText>
            </ListItem>
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t("otherAttachments")}
                secondary={filePaths.misc || t("noFilesSelected")}
              ></ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
