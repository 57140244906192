import React from "react";
import { Alert } from "@mui/material";

export function SuccessAlert() {
  return (
    <Alert severity="success" sx={{ width: "100%" }}>
      Changes saved successfully
    </Alert>
  );
}
export function ErrorAlert({ msg }) {
  return (
    <Alert severity="error" sx={{ width: "100%" }}>
      Error: {msg}
    </Alert>
  );
}
export function GlobalError({ error }) {
  return (
    <div>
      <Alert severity="error" sx={{ width: "100%", justifyContent: "center" }}>
        Error: {error.message}
      </Alert>
    </div>
  );
}
