import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
  Stack,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { deleteHealthResult } from "../../../api/adminRoutes";
import DogAutocomplete from "../../../components/formComponents/dogAutocomplete";
import { healthTestSources } from "../../../constants/healthTests";

import {
  accessTokenState,
  showSubmitState,
  snackBarState,
  kruValuesState,
} from "../../../recoil/globalStates";
import {
  adminAllDogsState,
  adminDogByIdState,
} from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

function HealthtestForm({
  action,
  result,
  emitHandleSubmit,
  handleModalClose,
}) {
  const accessToken = useRecoilValue(accessTokenState);
  const [showSubmit, setShowSubmit] = useRecoilState(showSubmitState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const kruValues = useRecoilValue(kruValuesState);

  const dogs = useRecoilValue(adminAllDogsState);
  const [selectedDog, setSelectedDog] = useState(
    useRecoilValue(adminDogByIdState(result?.parentDoc))
  );

  const [initialResult, setInitialResult] = useState(result);
  const [updatedResult, setUpdatedResult] = useState(false);

  const handleChange = (event) => {
    setUpdatedResult({
      ...updatedResult,
      [event.target.name]: event.target.value,
    });
    setInitialResult({
      ...initialResult,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
  };

  const handleSourceChange = (event, newValue) => {
    setUpdatedResult({
      ...updatedResult,
      source: newValue,
    });
    setInitialResult({
      ...initialResult,
      source: newValue,
    });
    setShowSubmit(true);
  };

  const handleDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setInitialResult({
        ...initialResult,
        date: dayjs(newValue).toISOString(),
      });
      setUpdatedResult({
        ...updatedResult,
        date: dayjs(newValue).toISOString(),
      });
      setShowSubmit(true);
    }
  };

  const onChangeDog = (e, newValue) => {
    setSelectedDog(newValue);
    setUpdatedResult({
      ...updatedResult,
      parentDoc: newValue._id,
    });
  };

  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedResult, initialResult._id);
    setShowSubmit(false);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this result? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteHealthResult(initialResult._id, { accessToken }).then((res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            alert(`Result has been removed.`);
            setTimeout((window.location = `/admin/allhealthresults`), 3000);
          }
        });
      };
      deleteData();
    }
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} HEALTH RESULT{" "}
              {action === "edit" && initialResult?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        {action === "edit" && (
          <Grid item xs={12}>
            <Stack direction="column" alignItems="flex-start" sx={{ pb: 2 }}>
              <Typography variant="caption">
                Created{" "}
                {initialResult?.createdAt &&
                  dayjs(initialResult?.createdAt).format("DD.MM.YYYY")}
              </Typography>

              <Typography variant="caption">
                Updated{" "}
                {initialResult?.updatedAt &&
                  dayjs(initialResult?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <DatePicker
            label="Test date"
            name="date"
            value={initialResult?.date || null}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={6}>
          <DogAutocomplete
            label="Tested dog"
            name="dog"
            handleChange={onChangeDog}
            options={dogs}
            value={selectedDog}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Subject</InputLabel>
            <Select
              fullWidth
              label="Subject"
              name="subject"
              placeholder="Subject"
              value={initialResult?.subject || ""}
              onChange={handleChange}
            >
              {kruValues
                .filter((i) => i.category === "result.health")
                .map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.en.name} {value.en.acronym}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Result"
            name="result"
            placeholder="Result"
            value={initialResult?.result || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            freeSolo
            label="Source"
            name="source"
            placeholder="Source"
            options={healthTestSources}
            value={initialResult?.source || ""}
            onChange={handleSourceChange}
            renderInput={(params) => <TextField {...params} label="Source" />}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Additional information"
            name="additionalInfo"
            placeholder="Additional information"
            value={initialResult?.additionalInfo || ""}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedResult
              ? JSON.stringify(updatedResult, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <SaveIcon fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default HealthtestForm;
