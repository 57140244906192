import React, { Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import { LoaderBlock } from "../../../elements/Loader";

// MUI
import { Grid, Paper, Typography, Link, Box } from "@mui/material";
import AdminNav from "../../../components/adminDashboardComponents/adminNav";
import { Outlet } from "react-router";
import { useRecoilValue } from "recoil";
import { userState } from "../../../recoil/globalStates";

function AdminContainer() {
  const { isAuthenticated } = useAuth0();
  const dbUser = useRecoilValue(userState);

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        justifyContent="center"
      >
        {isAuthenticated && dbUser.role === "admin" ? (
          <>
            <Grid item xs={12}>
              <Typography
                component={Link}
                href="/admin/dashboard"
                variant="h1"
                sx={{
                  color: "primary",
                  textDecoration: "none",
                }}
              >
                Admin Dashboard
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AdminNav />
            </Grid>
            <Grid item xs={12}>
              <Suspense
                fallback={
                  <Box sx={{ p: 4 }}>
                    <LoaderBlock />
                  </Box>
                }
              >
                <Paper sx={{ p: 4 }}>
                  <Outlet />
                </Paper>
              </Suspense>
            </Grid>
          </>
        ) : (
          <Typography variant="body">Insufficient permissions</Typography>
        )}
      </Grid>
    </>
  );
}

export default AdminContainer;
