import React, { Fragment } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import {
  Button,
  Grid,
  TextField,
  Alert,
  Divider,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";

import { updateDog, updateTempDog } from "../../../api/adminRoutes";
import {
  breedTestSources,
  breedTestSubjects,
} from "../../../constants/geneticTests";
import { Delete } from "@mui/icons-material";
import { accessTokenState } from "../../../recoil/globalStates";
import { useRecoilValue } from "recoil";

// Used in dogform as a modal to update dog's breed test data
function BreedTestComponent({ initialDog, formType, handleModalClose }) {
  const accessToken = useRecoilValue(accessTokenState);
  const [error, setError] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [breedTestObject, setBreedTestObject] = useState(initialDog?.breedtest);
  const [breedFields, setBreedFields] = useState(
    initialDog?.breedtest?.breeds || [{ name: "", percentage: "" }]
  );

  const handleAddFieldSets = (e) => {
    let newfieldset = { name: "", percentage: "" };
    setBreedFields([...breedFields, newfieldset]);
  };

  const onDeleteFieldset = (i) => {
    if (confirm("Delete breed fieldset?")) {
      const newArray = breedFields;
      newArray.splice(i, 1);
      setBreedFields(newArray);
      setBreedTestObject({
        ...breedTestObject,
        breeds: newArray,
      });
    }
  };

  const handleBreedTestChange = (e) => {
    setBreedTestObject({
      ...breedTestObject,
      [e.target.name]: e.target.value,
    });
  };

  const handleBreedTestDate = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setBreedTestObject({
        ...breedTestObject,
        ["date"]: dayjs(newValue).toISOString(),
      });
    }
  };

  const handleFieldSetChange = (i, e) => {
    let breeds = [...breedFields];
    breeds[i][e.target.name] = e.target.value;
    setBreedFields(breeds);
    setBreedTestObject({
      ...breedTestObject,
      breeds,
    });
  };

  const handleSubmit = () => {
    const field = { breedtest: breedTestObject };
    const updateDogData = async () => {
      await updateDog(initialDog._id, field, { accessToken }).then((res) => {
        if (res.error) {
          setError({ error: true, msg: res.message });
        } else {
          setUpdateSuccessful(true);
        }
      });
    };
    const updateTempDogData = async () => {
      await updateTempDog(initialDog._id, field, { accessToken }).then((res) => {
        if (res.error) {
          setError({ error: true, msg: res.message });
        } else {
          setUpdateSuccessful(true);
        }
      });
    };

    if (formType === "dog") {
      updateDogData();
    } else if (formType === "tempdog") {
      updateTempDogData();
    } else {
      setError({ error: true, msg: "Error in Breed test Formtype" });
    }
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">Edit Genetic Breed test</Typography>
            <Button variant="text" color="gray" onClick={handleModalClose}>
              Close
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}></Divider>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Test source</InputLabel>
            <Select
              fullWidth
              label="Test source"
              name="source"
              placeholder="Test source"
              value={breedTestObject?.source || ""}
              onChange={handleBreedTestChange}
            >
              {breedTestSources.map((val, i) => (
                <MenuItem key={i} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Date"
            name="date"
            value={breedTestObject?.date || null}
            onChange={handleBreedTestDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Subject</InputLabel>
            <Select
              fullWidth
              label="Subject"
              name="subject"
              placeholder="Subject"
              value={breedTestObject?.subject || ""}
              onChange={handleBreedTestChange}
            >
              {breedTestSubjects.map((val, i) => (
                <MenuItem key={i} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Additional info"
            name="additionalInfo"
            placeholder="Additional info"
            value={breedTestObject?.additionalInfo || ""}
            onChange={handleBreedTestChange}
          />
        </Grid>
        <Fragment key={breedFields}>
          {breedFields.map((fieldset, i) => {
            return (
              <Fragment key={i}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={"Breed " + (i + 1)}
                    name="name"
                    placeholder="Breed"
                    defaultValue={breedTestObject?.rodut?.name}
                    value={fieldset.name}
                    onChange={(e) => handleFieldSetChange(i, e)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    type="number"
                    label={"Percent " + (i + 1)}
                    name="percentage"
                    placeholder="Percent"
                    value={fieldset.percentage}
                    onChange={(e) => handleFieldSetChange(i, e)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => onDeleteFieldset(i)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Fragment>
            );
          })}
        </Fragment>

        <Grid item xs={12}>
          <Stack alignItems="flex-end">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddFieldSets}
            >
              Add breed
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Breed test object preview (raw JSON)
          </Typography>
          {breedTestObject
            ? JSON.stringify(breedTestObject, null, "\t")
            : "No changes to update"}
          {error.error && (
            <Alert severity="error" sx={{ width: "100%" }}>
              Error: {error.msg}
            </Alert>
          )}
          {updateSuccessful && (
            <Alert severity="success" sx={{ width: "100%", my: 2 }}>
              Changes saved successfully
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ float: "right" }}
          >
            Update breed test data
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default BreedTestComponent;
