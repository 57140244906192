import React from "react";

// Routes
import { updateLitter } from "../../../api/adminRoutes";

// Components
import LitterForm from "../forms/litterForm";

// MUI
import { Button, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

function AdminLitterView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const litter = useRecoilValue(selectedDocByIdState("litter"));

  const handleSubmit = (accessToken, newLitter, id) => {
    updateLitter(id, newLitter, {
      accessToken,
      options: { new: true },
    }).then((response) => {
      if (response.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("updateSuccess", { docType: "Litter" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/alllitters"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <LitterForm
            litter={litter}
            formType="litter"
            action="edit"
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminLitterView;
