import React from "react";
import i18next from "i18next";
import { Typography, Paper, Link, Stack } from "@mui/material";
import DogAvatar from "../../elements/DogAvatar";

export const imageNode = ({ nodeDatum, toggleNode }) => (
  <foreignObject x="-40" y="-40" width="200" height="100">
    <Paper>
      <Stack direction="row" alignItems="center" sx={{ p: 1 }}>
        <DogAvatar
          hasPhoto={nodeDatum.hasPhoto}
          dogId={nodeDatum.id}
          alt={nodeDatum.name}
        />
        <Stack direction="column">
          {nodeDatum.regType !== null ? (
            <>
              <Typography
                component={Link}
                href={`/dog/${nodeDatum.id}`}
                variant="h5"
                sx={{ ml: 1, fontSize: "0.8rem" }}
              >
                {nodeDatum.name}
              </Typography>{" "}
              <Typography variant="caption" sx={{ ml: 1 }}>
                {nodeDatum.regNumber}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h5" sx={{ ml: 1, fontSize: "0.8rem" }}>
                {nodeDatum.name}
              </Typography>
              <Typography variant="caption" sx={{ ml: 1 }}>
                {i18next.t("familyTreeDog")}
              </Typography>
            </>
          )}{" "}
        </Stack>
      </Stack>{" "}
    </Paper>
  </foreignObject>
);
export const smallImageNode = ({ nodeDatum, toggleNode }) => (
  <foreignObject x="0" y="0" width="110" height="200">
    <Stack direction="column">
      <Typography variant="h5" sx={{ ml: 1, fontSize: "0.8rem" }}>
        {nodeDatum.name}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {nodeDatum.regNumber}
      </Typography>
    </Stack>
  </foreignObject>
);
