import React from "react";
import { useTranslation } from "react-i18next";

import { litterColumns } from "../../components/datagridColumns/LitterColumns";
import { muiFi } from "../../constants/muiTranslations";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Recoil
import { allLittersState } from "../../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function LitterList() {
  const { i18n } = useTranslation();

  const litters = useRecoilValue(allLittersState);

  const handleRowClick = (params) => {
    window.location = `/litter/${params.row._id}`;
  };

  return (
    <DataGrid
      autoHeight
      pageSize={50}
      getRowId={(row) => row._id}
      onRowClick={handleRowClick}
      rows={litters}
      columns={litterColumns}
      localeText={i18n.language === "fi" ? muiFi : null}
      components={{ Toolbar: GridToolbar }}
      disableSelectionOnClick
    />
  );
}
