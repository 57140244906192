import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

// Components
import { acceptVerification } from "../../api/privateRoutes";

import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Box,
  Button,
} from "@mui/material";
import { accessTokenState, userState } from "../../recoil/globalStates";
import { useRecoilValue } from "recoil";
import { dogByIdState } from "../../recoil/publicStates";
import Loader, { LoaderOverlay } from "../../elements/Loader";

export default function VerifyForm({ verification, setVerification }) {
  const { t } = useTranslation();
  const { _id } = useParams("_id");
  const accessToken = useRecoilValue(accessTokenState);
  const user = useRecoilValue(userState);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [operationTarget, setOperationTarget] = useState(null);

  const sire = useRecoilValue(dogByIdState(verification?.parentDoc?.sire));
  const dam = useRecoilValue(dogByIdState(verification?.parentDoc?.dam));

  const header =
    verification?.operation === "verifyLitterSire" ||
      verification?.operation === "verifyLitterDam"
      ? t("littersParentsVerification")
      : verification?.operation === "verifyOwnerReceive"
        ? t("ownershipChangeVerification")
        : "Error";

  const introText =
    verification?.operation === "verifyLitterSire" ||
      verification?.operation === "verifyLitterDam"
      ? t("littersParentsVerificationText")
      : verification?.operation === "verifyOwnerReceive"
        ? t("ownershipVerificationText")
        : "Error";

  useEffect(() => {
    if (!verification?.parentDoc) return;

    switch (verification?.operation) {
      case "verifyLitterSire": {
        setOperationTarget(sire);
      }
      case "verifyLitterDam": {
        setOperationTarget(dam);
      }

      default:
        break;
    }
  }, [verification]);

  const handleCheckBox = (e) => setChecked(e.target.checked);

  const handleClick = () => {
    setLoading(true);
    acceptVerification(_id, { accessToken }).then((res) => {
      setVerification(res);
      setLoading(false);
    });
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        {loading && <LoaderOverlay />}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2">{header}</Typography>
            <br />
            <Typography variant="body">{introText}</Typography>
          </Grid>{" "}
          {verification?.operation === "verifyOwnerReceive" ? (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" sx={{ display: "block" }}>
                  {t("dog")}
                </Typography>
                <Typography variant="body">{`${verification.parentDoc.name} (${verification.parentDoc.regNumber})`}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" sx={{ display: "block" }}>
                  {t("newOwner")}
                </Typography>
                <Typography variant="body">{`${user.name} (${user.email})`}</Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("dob")}</TableCell>
                      <TableCell>{t("sire")}</TableCell>
                      <TableCell>{t("dam")}</TableCell>
                      <TableCell>{t("puppies")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {dayjs(verification.parentDoc?.dateOfBirth).format(
                          "DD.MM.YYYY"
                        )}
                      </TableCell>
                      <TableCell>{sire?.name}</TableCell>
                      <TableCell>{dam?.name}</TableCell>
                      <TableCell>
                        {verification.parentDoc?.puppies?.length || "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={9}>
            <Stack direction="row" alignItems="center">
              <Checkbox color="primary" onClick={handleCheckBox} />
              {verification?.operation === "verifyOwnerReceive" ? (
                <Typography variant="body">
                  {t("ownershipChangeCheckboxText", {
                    dogName: verification.parentDoc.name,
                    dogRegno: verification.parentDoc.regNumber,
                  })}
                </Typography>
              ) : (
                <Typography variant="body">
                  {t("littersParentsCheckboxText", {
                    dogName: operationTarget?.name,
                    dogRegno: operationTarget?.regNumber,
                  })}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" justifyContent="right">
              <Button
                variant="contained"
                disabled={!checked}
                onClick={handleClick}
              >
                {verification.verified
                  ? t(`verificationComplete`)
                  : t(`${verification.operation}`)}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
