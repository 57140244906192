import React from "react";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Launch } from "@mui/icons-material";

import dayjs from "dayjs";

export default function CertificatesList({ certs, onDeleteCert }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Preview</TableCell>
          <TableCell>Certificate name</TableCell>
          <TableCell>Issuing date</TableCell>
          <TableCell>_id</TableCell>
          <TableCell>Remove</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {certs && certs.length > 0 ? (
          certs.map((cert, i) => (
            <TableRow key={i}>
              <TableCell>
                <IconButton
                  href={`/admin/certificate/${cert._id}`}
                  target="_blank"
                >
                  <Launch />
                </IconButton>
              </TableCell>
              <TableCell>{cert.certificate?.name}</TableCell>
              <TableCell>
                {dayjs(cert.issuingDate).format("DD.MM.YYYY")}
              </TableCell>
              <TableCell>{cert._id}</TableCell>
              <TableCell>
                <IconButton onClick={() => onDeleteCert(i)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={7}>
              No certificates
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
