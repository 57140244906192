import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getBreederInfo, getBadge } from "../constants/helpers";

// Components
import BasicInformation from "../components/dogProfileComponents/BasicInformation";
import RegistrationInfo from "../components/dogProfileComponents/RegistrationInfo";
import GeneticsInfo from "../components/dogProfileComponents/GeneticResults";
import HealthInfo from "../components/dogProfileComponents/HealthResults";
import CompetitionInfo from "../components/dogProfileComponents/CompetitionResults";
import SiblingsInfo from "../components/dogProfileComponents/Siblings";
import LittersInfo from "../components/dogProfileComponents/Litters";
import OverviewCard from "../components/dogProfileComponents/OverviewCard";
import PedigreeTable from "../components/pedigreeComponents/PedigreeTable";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Paper, Divider, Typography } from "@mui/material";

import { imageNode } from "../components/pedigreeComponents/nodeElements";

import { useRecoilValue } from "recoil";
import { currentDogState } from "../recoil/publicStates";

export default function DogProfile() {
  const { t } = useTranslation();
  const { id } = useParams();

  const dog = useRecoilValue(currentDogState(id));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breeder = getBreederInfo(dog?.breeder);
  const breederBadge = getBadge(dog?.breeder);

  return (
    <>
      <Grid container spacing={5}>
        <Grid xs={12}>
          <Paper>
            <OverviewCard
              dog={dog}
              breeder={breeder}
              breederBadge={breederBadge}
            />
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h3">{t("basicInformation")}</Typography>
          </Divider>
          <BasicInformation
            dog={dog}
            breeder={breeder}
            breederBadge={breederBadge}
          />
        </Grid>
        <Grid xs={12}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h3">{`${t("registration")} & ${t(
              "certificates"
            ).toLowerCase()}`}</Typography>
          </Divider>
          <RegistrationInfo dog={dog} />
        </Grid>
        {dog?.health ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("healthTestResults")}</Typography>
            </Divider>
            <HealthInfo dog={dog} />
          </Grid>
        ) : null}
        {dog?.genetics ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("geneticTestResults")}</Typography>
            </Divider>
            <GeneticsInfo dog={dog} />
          </Grid>
        ) : null}
        {dog?.competition ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("competitionResults")}</Typography>
            </Divider>
            <CompetitionInfo dog={dog} />
          </Grid>
        ) : null}
        {dog ? (
          <Grid xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("pedigree")}</Typography>
            </Divider>
            <Paper>
              <div id="treeWrapper" style={{ width: "100%", height: "800px" }}>
                <PedigreeTable
                  dog={dog}
                  nodeSize={{ x: 200, y: 100 }}
                  translate={{ x: 100, y: 400 }}
                  zoom={0.8}
                  renderCustomNodeElement={imageNode}
                />
              </div>
            </Paper>
          </Grid>
        ) : null}
        {dog ? (
          <Grid xs={12} md={6}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("siblings")}</Typography>
            </Divider>
            <SiblingsInfo dog={dog} />
          </Grid>
        ) : null}
        {dog ? (
          <Grid xs={12} md={6}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              <Typography variant="h3">{t("litters")}</Typography>
            </Divider>
            <LittersInfo parent={dog} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
