import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Stack, Button, ButtonGroup } from "@mui/material";

// Recoil
import { constSelector, useRecoilValue } from "recoil";
import { userState } from "../recoil/globalStates";
import { useAuth0 } from "@auth0/auth0-react";

export default function Footer() {
  const { isAuthenticated } = useAuth0();
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const domainSuffix = i18n.language === "fi" ? "fi" : "com";
  const termsPath =
    i18n.language === "fi"
      ? "datacanis-kayttoehdot"
      : "datacanis-terms-and-conditions";

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Button
          variant="text"
          color="secondary"
          size="small"
          href={`mailto:info@datacanis.${domainSuffix}`}
          target="_blank"
          rel="noreferrer"
          sx={{ pb: 2, display: { xs: "block", sm: "none" } }}
        >
          info@datacanis.{domainSuffix}
        </Button>
        <ButtonGroup
          variant="text"
          color="secondary"
          size="small"
          sx={{ pb: 2, display: { xs: "none", sm: "block" } }}
        >
          <Button
            href={`mailto:info@datacanis.${domainSuffix}`}
            target="_blank"
            rel="noreferrer"
          >
            info@datacanis.{domainSuffix}
          </Button>
          <Button
            href={`https://ethicanis.fi/${termsPath}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("termsAndConditions")}
          </Button>
          <Button
            href="https://ethicanis.fi/tietosuojaseloste/"
            target="_blank"
            rel="noreferrer"
          >
            {t("privacyPolicy")}
          </Button>
          {user && user?.role === "admin" && (
            <Button href="/admin/dashboard">{t("admin dashboard")}</Button>
          )}
        </ButtonGroup>
      </Stack>
    </Box>
  );
}
