import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  TextField,
  Checkbox,
  Alert,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import { countries } from "../../../constants/countries";
import { userState } from "../../../recoil/globalStates";
import { useRecoilValue } from "recoil";
import { Controller, useFormContext } from "react-hook-form";

export default function LitterBreeder() {
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { checkboxes } = watch();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("breederInformation")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("breederInfoText")}
            <br />
            <br />
            {t("ownerInfoText2")} <b>{t("ownerInfoText3")}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("breedersFullName")}
            value={user?.name || ""}
            error={user?.name === null || user?.name === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("breederId")}
            value={user?.breederId || t("noBreederId")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("breederPrefix")}
            value={user?.breedingPrefix || t("noBreederPrefix")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("address")}
            value={user?.address || t("noData")}
            error={user?.address === undefined || user?.address === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("postalCode")}
            value={user?.postalCode || t("noData")}
            error={user?.postalCode === undefined || user?.postalCode === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("city")}
            value={user?.city || t("noData")}
            error={user?.city === undefined || user?.city === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("country")}
            value={
              user?.country
                ? countries.find(
                    (c) => c.code === user?.country?.toUpperCase()
                  )[i18n.language]
                : t("noData")
            }
            error={user?.country === undefined || user?.country === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("phoneNumber")}
            value={user?.phone || t("noData")}
            error={user?.phone === undefined || user?.phone === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("email")}
            value={user?.email || t("noData")}
            error={user?.email === undefined || user?.email === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("permissionToPublishName")}
            value={user?.permissions?.name ? t("yes") : t("no")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("permissionToPublishCity")}
            value={user?.permissions?.city ? t("yes") : t("no")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ float: "right" }}>
            <FormControl error={errors.checkboxes?.breederVerified && true}>
              <FormControlLabel
                control={
                  <Controller
                    name="checkboxes.breederVerified"
                    control={control}
                    defaultValue={checkboxes?.breederVerified || false}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label={t("breederInfoConfirm")}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {errors.checkboxes?.breederVerified && (
            <Alert severity="error">{t("breederInfoConfirmError")}</Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
}
