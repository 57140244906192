import React, { useState } from "react";
import i18n from "../i18n/i18n";
import Flag from "react-world-flags";

// MUI
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import "../App.css";

const style = {
  boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
  borderRadius: "3px",
  "& :hover": {
    opacity: "0.5",
  },
};

export default function LangPicker() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ pl: 2 }}>
        <Flag
          code={i18n.language.slice(0, 2) === "fi" ? "fi" : "us"}
          width="26"
          alt={i18n.language}
          className="flag"
        />
        <Typography variant="caption" sx={{ color: "#ffffff", pl: 1 }}>
          {i18n.language.slice(0, 2) === "fi" ? "FI" : "US"}
        </Typography>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
        <MenuItem onClick={() => handleLanguage("fi")}>
          <Flag code="fi" width="26" alt="Finnish" className="flag" />{" "}
          <Typography variant="caption" color="secondary" sx={{ pl: 1 }}>
            FI
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleLanguage("en")}>
          <Flag code="US" width="26" alt="English" className="flag" />
          <Typography variant="caption" color="secondary" sx={{ pl: 1 }}>
            US
          </Typography>
        </MenuItem>
        {/*           <MenuItem onClick={() => handleLanguage("es")}>
            <Flag code="ES" width="26" alt="Spanish" className="flag" />
            <Typography variant="caption" color="secondary" sx={{ pl: 1 }}>
              ES
            </Typography>
          </MenuItem> */}
      </Menu>
    </>
  );
}
