import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

// COMPONENTS
import { getKruValueLabel } from "../../constants/helpers";
import { countries } from "../../constants/countries";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Typography,
  Box,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
} from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";

import { useRecoilValue } from "recoil";
import { existingTempLitterState } from "../../recoil/registrationStates";

export default function LitterPaymentSuccess() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const language = i18n.language;

  const litter = useRecoilValue(existingTempLitterState(id));

  return (
    <Paper sx={{ p: 4 }}>
      <Grid container alignItems="center" spacing={6}>
        <Grid item xs={12} sm={9}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            {t("paymentSuccessfulHeader")}
          </Typography>
          <Typography variant="body">{t("paymentSuccessfulText")}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box display="flex" justifyContent="center">
            <CheckCircleOutlined
              color="primary"
              sx={{
                fontSize: { xs: "80px", sm: "100px" },
                textAlign: "center",
              }}
            />
          </Box>
        </Grid>
        {litter && (
          <Grid item xs={12}>
            <Typography variant="h3">{t("summary")}</Typography>
            <TableContainer>
              <Table sx={{ minWidth: 200 }}>
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">{t("registry")}</TableCell>
                    <TableCell>
                      {litter.puppies[0].organizations[0]?.name || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("dob")}</TableCell>
                    <TableCell>
                      {dayjs(litter.dateOfBirth).format("DD.MM.YYYY") || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("countryOfOrigin")}</TableCell>
                    <TableCell>
                      {countries.find(
                        (c) => c.code === litter.puppies[0].countryOfOrigin
                      )[language] || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("sire")}</TableCell>
                    <TableCell>
                      {litter.sire.name || ""} {litter.sire.regNumber || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t("dam")}</TableCell>
                    <TableCell>
                      {litter.dam.name || ""} {litter.dam.regNumber || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} variant="head">
                      {t("puppies2")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  {litter.puppies.map((puppy, i) => (
                    <TableRow key={i}>
                      <TableCell>{puppy.name || ""}</TableCell>
                      <TableCell>{puppy.microchipNumber || ""}</TableCell>
                      <TableCell>{t(puppy.sex) || ""}</TableCell>
                      <TableCell>
                        {puppy.color.map((v, i) => {
                          return (
                            <div key={i}>
                              {getKruValueLabel(v, language)}
                              <br />
                            </div>
                          );
                        }) || "-"}
                      </TableCell>
                      <TableCell>
                        {puppy.coat.map((v, i) => {
                          return (
                            <div key={i}>
                              {getKruValueLabel(v, language)}
                              <br />
                            </div>
                          );
                        }) || "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
