// PRIVATE API ROUTES

import { request, privateUrl, specialUrl } from "./common";

// TODO: Handle errors somehow here instead of in every page

/**
 * Returns the current users document
 * Creates a new user document if userId nor email is found
 */
export async function getCurrentUser({ accessToken }) {
  const url = privateUrl`/users/currentuser`;
  return request({ url, accessToken });
}

export async function getUsersOwnedDogs({
  accessToken,
  options = { populate: "breeder organizations" },
}) {
  if (!accessToken) return;
  const url = privateUrl`/dogs/owned`;
  return request({ url, accessToken, body: { options } });
}

export async function getUsersLitters({
  accessToken,
  options = { populate: "sire dam puppies" },
}) {
  if (!accessToken) return;
  const url = privateUrl`/litters/currentuser`;
  return request({ url, accessToken, body: { options } });
}

export async function getUsersTempLitters({
  accessToken,
  options = {
    populate: "sire dam verifications",
  },
}) {
  if (!accessToken) return;
  const url = privateUrl`/templitters/currentuser`;
  return request({ url, accessToken, body: { options } });
}

export async function getUsersTempDogs({ accessToken, options = {} }) {
  if (!accessToken) return;
  const url = privateUrl`/tempdogs/currentuser`;
  return request({
    url,
    accessToken,
    body: {
      options,
      query: { regType: "reg.types.individual" },
    },
  });
}

export async function updateUser(update, { accessToken }) {
  const url = privateUrl`/users/currentuser`;
  return request({ url, method: "PATCH", accessToken, body: { update } });
}

export async function verifyUser(verifyUser, { accessToken }) {
  return updateUser({ isVerified: verifyUser }, { accessToken });
}

export async function checkIfMicrochipExists(microchip, { accessToken }) {
  const url = specialUrl`/microchipExists/${microchip}`;
  return request({ url, method: "GET", accessToken });
}

export async function checkIfUserIsOwner(_id, { accessToken }) {
  const url = specialUrl`/userOwnsDog/${_id}`;
  return request({ url, method: "GET", accessToken });
}

export async function registerTempDog(document, { accessToken }) {
  const url = privateUrl`/tempdogs/new`;
  return request({ url, accessToken, body: { document } });
}
export const registerPuppy = registerTempDog;

export async function getTempDogById(
  _id,
  { accessToken, options = { populate: "organizations" } }
) {
  const url = privateUrl`/tempdogs/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function registerLitter(document, { accessToken }) {
  const url = privateUrl`/templitters/new`;
  return request({ url, accessToken, body: { document } });
}

export async function getTempLitterById(
  _id,
  {
    accessToken,
    options = {
      populate: { path: "puppies sire dam", populate: "organizations" },
    },
  }
) {
  const url = privateUrl`/templitters/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function addAttachment(attachment, { accessToken }) {
  const url = privateUrl`/attachments/new`;
  return request({ url, accessToken, body: attachment });
}

/**
 * CREATE VERIFICATION
 * @param {{accessToken: String, verification: {operation: String, parentDoc: String, parentDocType: String, user: String}}} opts
 * @param {String} verification.operation - `"verifyLitterSire" || "verifyLitterDam"`
 */
export async function createVerification(document, { accessToken }) {
  const url = privateUrl`/verifications/new`;
  return request({ url, accessToken, body: { document } });
}

export async function getVerification(
  _id,
  { accessToken, options = { populate: "parentDoc" } }
) {
  const url = privateUrl`/verifications/byId/${_id}`;
  return request({ url, accessToken, body: { options } });
}

export async function acceptVerification(_id, { accessToken }) {
  const url = privateUrl`/verifications/acceptById/${_id}`;
  return request({ url, accessToken });
}

/**
 * GET ALL VERIFICATIONS OF A PARENT DOCUMENT
 * @param {String} accessToken user access token
 * @param {String} parentDoc Parent document _id
 * @param {String} parentDocType Parent document type
 */
export async function getVerifications({
  accessToken,
  parentDoc,
  parentDocType,
}) {
  const url = privateUrl`/verifications/byParentDoc`;
  return request({
    url,
    accessToken,
    body: { query: { parentDoc, parentDocType } },
  });
}

export async function createStatistic(data, { accessToken }) {
  const url = privateUrl`/statistics/new`;
  return request({
    url,
    accessToken,
    body: { document: { data, type: "litterRegistration" } },
  });
}

export async function createCheckout({
  accessToken,
  parentDoc,
  parentDocType,
}) {
  const url = privateUrl`/orders/new`;
  return request({
    url,
    accessToken,
    body: { document: { parentDoc, parentDocType } },
  });
}
