import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
  userState,
} from "../../recoil/globalStates";
import { activeStepState } from "../../recoil/registrationStates";

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { sendLitterRegDataToApi } from "../../components/registrationComponents/constructObject";
import LitterRegistration from "./litterRegistration";

export default function LitterRegistrationContainer() {
  const { t } = useTranslation();
  const setSnackbarState = useSetRecoilState(snackBarState);
  const setLoading = useSetRecoilState(loadingState);

  const user = useRecoilValue(userState);
  const accessToken = useRecoilValue(accessTokenState);

  const methods = useForm({ mode: "all" });
  const {
    trigger,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const litterForm = watch();
  const { checkboxes } = watch();

  const isErrorsEmpty = Object.keys(errors).length === 0;

  const [activeStep, setActiveStep] = useRecoilState(activeStepState);

  // Increase activestep to show next step, use trigger to validate before proceeding
  const handleNext = async () => {
    await trigger().then((res) => {
      if (res === true) {
        setActiveStep(activeStep + 1);
      }
    });
  };

  // Decrease activestep to show previous step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // Handle form data formatting and sending to APIs
  const onSubmit = async () => {
    await trigger().then((res) => {
      if (res === true) {
        setLoading(true);
        sendLitterRegDataToApi(litterForm, user, accessToken).then((res) => {
          if (res) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          }
          setLoading(false);
        });
      }
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper sx={{ p: { xs: 2, sm: 4 } }}>
            {user && !user.isVerified && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <AlertTitle>{t("accountNotVerifiedErrorTitle")}</AlertTitle>
                {t("accountNotVerifiedErrorText")}
              </Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack
                  display="flex"
                  justifyContent="space-between"
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <Typography variant="h1" sx={{ mb: 3 }}>
                    {t("litterRegistration")}
                  </Typography>
                  <Typography
                    variant="body"
                    sx={{ mr: 2, mt: 1, float: "right" }}
                  >
                    {t("mandatoryFields")}{" "}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <LitterRegistration errors={errors} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("previous")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Box>
                    {!isErrorsEmpty && (
                      <Typography variant="caption" color="error">
                        {t("errorCheckInput")}
                      </Typography>
                    )}

                    {activeStep === 2 ? (
                      <Button
                        onClick={onSubmit}
                        disabled={
                          !checkboxes?.dataVerified ||
                          !checkboxes?.eulaAgreement
                        }
                      >
                        {t("proceedToPay")}
                      </Button>
                    ) : (
                      <Button onClick={handleNext}>{t("next")}</Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </FormProvider>
    </>
  );
}
