import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  TextField,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

// API routes
import { checkIfUserIsOwner } from "../../../api/privateRoutes";

import { accessTokenState } from "../../../recoil/globalStates";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  userIsDamOwnerState,
  userIsSireOwnerState,
} from "../../../recoil/registrationStates";
import { dogsByGenderState } from "../../../recoil/publicStates";

export default function LitterParents() {
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { dam, sire, verificationNeeded } = watch();

  const [userIsSireOwner, setUserIsSireOwner] =
    useRecoilState(userIsSireOwnerState);
  const [userIsDamOwner, setUserIsDamOwner] =
    useRecoilState(userIsDamOwnerState);

  const [open, setOpen] = useState({
    sire: false,
    dam: false,
  });

  const femaleDogs = useRecoilValue(dogsByGenderState("female"));
  const maleDogs = useRecoilValue(dogsByGenderState("male"));

  const selectedSire = useWatch({ control, name: "sire" });
  const selectedDam = useWatch({ control, name: "dam" });

  useEffect(() => {
    if (selectedSire) {
      checkIfUserIsOwner(selectedSire._id, { accessToken }).then((res) => {
        setUserIsSireOwner(res);
      });
    }
  }, [selectedSire]);

  useEffect(() => {
    if (selectedDam) {
      checkIfUserIsOwner(selectedDam._id, { accessToken }).then((res) => {
        setUserIsDamOwner(res);
      });
    }
  }, [selectedDam]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("litterParents")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("litterParentsInfoText")}
            <br />
            <br />
            {t("litterParentsInfoText2")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Divider textAlign="left" sx={{ mb: 4 }}>
            <Typography variant="h3">{t("sire")}</Typography>
          </Divider>
          <Controller
            name="sire"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={maleDogs}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {option.regNumber}
                  </li>
                )}
                onOpen={() => {
                  setOpen({ ...open, sire: true });
                }}
                placeholder={t("sireOfTheLitter")}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option._id == value._id
                }
                autoComplete
                noOptionsText={t("noData")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("sireOfTheLitter")}
                    required
                    error={errors.sire && true}
                    helperText={errors.sire && t("required")}
                  />
                )}
                onChange={(e, data) => onChange(data)}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={sire || null}
            control={control}
          />

          {userIsSireOwner !== null &&
            (userIsSireOwner ? (
              <Typography variant="body">{t("youAreDogsOwnerText")}</Typography>
            ) : (
              <>
                <Typography variant="body">
                  {t("youAreNotDogsOwnerText")}
                </Typography>
                <br />
                <br />
                <FormControl>
                  <FormLabel id="sireSignature">
                    {t("siresMatingCertificate")}
                  </FormLabel>
                  <Controller
                    name="verificationNeeded.sire"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={verificationNeeded?.sire || "email"}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label={t("emailParentVerification")}
                        />
                        <FormControlLabel
                          value="attachment"
                          control={<Radio />}
                          label={t("attachmentParentVerification")}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </>
            ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Divider textAlign="left" sx={{ mb: 4 }}>
            <Typography variant="h3">{t("dam")}</Typography>
          </Divider>
          <Controller
            name="dam"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={femaleDogs}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {option.regNumber}
                  </li>
                )}
                onOpen={() => {
                  setOpen({ ...open, dam: true });
                }}
                placeholder={t("damOfTheLitter")}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option._id == value._id
                }
                autoComplete
                noOptionsText={t("noData")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("damOfTheLitter")}
                    required
                    error={errors.dam && true}
                    helperText={errors.dam && t("required")}
                  />
                )}
                onChange={(e, data) => onChange(data)}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={dam || null}
            control={control}
          />
          {userIsDamOwner !== null &&
            (userIsDamOwner ? (
              <Typography variant="body">{t("youAreDogsOwnerText")}</Typography>
            ) : (
              <>
                <Typography variant="body">
                  {t("youAreNotDogsOwnerText")}
                </Typography>
                <br />
                <br />
                <FormControl>
                  <FormLabel id="damSignature">
                    {t("damsMatingCertificate")}
                  </FormLabel>
                  <Controller
                    name="verificationNeeded.dam"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={verificationNeeded?.dam || "email"}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label={t("emailParentVerification")}
                        />
                        <FormControlLabel
                          value="attachment"
                          control={<Radio />}
                          label={t("attachmentParentVerification")}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </>
            ))}
        </Grid>
      </Grid>
    </>
  );
}
