import { createTheme } from "@mui/material/styles";
import { fiFI } from "@mui/material/locale";
import i18next from "i18next";

export const config = {
  logoFont: "'Spinnaker', sans-serif",
  // Cards
  headerFont: "'Josefin Sans', sans-serif",
  textFont: "'Open Sans', sans-serif",
  // Sukutaulu
  nameFont: "'Josefin Sans', sans-serif",
};

const theme = createTheme(
  {
    palette: {
      primary: { main: "#00A692" },
      secondary: { main: "#004569" },
      background: { default: "#f9f9f9" },
      white: { main: "#ffffff" },
      lightGray: { default: "#f7f7f7" },
      gray: { main: "#a3a3a3" },
    },
    typography: {
      fontFamily: '"Open Sans", Verdana, sans-serif',
      h1: {
        fontFamily: '"Josefin Sans", sans-serif',
        color: "#004569",
        fontSize: "1.8rem",
        fontWeight: 300,
      },
      h2: {
        fontFamily: '"Josefin Sans", sans-serif',
        color: "#004569",
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      h3: {
        fontFamily: '"Josefin Sans", sans-serif',
        color: "#004569",
        fontSize: "1.3rem",
        fontWeight: 400,
      },
      h4: {
        fontFamily: '"Open Sans", sans-serif',
        color: "#004569",
        fontSize: "1.1rem",
        fontWeight: 600,
      },
      h5: {
        fontFamily: '"Open Sans", sans-serif',
        color: "#004569",
        fontSize: "1.0rem",
        fontWeight: 600,
      },
      h6: {
        fontFamily: '"Open Sans", sans-serif',
        color: "#004569",
        fontSize: "0.9rem",
        fontWeight: 500,
      },
      body: {
        fontSize: "0.9rem",
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 400,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 600,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
    },
  },
  i18next.language === "fi" ? fiFI : null
);

export default theme;
