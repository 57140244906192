import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

import EmailVerifiedBadge from "./emailVerifiedBadge";

// MUI Icons
import Logout from "@mui/icons-material/Logout";

import { Paper, Chip, Typography, Grid, Avatar, Button } from "@mui/material";
import {
  subscriptionManagementUrl,
  subscriptionPaymentUrl,
} from "../../constants/stripeLinks";

export default function LoggedInUserBox({ userDocument }) {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const { isLoading, logout, user } = useAuth0();

  return (
    <>
      {!isLoading && userDocument && (
        <Paper>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ px: 2, py: { xs: 2, sm: 4 } }}
          >
            <Grid item xs={12}>
              <Avatar
                src={user?.picture}
                alt={user?.name}
                sx={{ width: 100, height: 100, mb: 3 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ display: "block" }}>
                {user?.email} {user?.email_verified && <EmailVerifiedBadge />}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Chip
                variant="outlined"
                color="primary"
                size="small"
                label={`${userDocument?.role}`}
                sx={{ mt: 1, mb: 2, textTransform: "uppercase" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => logout()}
                sx={{ mt: 2 }}
              >
                <Logout fontSize="small" sx={{ mr: 1 }} />
                {t("logOut")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
