import React from "react";
import { LoaderOverlay } from "./elements/Loader";

// Redirect domain
import { domain } from "./api/common.js";

// Auth0ProviderWithNavigate
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// AuthenticationGuard
import { withAuthenticationRequired } from "@auth0/auth0-react";

const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  if (!(domain && AUTH_CLIENT_ID))
    return console.error("Error: no domain or cliendId");

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={AUTH_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${domain}/user`,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>
        <LoaderOverlay />
      </div>
    ),
  });
  return <Component />;
};

export default Auth0ProviderWithNavigate;
