import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Queue } from "@mui/icons-material";
import { Button, Link, Menu } from "@mui/material";
import StyledMenuItem from "../../../elements/styledMenuItem";
import { useAuth0 } from "@auth0/auth0-react";

export default function RegisterMenu() {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const items = [
    <StyledMenuItem key={0} divider component={Link} href="/dog-registration">
      {t("registerDog")}
    </StyledMenuItem>,
    <StyledMenuItem key={1} component={Link} href="/litter-registration">
      {t("registerLitter")}
    </StyledMenuItem>,
  ];

  const handleClick = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget);
    } else {
      loginWithRedirect();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        name="register"
        color="white"
        onClick={handleClick}
        startIcon={<Queue />}
      >
        {t("register")}
      </Button>
      <Menu id="register" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items}
      </Menu>
    </>
  );
}
