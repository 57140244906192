import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { countFemales, countMales } from "../../../constants/helpers";

// Components
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Divider,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  IconButton,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  Alert,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// API
import { checkIfMicrochipExists } from "../../../api/privateRoutes";
import { countries } from "../../../constants/countries";
import {
  accessTokenState,
  kruValuesState,
  userState,
} from "../../../recoil/globalStates";
import { useRecoilValue } from "recoil";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

export default function LitterPuppies() {
  const { t, i18n } = useTranslation();
  const kruValues = useRecoilValue(kruValuesState);
  const user = useRecoilValue(userState);
  const accessToken = useRecoilValue(accessTokenState);

  const language = i18n.language;

  const breedingPrefix = user?.breedingPrefix || "";

  const {
    control,
    watch,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { countryOfOrigin, dateOfBirth, puppies } = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    rules: {
      required: t("addAtLeastOnePuppy"),
    },
    name: "puppies",
  });

  const addPuppy = () => {
    append({
      name: breedingPrefix || "",
      microchipNumber: "",
      sex: "male",
      color: "",
      markings: [],
      coattype: "",
      coatqualities: [],
    });
  };

  const checkMicrochipNumber = (e) => {
    if (e.target.value && e.target.value !== "") {
      checkIfMicrochipExists(e.target.value, { accessToken }).then((response) => {
        if (response === true) {
          setError(e.target.name, {
            type: "custom",
            message: t("checkMicrochipNumber"),
          });
        } else {
          clearErrors(e.target.name);
        }
      });
      clearErrors(e.target.name);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("litterInformation")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue={dateOfBirth || null}
            rules={{
              required: t("required"),
            }}
            render={({ field: { ref, name, ...field } }) => (
              <DatePicker
                {...field}
                required
                inputRef={ref}
                label={t("litterDob")}
                onChange={(e) => field.onChange(dayjs(e).toISOString())}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    name={name}
                    error={errors?.dateOfBirth && true}
                    helperText={errors && errors?.dateOfBirth?.message}
                  />
                )}
                disableFuture
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="countryOfOrigin"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={countries}
                getOptionLabel={(option) =>
                  option[language] ? option[language] : ""
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {option[language]} {option.code}
                  </li>
                )}
                placeholder={t("countryOfOrigin")}
                isOptionEqualToValue={(option, value) =>
                  option.code == value.code
                }
                renderInput={(params) => (
                  <TextField {...params} label={t("countryOfOrigin")} />
                )}
                onChange={(e, data) => onChange(data)}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={
              countryOfOrigin ||
              countries.find((c) => c.code === user.country.toUpperCase())
            }
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Controller
              name="stats.deadPuppies"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label={t("countOfDeadPuppies")}
                />
              )}
            />
            <FormHelperText>{t("voluntaryDataInfoText")}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="mating">{t("mating")}</InputLabel>
            <Controller
              name="stats.mating"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} fullWidth label={t("mating")}>
                  <MenuItem value="natural">{t("natural")}</MenuItem>
                  <MenuItem value="artificialInsemination">
                    {t("artificialInsemination")}
                  </MenuItem>
                </Select>
              )}
            />
            <FormHelperText>{t("voluntaryDataInfoText")} </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="birth">{t("birth")}</InputLabel>
            <Controller
              name="stats.birth"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} fullWidth label={t("birth")}>
                  <MenuItem value="natural">{t("natural")}</MenuItem>
                  <MenuItem value="cesareanSection">
                    {t("cesareanSection")}
                  </MenuItem>
                  <MenuItem value="laborDifficulties">
                    {t("laborDifficulties")}
                  </MenuItem>
                </Select>
              )}
            />
            <FormHelperText>{t("voluntaryDataInfoText")} </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ my: 1 }}>
            {t("microchipCertificate")}
          </Typography>
          <Typography variant="body" sx={{ my: 2 }}>
            {t("microchipCertificateText")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ mt: 2 }}>
            <Typography variant="h2">{t("puppies2")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="space-between" direction="row"></Stack>
          <Grid item xs={12}>
            {fields.map((puppy, i) => (
              <Paper variant="outlined" key={puppy.id} sx={{ mb: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Stack justifyContent="space-between" direction="row">
                      <Typography variant="h4">
                        {`${t("puppy")} ${i + 1}`}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          if (confirm(t("deletePuppyConfirmation"))) {
                            remove(i);
                            trigger();
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      name={`puppies[${i}].name`}
                      control={control}
                      defaultValue={puppies[i].name || breedingPrefix}
                      rules={{
                        required: t("required"),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          label={t("puppysName")}
                          error={errors?.puppies?.[i]?.puppyName && true}
                          helperText={
                            errors?.puppies?.[i] &&
                            errors?.puppies?.[i]?.puppyName?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`puppies[${i}].microchipNumber`}
                      control={control}
                      defaultValue={puppies[i].microchipNumber || null}
                      rules={{
                        required: t("required"),
                        onBlur: (e) => checkMicrochipNumber(e),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          label={t("microchipNumber")}
                          error={errors?.puppies?.[i]?.microchipNumber && true}
                          helperText={
                            errors?.puppies?.[i] &&
                            errors?.puppies?.[i]?.microchipNumber?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormLabel>{t("sex")}</FormLabel>
                    <Controller
                      name={`puppies[${i}].sex`}
                      control={control}
                      defaultValue={puppies[i].sex || "male"}
                      rules={{
                        required: t("required"),
                      }}
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label={
                              <Typography variant="body">
                                {t("male")}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label={
                              <Typography variant="body">
                                {t("female")}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("mainColor")}</InputLabel>
                      <Controller
                        name={`puppies[${i}].color`}
                        control={control}
                        defaultValue={puppies[i].color || ""}
                        render={({ field }) => (
                          <Select {...field} fullWidth label={t("mainColor")}>
                            {kruValues
                              .filter((i) => i.category === "coat.colors")
                              .map((color) => (
                                <MenuItem key={color._id} value={color._id}>
                                  {color[language].name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("markings")}</InputLabel>
                      <Controller
                        name={`puppies[${i}].markings`}
                        control={control}
                        defaultValue={puppies[i].markings || []}
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            multiple
                            label={t("markings")}
                          >
                            {kruValues
                              .filter((i) => i.category === "coat.markings")
                              .map((color) => (
                                <MenuItem key={color._id} value={color._id}>
                                  {color[language].name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("coatType")}</InputLabel>
                      <Controller
                        name={`puppies[${i}].coattype`}
                        control={control}
                        defaultValue={puppies[i].coattype || ""}
                        render={({ field }) => (
                          <Select {...field} fullWidth label={t("coatType")}>
                            {kruValues
                              .filter((i) => i.category === "coat.types")
                              .map((color) => (
                                <MenuItem key={color._id} value={color._id}>
                                  {color[language].name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("coatQualities")}</InputLabel>
                      <Controller
                        name={`puppies[${i}].coatqualities`}
                        control={control}
                        defaultValue={puppies[i].coatqualities || []}
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            multiple
                            label={t("coatQualities")}
                          >
                            {kruValues
                              .filter((i) => i.category === "coat.qualities")
                              .map((color) => (
                                <MenuItem key={color._id} value={color._id}>
                                  {color[language].name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            ))}{" "}
          </Grid>
          {errors?.puppies?.root && (
            <Grid item xs={12}>
              <Alert severity="error">{errors.puppies.root.message}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" spacing={3} justifyContent="space-between">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addPuppy}
              >
                {t("addPuppy")}
              </Button>
              <Stack spacing={3} direction="row">
                <Typography variant="body">
                  {t("males")} {countMales(watch("puppies"))}
                </Typography>
                <Typography variant="body">
                  {t("females")} {countFemales(watch("puppies"))}
                </Typography>
                <Typography variant="body">
                  {t("total")} {watch("puppies")?.length || 0}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
