import React, { Suspense } from "react";

// Components
import LitterCard from "../../elements/LitterCard";
import { Loader } from "../../elements/Loader";

// API connection
import { newestLittersState } from "../../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function NewestLitters() {
  const litters = useRecoilValue(newestLittersState);

  return (
    <Suspense fallback={<Loader />}>
      {litters.map((litter) => (
        <div key={litter._id}>
          <LitterCard litter={litter} />
        </div>
      ))}
    </Suspense>
  );
}
