import React, { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";

import { getBreederInfo } from "../../constants/helpers";

import SkrLogo from "../../images/skr-logo-square.png";
import SkrTextLogo from "../../images/logo_tekstilla_sin.png";
import DatacanisLogo from "../../images/datacanis_logo_bl.png";

import { Typography, Divider, Grid, Box } from "@mui/material";
import PedigreeTable from "../pedigreeComponents/PedigreeTable";
import { imageNode, smallImageNode } from "../pedigreeComponents/nodeElements";
import { useTranslation } from "react-i18next";

// If we want to use function component, we need to pass the ref with forwardRef here,
// since react-to-print uses ref to fetch the underlying DOM

const CertificateTemplate = forwardRef(function CertificateTemplate(
  { dog },
  ref
) {
  const { t } = useTranslation();
  const breeder = getBreederInfo(dog?.breeder);

  const bgStyle = {
    opacity: 0.05,
    position: "absolute",
    left: 0,
    top: "285px",
    width: "100%",
    height: "auto",
  };

  const wrapperStyle = {
    overflow: "hidden",
    position: "relative",
    minHeight: "1000px",
    maxHeight: "1000px",
  };

  return (
    <>
      <div ref={ref} style={wrapperStyle}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          sx={{ p: 3 }}
        >
          <Grid item xs={8}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <img style={{ height: "40px" }} src={DatacanisLogo} alt="" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img style={{ height: "60px" }} src={SkrTextLogo} alt="" />
            </Box> */}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }}>
              <Typography align="center" variant="h1">
                {dog?.organizations && dog.organizations[0]?.name}
              </Typography>
              <Typography align="center" variant="h6">
                {t("dogsRegistrationCertificate")}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("dogsName")}</Typography>

            <Typography variant="h5">{dog?.name || t("noData")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("dob")}</Typography>

            <Typography variant="h5">
              {dayjs(dog?.dateOfBirth).format("DD.MM.YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">{t("sex")}</Typography>

            <Typography variant="h5">{t(dog?.sex) || t("noData")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{t("registrationNumber")}</Typography>

            <Typography variant="h5">
              {dog?.regNumber || t("noData")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{t("microchipNumber")}</Typography>

            <Typography variant="h5">
              {dog?.microchipNumber || t("noData")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("breeder")}</Typography>

            <Typography variant="h5">{breeder}</Typography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Typography variant="h5">{t("pedigree")}</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <div id="treeWrapper" style={{ width: "100%", height: "500px" }}>
              <PedigreeTable
                dog={dog}
                nodeSize={{ x: 200, y: 40 }}
                translate={{ x: 0, y: 200 }}
                zoom={0.8}
                renderCustomNodeElement={smallImageNode}
              />
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="right" variant="caption" sx={{ mt: 2 }}>
                {t("printedFromDatacanisAt")}{" "}
                {dayjs().format("DD.MM.YYYY HH.mm")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <img style={bgStyle} src={SkrLogo} alt="" />
      </div>
    </>
  );
});

export default CertificateTemplate;
