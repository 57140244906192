import React from "react";
import { GetDogColumns } from "../../../components/adminDashboardComponents/adminDatagridColumns";

// Recoil
import { adminAllDogsState } from "../../../recoil/adminStates";
import { useRecoilValue } from "recoil";

// MUI
import { Typography, Divider, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function AdminAllDogs() {
  const dogs = useRecoilValue(adminAllDogsState);

  const handleRowClick = (params) => {
    window.location = `/admin/dog/${params.row._id}`;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">All dogs</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={dogs}
            getRowId={(row) => row._id}
            columns={GetDogColumns("dog")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminAllDogs;
