export const litterRegRules = {
  // price per puppy in euros
  pricePerPuppy: 13.0,
  // minimum age of litter's parents in months
  parentAgeMonthsMin: 18,
  // minimum amount of months between this litter and dam's last litter
  lastLitterMin: 10,
  // maximum length of dogs name
  nameLength: 40,
};

export const dogRegRules = {
  // price per dog in euros
  pricePerDog: 18.9,
  // maximum length of dogs name
  nameLength: 40,
  // minimum age of the dog in months
  ageMonthsMin: 3,
};
