import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { countries } from "../../../constants/countries";
import { Launch } from "@mui/icons-material";
import { organizationsState } from "../../../recoil/publicStates";
import { kruValuesState } from "../../../recoil/globalStates";
import { useRecoilValue } from "recoil";

export default function DogInformation({ dog, setDog, formErrors }) {
  const { t, i18n } = useTranslation();

  const kruValues = useRecoilValue(kruValuesState);
  const organizations = useRecoilValue(organizationsState);

  const language = i18n.language;

  const [selectedCountry, setSelectedCountry] = useState(
    dog.countryOfOrigin && countries.find((c) => c.code === dog.countryOfOrigin)
  );

  /*  const [ageCheck, setAgeCheck] = useState({ ok: true, age: null });

   useEffect(() => {
    if (dog.dateOfBirth !== null) {
      setAgeCheck(checkDogAge(dog.dateOfBirth));
    } else {
      setAgeCheck({ ok: true, age: null });
    }
  }, [dog.dateOfBirth]); */

  const handleChange = (event) => {
    setDog({
      ...dog,
      [event.target.name]: event.target.value,
    });
  };

  const handleOrgChange = (event) => {
    setDog({
      ...dog,
      registry: event.target.value,
    });
  };

  // separate handling for numbers since they have to be integers (not decimals)
  const handleNumberChange = (event) => {
    setDog({
      ...dog,
      [event.target.name]: Number.parseInt(event.target.value),
    });
  };

  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
    setDog({
      ...dog,
      countryOfOrigin: newValue.code,
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("registry")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("selectRegistryInfoText", { formType: "dog" })}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <InputLabel>{t("registry")}</InputLabel>
            <Select
              fullWidth
              required
              label={t("registry")}
              name="registry"
              placeholder={t("registry")}
              onChange={handleOrgChange}
              value={dog.registry}
              error={formErrors?.registryIsNull}
            >
              {organizations?.map((org) => (
                <MenuItem key={org._id} value={org}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.registryIsNull && (
              <FormHelperText error>{t("required")}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 2 }}>
          {dog.registry && (
            <Button
              key={dog.registry}
              href={dog.registry?.url}
              target="_blank"
              endIcon={<Launch />}
            >
              {t("registryGuidelines")}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("dogsInformation")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("formInfoText")}
            <b>{t("ownerInfoText3")}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            name="name"
            label={t("dogsName")}
            value={dog.name || ""}
            onChange={handleChange}
            error={formErrors?.nameIsNull}
            helperText={formErrors?.nameIsNull ? t("required") : null}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            name="microchipNumber"
            label={t("microchipNumber")}
            value={dog.microchipNumber || ""}
            onChange={handleChange}
            error={formErrors?.microchipIsNull || formErrors.microChipExists}
            helperText={
              formErrors?.microchipIsNull
                ? t("required")
                : formErrors.microChipExists
                ? t("checkMicrochipNumber")
                : null
            }
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormLabel>{t("sex")}</FormLabel>
          <RadioGroup row name="sex" value={dog.sex} onChange={handleChange}>
            <FormControlLabel
              value="male"
              control={<Radio />}
              label={<Typography variant="body">{t("male")}</Typography>}
            />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label={<Typography variant="body">{t("female")}</Typography>}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
          <DatePicker
            required
            disableFuture
            label={t("dob")}
            value={dog.dateOfBirth}
            onChange={(newVal) => {
              setDog({ ...dog, dateOfBirth: dayjs(newVal).format() });
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                required
                helperText={formErrors.dobIsNull && t("required")}
                {...params}
                error={formErrors.dobIsNull}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            freeSolo
            label={t("countryOfOrigin")}
            name="countryOfOrigin"
            placeholder={t("countryOfOrigin")}
            onChange={handleCountryChange}
            options={countries}
            isOptionEqualToValue={(option, value) => option.code == value}
            getOptionLabel={(option) => option[language]}
            value={selectedCountry}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("countryOfOrigin")}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">{t("appearance")}</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("mainColor")}</InputLabel>
            <Select
              fullWidth
              label={t("mainColor")}
              name="color"
              placeholder={t("mainColor")}
              onChange={handleChange}
              value={dog?.color || ""}
            >
              {kruValues
                .filter((i) => i.category === "coat.colors")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color[language].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("markings")}</InputLabel>
            <Select
              fullWidth
              multiple
              label={t("markings")}
              name="markings"
              placeholder={t("markings")}
              onChange={handleChange}
              value={dog.markings}
            >
              {kruValues
                .filter((i) => i.category === "coat.markings")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color[language].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("coatType")}</InputLabel>
            <Select
              fullWidth
              label={t("coatType")}
              name="coattype"
              placeholder={t("coatType")}
              onChange={handleChange}
              value={dog.coattype}
            >
              {kruValues
                .filter((i) => i.category === "coat.types")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color[language].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("coatQualities")}</InputLabel>
            <Select
              fullWidth
              multiple
              label={t("coatQualities")}
              name="coatqualities"
              placeholder={t("coatQualities")}
              onChange={handleChange}
              value={dog.coatqualities}
            >
              {kruValues
                .filter((i) => i.category === "coat.qualities")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color[language].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="number"
            label={t("height")}
            name="height"
            placeholder={t("height")}
            onChange={handleNumberChange}
            value={dog?.height || ""}
            helperText={t("numberInputHelperText")}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="number"
            label={t("weigth")}
            name="weight"
            placeholder={t("weigth")}
            onChange={handleNumberChange}
            value={dog?.weight || ""}
            helperText={t("numberInputHelperText")}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
