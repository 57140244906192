import React, { Component } from "react";

// MUI
import { Avatar } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { kuvaServer } from "../constants/values";

export default class DogAvatar extends Component {
  render() {
    const hasPhoto = this.props.hasPhoto;
    const dogId = this.props.dogId;
    const alt = this.props.alt;
    return (
      <>
        {hasPhoto ? (
          <Avatar
            sx={{ bgcolor: "primary.main", width: 50, height: 50 }}
            alt={alt}
            src={kuvaServer + dogId + ".jpg"}
            variant="rounded"
          />
        ) : (
          <Avatar
            sx={{ bgcolor: "primary.main", width: 50, height: 50 }}
            alt={alt}
            variant="rounded"
          >
            {alt ? alt?.charAt(0) : <CheckBoxOutlineBlankIcon />}
          </Avatar>
        )}
      </>
    );
  }
}
