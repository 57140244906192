export const registries = [
  "Suomen Kennelliitto",
  "Svenska Kennelklubben",
  "Eesti Kennelliit",
  "Norsk Kennel Klub",
  "Dansk Kennel Klub",
  "Russian Cynological Federation",
  "The Kennel Club",
  "United Kennel Club",
  "National Kennel Club",
  "American Kennel Club",
  "Real Sociedad Canina de España",
];
