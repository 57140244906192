import React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
  Box,
  Switch,
  Select,
  FormGroup,
  FormControl,
  FormControlLabel,
  MenuItem,
  Stack,
  FormHelperText,
  Autocomplete,
  Paper,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { deleteUser } from "../../../api/adminRoutes";
import { citiesFi } from "../../../constants/fiCities";

import CertificatesList from "../../../components/formComponents/certificatesList";
import { Add } from "@mui/icons-material";
import { countries } from "../../../constants/countries";

import {
  accessTokenState,
  showSubmitState,
} from "../../../recoil/globalStates";
import {
  adminAllCertsState,
  adminAllOrgsState,
} from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

function UserForm({ action, user, emitHandleSubmit, handleModalClose }) {
  const accessToken = useRecoilValue(accessTokenState);
  const [showSubmit, setShowSubmit] = useRecoilState(showSubmitState);

  const [initialUser, setInitialUser] = useState(user);
  const [updatedUser, setUpdatedUser] = useState(false);

  const organizations = useRecoilValue(adminAllOrgsState);
  const [usersOrgs, setUsersOrgs] = useState(user?.organizations || []);

  const certificates = useRecoilValue(adminAllCertsState);
  const [newCert, setNewCert] = useState({
    certificate: "",
    issuingDate: "",
  });

  useEffect(() => {
    if (user) {
      // TODO: This could be done in recoil
      setInitialUser({
        ...initialUser,
        country: countries.find((c) => c.code === user.country) || "",
      });
    }
  }, []);

  const handleChange = (event) => {
    setUpdatedUser({
      ...updatedUser,
      [event.target.name]: event.target.value,
    });
    setInitialUser({
      ...initialUser,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
  };

  const handleOrgChange = (event, newValue) => {
    const orgIds = newValue.map((org) => org._id);
    setUsersOrgs(newValue);
    setUpdatedUser({
      ...updatedUser,
      organizations: orgIds,
    });
    setShowSubmit(true);
  };

  const handleChecked = (event) => {
    setUpdatedUser({
      ...updatedUser,
      [event.target.name]: event.target.checked,
    });
    setInitialUser({
      ...initialUser,
      [event.target.name]: event.target.checked,
    });
    setShowSubmit(true);
  };

  // Handled separately because permissions is a nested object
  const handlePermissions = (event) => {
    setUpdatedUser({
      ...updatedUser,
      permissions: {
        ...updatedUser?.permissions,
        [event.target.name]: event.target.checked,
      },
    });
    setInitialUser({
      ...initialUser,
      permissions: {
        ...updatedUser?.permissions,
        [event.target.name]: event.target.checked,
      },
    });
    setShowSubmit(true);
  };

  const handleCityChange = (event, newValue) => {
    setUpdatedUser({
      ...updatedUser,
      city: newValue,
    });
    setInitialUser({
      ...initialUser,
      city: newValue,
    });
    setShowSubmit(true);
  };

  const handleCountryChange = (event, newValue) => {
    setUpdatedUser({
      ...updatedUser,
      country: newValue.code,
    });
    setInitialUser({
      ...initialUser,
      country: newValue,
    });
    setShowSubmit(true);
  };

  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedUser, initialUser._id);
    setShowSubmit(false);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete ${initialUser.name} ? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteUser(initialUser._id, { accessToken }).then((res) => {
          if (res.error) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          } else {
            alert(`${res.name} has been removed.`);
            setTimeout((window.location = `/admin/allusers`), 3000);
          }
        });
      };
      deleteData();
    }
  };

  // Handle value changes in newCert object
  const handleNewCertChange = (e) => {
    setNewCert({ ...newCert, [e.target.name]: e.target.value });
  };

  const onAddNewCert = () => {
    let initCerts = initialUser.certificates || [];
    initCerts.push(newCert);

    setInitialUser({
      ...initialUser,
      certificates: initCerts,
    });

    const certsWithIds = initCerts.map((c) => ({
      certificate: c.certificate._id,
      issuingDate: c.issuingDate,
    }));

    setUpdatedUser({
      ...updatedUser,
      certificates: certsWithIds,
    });

    setNewCert({
      certificate: "",
      issuingDate: "",
    });
    setShowSubmit(true);
  };

  // Handle deleting a cert object from user.certificates
  const onDeleteCert = (i) => {
    if (confirm("Are you sure you want to delete certificate?")) {
      const certs = user.certificates;
      certs.splice(i, 1);
      setInitialUser({ ...initialUser, certificates: certs });
      const certsWithIds = certs.map((c) => ({
        certificate: c.certificate._id,
        issuingDate: c.issuingDate,
      }));
      setUpdatedUser({ ...updatedUser, certificates: certsWithIds });
      setShowSubmit(true);
    }
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} USER{" "}
              {action === "edit" && initialUser?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="flex-end">
            <Typography variant="caption">
              {`Created ${
                initialUser?.createdAt &&
                dayjs(initialUser?.createdAt).format("DD.MM.YYYY")
              }, updated ${
                initialUser?.updatedAt &&
                dayjs(initialUser?.updatedAt).format("DD.MM.YYYY")
              }, customer-ID (deprecated) ${initialUser?.asiakasnro || "-"}`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label="First name and surname"
            placeholder="First name and surname"
            value={initialUser?.name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="email"
            placeholder="Email address"
            label="Email address"
            value={initialUser?.email || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="phone"
            label="Phone number"
            placeholder="Phone number"
            type="tel"
            value={initialUser?.phone || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address"
            label="Address"
            placeholder="Address"
            value={initialUser?.address || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="postalCode"
            label="Postal code"
            placeholder="Postal code"
            type="number"
            inputMode="numeric"
            value={initialUser?.postalCode || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            freeSolo
            label="City"
            name="city"
            placeholder="City"
            onChange={handleCityChange}
            options={citiesFi}
            getOptionLabel={(option) => option}
            value={initialUser?.city || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            label="Country"
            name="country"
            placeholder="Country"
            onChange={handleCountryChange}
            options={countries}
            isOptionEqualToValue={(option, value) => option.code == value}
            getOptionLabel={(option) => `${option.en} (${option.code})`}
            value={initialUser?.country || null}
            renderOption={(props, option) => (
              <li {...props} key={option.code}>
                {option.en} {option.code}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          {organizations.length > 0 && (
            <Autocomplete
              fullWidth
              multiple
              label="Organizations"
              name="organizations"
              placeholder="Organizations"
              options={organizations}
              isOptionEqualToValue={(option, value) => option._id == value._id}
              getOptionLabel={(option) => (option ? option.name : "")}
              value={usersOrgs}
              onChange={handleOrgChange}
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Organizations" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Permissions</Typography>
          </Divider>
          <Typography variant="body">
            {
              "User's given permission to show spesific info in the public database"
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <InputLabel>Name permission</InputLabel>
            <FormControlLabel
              control={
                <Switch
                  name="name"
                  checked={initialUser?.permissions?.name || false}
                  onChange={handlePermissions}
                />
              }
              label={initialUser?.permissions?.name ? "Yes" : "No"}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <InputLabel>City permission</InputLabel>
            <FormControlLabel
              control={
                <Switch
                  name="city"
                  checked={initialUser?.permissions?.city || false}
                  onChange={handlePermissions}
                />
              }
              label={initialUser?.permissions?.city ? "Yes" : "No"}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Breeder</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="breedingPrefix"
            label="Breeder prefix"
            placeholder="Breeder prefix"
            value={initialUser?.breedingPrefix || ""}
            onChange={handleChange}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            fullWidth
            name="breederId"
            label="Breeder ID"
            placeholder="Breeder ID"
            value={initialUser?.breederId || ""}
            disabled
            helperText="Autogenerated, public"
          />
        </Grid>
        <Grid item xs={3}>
          <FormGroup>
            <InputLabel>Has Litters?</InputLabel>
            <FormControlLabel
              control={
                <Switch
                  name="hasLitters"
                  checked={initialUser?.hasLitters || false}
                  onChange={handleChecked}
                />
              }
              label={initialUser?.hasLitters ? "Yes" : "No"}
            />
            <FormHelperText>
              Should user be shown in breeder listing?
            </FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Certificates</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <CertificatesList
            certs={initialUser?.certificates}
            onDeleteCert={onDeleteCert}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 1 }}>
                  Add new Certificate
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Certificate</InputLabel>
                  <Select
                    fullWidth
                    label="Certificate"
                    name="certificate"
                    placeholder="Certificate"
                    onChange={handleNewCertChange}
                    value={newCert.certificate}
                  >
                    {certificates.map((cert) => (
                      <MenuItem key={cert._id} value={cert}>
                        {cert.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <DatePicker
                  label="Issuing date"
                  name="issuingDate"
                  value={newCert?.issuingDate || null}
                  onChange={(newVal) => {
                    setNewCert({
                      ...newCert,
                      issuingDate: dayjs(newVal).format(),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={onAddNewCert}
                  startIcon={<Add />}
                >
                  Add certificate
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" color="error">
              Account
            </Typography>
          </Divider>
        </Grid>
        <Paper variant="outlined" sx={{ borderColor: "error.main", p: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body" color="error">
                Do NOT change these values unless you are 100% certain you know
                what you are doing and are authorized to do so!
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Auth0 UserId"
                name="userId"
                placeholder="Auth0 UserId"
                value={initialUser?.userId || ""}
                onChange={handleChange}
                helperText="This should generate automatically. Do not change if not necessary."
              />
            </Grid>{" "}
            <Grid item xs={6}>
              <FormGroup>
                <InputLabel>Verified?</InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      name="isVerified"
                      checked={initialUser?.isVerified || false}
                      onChange={handleChecked}
                    />
                  }
                  label={initialUser?.isVerified ? "Yes" : "No"}
                />
                <FormHelperText>
                  Has DB user been connected with auth0 user (by default, user
                  should do this)
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  label="Role"
                  name="role"
                  value={initialUser?.role || ""}
                  onChange={handleChange}
                >
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="breeder">Breeder</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Access token"
                name="access_token"
                placeholder="Access token"
                value={initialUser?.access_token || ""}
                onChange={handleChange}
                helperText="This should generate automatically. Do not change if not necessary."
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedUser
              ? JSON.stringify(updatedUser, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <SaveIcon fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default UserForm;
