import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CountryAutocomplete({
  label,
  name,
  handleChange,
  options,
  value,
}) {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <Autocomplete
      fullWidth
      label={label}
      name={name}
      placeholder={label}
      onChange={(event, value) => handleChange(event, value, name)}
      options={options}
      isOptionEqualToValue={(option, value) => option.code == value.code}
      getOptionLabel={(option) => `${option[language]} (${option.code})`}
      value={value}
      renderOption={(props, option) => (
        <li {...props} key={option.code}>
          {option[language]} {option.code}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
