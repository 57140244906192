import React from "react";

// Routes
import { updateOrganization } from "../../../api/adminRoutes";

// MUI
import { Button, Grid } from "@mui/material";
import OrganizationForm from "../forms/organizationForm";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { useRecoilState, useRecoilValue } from "recoil";
import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";

function AdminOrganizationView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const org = useRecoilValue(selectedDocByIdState("organization"));

  const handleSubmit = (accessToken, updatedOrg, id) => {
    const updateOrgData = async (accessToken, updatedOrg, id) => {
      await updateOrganization(id, updatedOrg, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Organization" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateOrgData(accessToken, updatedOrg, id);
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/allorganizations"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <OrganizationForm
            action="edit"
            org={org}
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminOrganizationView;
