import React, { useEffect, useState } from "react";
import { muiFi } from "../constants/muiTranslations";
import { useLocation } from "react-router-dom";
import i18next from "i18next";

// Components
import { GetDogColumns } from "./datagridColumns/DogColumns";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography, Grid, OutlinedInput } from "@mui/material";

// API
import { getSearchResults } from "../api/publicRoutes";

function Search() {
  const query = new URLSearchParams(useLocation().search);
  const [state, setState] = useState({
    hakusanat: query.get("hakusanat") || query || "",
    dogs: [],
    tyhjäHaku:
      new URL(window.location.href).searchParams.get("hakusanat") !== null,
    opts: { sort: { createdAt: -1 } },
  });

  useEffect(() => {
    const { hakusanat, opts } = state;

    const joined = hakusanat ? `"${hakusanat?.split?.(" ").join('" "')}"` : "";
    getSearchResults(joined, opts).then((res) => {
      setState({ ...state, dogs: res });
    });
  }, []);

  const handleChange = (e) => {
    setState({ ...state, hakusanat: e.target.value });
  };

  const handleSubmit = (event) => {
    window.location = `/haku/?hakusanat=${hakusanat}`;
  };

  const handleRowClick = (params) => {
    window.location = `/dog/${params.getValue(params.id, "_id")}`;
  };
  const { dogs = [], hakusanat } = state;
  return (
    <>
      <Grid container direction="column" spacing={{ xs: 1, sm: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h5">{i18next.t("search")}</Typography>
          <OutlinedInput
            fullWidth
            type="text"
            name="hakusanat"
            value={hakusanat}
            onChange={handleChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
            id="hakukenttä"
            placeholder={i18next.t("searchPlaceholder")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{i18next.t("searchResults")}</Typography>{" "}
          {dogs && (
            <DataGrid
              autoHeight
              pageSize={50}
              getRowId={(row) => row._id}
              onRowClick={handleRowClick}
              rows={dogs}
              columns={GetDogColumns()}
              localeText={i18next.language === "fi" ? muiFi : null}
              components={{ Toolbar: GridToolbar }}
              disableSelectionOnClick
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Search;
