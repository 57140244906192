import React from "react";
import { useTranslation } from "react-i18next";

import { Paper, Typography, Grid, Button, Stack } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { dbStatsState } from "../../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function InfoBox() {
  const { t } = useTranslation();
  const stats = useRecoilValue(dbStatsState);

  return (
    <Paper sx={{ mb: 5, p: 3 }}>
      <Grid
        container
        sx={{ mb: 6 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
          <Typography variant="h1">{t("homeHeader")}</Typography>
          <Typography variant="body2">{t("homeText")}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div
            style={{
              textAlign: "right",
              marginTop: "10px",
              marginRight: "20px",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<ChevronRight />}
                href="/dog-registration"
              >
                {t("registerDog")}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<ChevronRight />}
                href="/litter-registration"
              >
                {t("registerLitter")}
              </Button>
            </Stack>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("dogsRegistered")}</Typography>
          <Typography variant="h4">{stats.dog}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("littersRegistered")}</Typography>
          <Typography variant="h4">{stats.litter}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("healthTestResults")}</Typography>
          <Typography variant="h4">{stats.healthtest}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("geneticTestResults")}</Typography>
          <Typography variant="h4">{stats.geneticstest}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("competitionResults")}</Typography>
          <Typography variant="h4">{stats.competitionresult}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
