import React from "react";

// Routes
import { updateCertificate } from "../../../api/adminRoutes";

// MUI
import { Button, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import CertificateForm from "../forms/certificateForm";

import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

function AdminCertificateView() {
  const { t } = useTranslation();
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const cert = useRecoilValue(selectedDocByIdState("certificate"));

  const handleSubmit = (accessToken, updatedCert, id) => {
    const updateCertData = async (accessToken, updatedCert, id) => {
      await updateCertificate(id, updatedCert, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Certificate" }),
            severity: "success",
            open: true,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      });
    };
    updateCertData(accessToken, updatedCert, id);
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/allcertificates"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <CertificateForm
            action="edit"
            cert={cert}
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminCertificateView;
