import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
  Box,
  Stack,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { deleteCompetitionResult } from "../../../api/adminRoutes";

import DogAutocomplete from "../../../components/formComponents/dogAutocomplete";
import { citiesFi } from "../../../constants/fiCities";

import {
  accessTokenState,
  showSubmitState,
  snackBarState,
} from "../../../recoil/globalStates";
import {
  adminAllDogsState,
  adminDogByIdState,
} from "../../../recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";

function CompetitionResultForm({
  action,
  result,
  emitHandleSubmit,
  handleModalClose,
}) {
  const accessToken = useRecoilValue(accessTokenState);
  const [showSubmit, setShowSubmit] = useRecoilState(showSubmitState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const [initialResult, setInitialResult] = useState(result);
  const [updatedResult, setUpdatedResult] = useState(false);

  const dogs = useRecoilValue(adminAllDogsState);
  const [selectedDog, setSelectedDog] = useState(
    useRecoilValue(adminDogByIdState(result?.parentDoc))
  );

  const handleChange = (event) => {
    setUpdatedResult({
      ...updatedResult,
      [event.target.name]: event.target.value,
    });
    setInitialResult({
      ...initialResult,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
  };

  const handleCityChange = (event, newValue) => {
    setUpdatedResult({
      ...updatedResult,
      city: newValue,
    });
    setInitialResult({
      ...initialResult,
      city: newValue,
    });
    setShowSubmit(true);
  };

  const handleDateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setInitialResult({
        ...initialResult,
        date: dayjs(newValue).toISOString(),
      });
      setUpdatedResult({
        ...updatedResult,
        date: dayjs(newValue).toISOString(),
      });
      setShowSubmit(true);
    }
  };

  const onChangeDog = (e, newValue) => {
    setSelectedDog(newValue);
    setUpdatedResult({
      ...updatedResult,
      parentDoc: newValue._id,
    });
  };

  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedResult, initialResult._id);
    setShowSubmit(false);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this result? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteCompetitionResult(initialResult._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Result has been removed.`);
              setTimeout(
                (window.location = `/admin/allcompetitionresults`),
                3000
              );
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} COMPETITION RESULT{" "}
              {action === "edit" && initialResult?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        {action === "edit" && (
          <Grid item xs={12}>
            <Stack direction="column" alignItems="flex-start" sx={{ pb: 2 }}>
              <Typography variant="caption">
                Created{" "}
                {initialResult?.createdAt &&
                  dayjs(initialResult?.createdAt).format("DD.MM.YYYY")}
              </Typography>

              <Typography variant="caption">
                Updated{" "}
                {initialResult?.updatedAt &&
                  dayjs(initialResult?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <DatePicker
            label="Competition date"
            name="date"
            value={initialResult?.date || null}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={6}>
          <DogAutocomplete
            label="Dog"
            name="dog"
            handleChange={onChangeDog}
            options={dogs}
            value={selectedDog}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Sport"
            name="sport"
            placeholder="Sport"
            value={initialResult?.sport || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Class"
            name="class"
            placeholder="Class"
            value={initialResult?.class || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Result"
            name="result"
            placeholder="Result"
            value={initialResult?.result || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            freeSolo
            label="City"
            name="city"
            placeholder="City"
            onChange={handleCityChange}
            options={citiesFi}
            getOptionLabel={(option) => option}
            value={initialResult?.city || ""}
            renderInput={(params) => <TextField {...params} label="City" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Judge"
            name="judge"
            placeholder="Judge"
            value={initialResult?.judge || ""}
            onChange={handleChange}
            helperText="Give this data only if judge has given permission for name publishing"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Additional information"
            name="additionalInfo"
            placeholder="Additional information"
            value={initialResult?.additionalInfo || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedResult
              ? JSON.stringify(updatedResult, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <SaveIcon fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default CompetitionResultForm;
