import React from "react";
import { useTranslation } from "react-i18next";

import { getKruValueLabel } from "../../constants/helpers";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Launch } from "@mui/icons-material";

export default function PuppiesList({ puppies, onDeletePuppy, formType }) {
  const { t } = useTranslation();

  const dogDocType = formType === "litter" ? "dog" : "tempdog";
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Link</TableCell>
          <TableCell>Microchip no</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Sex</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Coat</TableCell>
          <TableCell>_id</TableCell>
          <TableCell>Remove</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {puppies.length > 0 ? (
          puppies.map((puppy, i) => (
            <TableRow key={i}>
              <TableCell>
                <IconButton
                  href={`/admin/${dogDocType}/${puppy._id}`}
                  target="_blank"
                >
                  <Launch />
                </IconButton>
              </TableCell>
              <TableCell>{puppy.microchipNumber}</TableCell>
              <TableCell>{puppy.name}</TableCell>
              <TableCell>{t(puppy.sex)}</TableCell>
              <TableCell>
                {/*  {puppy?.color?.map((value, i) => {
                  return (
                    getKruValueLabel(value, "en") +
                    (puppy.color.length - 1 === i ? "" : ", ")
                  );
                }) || error} */}
              </TableCell>
              <TableCell>
                {/*  {puppy?.coat?.map((value, i) => {
                  return (
                    getKruValueLabel(value, "en") +
                    (puppy.coat.length - 1 === i ? "" : ", ")
                  );
                }) || error} */}
              </TableCell>
              <TableCell>{puppy._id}</TableCell>
              <TableCell>
                <IconButton onClick={() => onDeletePuppy(i)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={7}>
              No puppies
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
