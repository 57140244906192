import React from "react";
import i18next from "i18next";
import { litterRegRules, dogRegRules } from "./rules";
import { Checkmark, Errormark } from "../elements/BreederBadges";
import dayjs from "dayjs";
import { countries } from "./countries";
import { getOrganizationById } from "../api/publicRoutes";
import { useRecoilValue } from "recoil";
import { kruValuesState } from "../recoil/globalStates";

// Badges
// TODO: this should be in components
export const getBadge = (breeder) => {
  if (breeder) {
    if (breeder?.certificates?.length > 0) {
      return <Checkmark />;
    } else {
      return <Errormark />;
    }
  } else {
    return " ";
  }
};

// takes in breeder object and returns formatted string with breeders public info
export const getBreederInfo = (breederData) => {
  // Nina doesnt remember why it was done this way instead of taking these values straight from an object
  // But it had something to do with very spesific way this string needs to be shown in the UI

  let breeder = {
    name: "",
    city: "",
    kennelname: "",
  };

  if (breederData) {
    if (breederData.name) {
      breeder.name = breederData.name;
    } else if (breederData.breederId) {
      breeder.name = "ID: " + breederData.breederId;
    } else {
      breeder.name = "";
    }
    if (breederData.city) {
      breeder.city = ", " + breederData.city;
    } else {
      breeder.city = "";
    }
    if (breederData.breedingPrefix) {
      breeder.kennelname = breederData.breedingPrefix + " - ";
    } else {
      breeder.kennelname = i18next.t("noBreederPrefix") + " - ";
    }
    return breeder.kennelname + breeder.name + breeder.city;
  } else {
    return i18next.t("notDisclosed");
  }
};

// takes in array of dog objects and a boolean, sorts then according to the given
// boolean and return the sorted array
export function sortDogsByCreationDate(dogs, sortingAsc) {
  const sortedOwnedDogs = [...dogs];

  sortedOwnedDogs.sort(function compare(a, b) {
    var dateA = dayjs(a.createdAt);
    var dateB = dayjs(b.createdAt);
    return sortingAsc ? dateB - dateA : dateA - dateB;
  });
  return sortedOwnedDogs;
}

// Get KruValue's label in selected language by id
export function getKruValueLabel(id, lang) {
  const kruValues = useRecoilValue(kruValuesState);
  if (id !== "") {
    const found = kruValues.find((value) => value._id === id);
    return found && found[lang].name;
  } else {
    return "";
  }
}

// Check if parent is not too young
export function checkAge(parent) {
  const dateParent = dayjs(parent.dateOfBirth);
  const dateToday = dayjs();
  const parentAge = dateToday.diff(dateParent, "month");

  if (parentAge < litterRegRules.parentAgeMonthsMin) {
    return { ok: false, age: parentAge };
  } else {
    return { ok: true, age: null };
  }
}

// Check if dog is not too young for registration
export function checkDogAge(dob) {
  const dateDog = dayjs(dob);
  const dateToday = dayjs();
  const dogAge = dateToday.diff(dateDog, "month");

  if (dogAge < dogRegRules.ageMonthsMin) {
    return { ok: false, age: dogAge };
  } else {
    return { ok: true, age: null };
  }
}

// Count the number of each gender to show in form UI
export function countFemales(puppies) {
  if (puppies) {
    const count = puppies.filter((pup) => pup.sex == "female");

    return count.length;
  }
}
// Count the number of each gender to show in form UI
export function countMales(puppies) {
  if (puppies) {
    const count = puppies.filter((pup) => pup.sex == "male");

    return count.length;
  }
}

export function getVerification(verifications, operation) {
  const found = verifications.find((e) => e.operation === operation);
  if (found) {
    return found.verified;
  }
}

export function getCountryByCode(code, lang) {
  const found = countries.find((c) => c.code === code);
  if (!found) {
    return code;
  } else {
    return found[lang];
  }
}

export async function getOrganizationNamesById(orgArray) {
  let promiseArray = [];
  orgArray.forEach((org) => promiseArray.push(getOrganizationById(org)));
  Promise.all(promiseArray).then((vals) => {
    const mappedValues = vals.map((v) => v.name);
    return mappedValues;
  });
}
