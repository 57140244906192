import * as React from "react";
import i18next from "i18next";

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Divider,
} from "@mui/material";

function getPuppiesTotal(litters) {
  let puppiesTotal = 0;
  litters.forEach((litter) => {
    puppiesTotal += litter.puppies?.length;
  });
  return puppiesTotal;
}

export default function BreederStatistics({ litters }) {
  const littersTotal = litters.length;
  const puppiesTotal = getPuppiesTotal(litters);

  // TODO:
  // - first litter year
  // - last litter year
  // - litters approx in a year

  return (
    litters && (
      <>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h3">{i18next.t("statistics")}</Typography>
        </Divider>
        <TableContainer component={Paper}>
          <Table
            sx={{
              tableLayout: { xs: "fixed" },
              overflowWrap: { xs: "break-word" },
            }}
          >
            <TableBody>
              <TableRow hover>
                <TableCell variant="head">
                  {i18next.t("littersTotal")}
                </TableCell>
                <TableCell>{littersTotal}</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell variant="head">
                  {i18next.t("puppiesTotal")}
                </TableCell>
                <TableCell>{puppiesTotal}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  );
}
