import React from "react";

// Routes
import { updateTempLitter } from "../../../api/adminRoutes";

// Components
import LitterForm from "../forms/litterForm";

// MUI
import { Button, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { snackBarState } from "../../../recoil/globalStates";
import { selectedDocByIdState } from "../../../recoil/adminStates";

function AdminTempLitterView() {
  const { t } = useTranslation();
  const setSnackbarState = useSetRecoilState(snackBarState);

  const litter = useRecoilValue(selectedDocByIdState("tempLitter"));

  const handleSubmit = (accessToken, newLitter, id) => {
    if (newLitter.puppies) {
      newLitter.puppies = newLitter.puppies.map((pup) => pup._id);
    }

    updateTempLitter(id, newLitter, {
      accessToken,
      options: { new: true },
    }).then((res) => {
      if (res.error) {
        setSnackbarState({
          message: res.message,
          severity: "error",
          open: true,
        });
      } else {
        setSnackbarState({
          message: t("updateSuccess", { docType: "TempLitter" }),
          severity: "success",
          open: true,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    });
  };

  return (
    <>
      <Button
        size="small"
        color="gray"
        href="/admin/alltemplitters"
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <LitterForm
            litter={litter}
            formType="tempLitter"
            action="edit"
            emitHandleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminTempLitterView;
