import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getBadge } from "../../constants/helpers";

import {
  Typography,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getOrganizationById } from "../../api/publicRoutes";
import { Business } from "@mui/icons-material";

export default function OrganizationsData({ userPerson }) {
  const { t } = useTranslation();
  const [certs, setCerts] = useState();

  useEffect(() => {
    let promiseArray = [];
    userPerson.organizations.forEach((c) => {
      promiseArray.push(getOrganizationById(c));
    });
    Promise.all(promiseArray).then((vals) => {
      setCerts(vals);
    });
  }, [userPerson]);

  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <List>
          <ListItem>
            <ListItemText>
              <Typography
                variant="h3"
                sx={{ display: "flex", alignItems: "flex-start" }}
              >
                <Business sx={{ mr: 1 }} /> {t("organizations")}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          {certs && certs.length > 0 ? (
            certs.map((cert, i) => (
              <ListItem key={i}>
                <ListItemText>{cert?.name}</ListItemText>
              </ListItem>
            ))
          ) : (
            <Typography variant="body" sx={{ p: 2 }}>
              {t("noOrganizations")}
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
}
