import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

// Components
import DogView from "../components/usersOwnedDogsComponents/dogView";
import BredDogsList from "../components/usersOwnedDogsComponents/bredDogsList";

// MUI
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Alert,
  Box,
  Button,
} from "@mui/material";
import LitterDrawerMenu from "../components/usersBredDogsComponents/litterDrawerMenu";
import { ChevronRight } from "@mui/icons-material";

import { useRecoilValue } from "recoil";
import { usersBredLittersState } from "../recoil/privateStates";

function BredDogs() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const [sortingAsc, setSortingAsc] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const bredDogs = useRecoilValue(usersBredLittersState);
  const [selectedDog, setSelectedDog] = useState(false);

  // if dog is selected in child component, do this
  const handleSelection = (dog) => {
    setSelectedDog(dog);
    setDrawerOpen(false);
  };

  // TODO: this is identical with a func in mydogs, should be put to separate file
  /*   const handleSort = () => {
    let sortedDogs = [];
    if (sortingAsc) {
      setSortingAsc(false);
      sortedDogs = sortDogsByCreationDate(bredDogs, false);
    } else {
      setSortingAsc(true);
      sortedDogs = sortDogsByCreationDate(bredDogs, true);
    }
    setBredDogs(sortedDogs);
  }; */

  return (
    <>
      {isAuthenticated && (
        <>
          <Box display={{ xs: "flex", sm: "flex", md: "none" }} sx={{ mb: 4 }}>
            <LitterDrawerMenu
              litters={bredDogs}
              handleSelection={handleSelection}
              /*               sortingAsc={sortingAsc}
              handleSort={handleSort} */
              open={drawerOpen}
              setOpen={setDrawerOpen}
            />
          </Box>
          <Box display={{ xs: "none", sm: "none", md: "block" }}>
            <Divider textAlign="left" sx={{ mb: 4 }}>
              <Typography variant="h2">{t("myLitters")}</Typography>
            </Divider>
          </Box>

          {bredDogs && bredDogs.length ? (
            <Paper>
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  {/*  <Button
                    endIcon={sortingAsc ? <UpIcon /> : <DownIcon />}
                    onClick={handleSort}
                  >
                    {sortingAsc ? t("sortAscending") : t("sortDescending")}
                  </Button> */}
                  <BredDogsList
                    litters={bredDogs}
                    handleSelection={handleSelection}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  {selectedDog ? (
                    <DogView selectedDog={selectedDog} />
                  ) : (
                    <Typography
                      variant="body"
                      align="center"
                      sx={{ p: 2, display: "block" }}
                    >
                      {t("selectALitter")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
          ) : bredDogs?.length === 0 ? (
            <Paper sx={{ p: 2 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  variant="body"
                  align="center"
                  sx={{ p: 2, display: "block" }}
                >
                  {t("noLitters")}
                </Typography>
                <Button
                  variant="text"
                  endIcon={<ChevronRight />}
                  href="/litter-registration"
                >
                  {t("registerYourFirstLitter")}
                </Button>
              </Box>
            </Paper>
          ) : (
            <>
              <Typography
                variant="body"
                align="center"
                sx={{ p: 2, display: "block" }}
              >
                {t("loading")}
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
}

export default BredDogs;
