import React from "react";
import { styled } from "@mui/material/styles";

//MUI
import { MenuItem, Link } from "@mui/material";

const StyledMenuItemComp = styled(MenuItem)(({ theme }) => ({
  padding: "10px 20px 10px 20px",
}));

export default function StyledMenuItem({ divider, href, children }) {
  return (
    <StyledMenuItemComp divider={divider} component={Link} href={href}>
      {children}
    </StyledMenuItemComp>
  );
}
