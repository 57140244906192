import React from "react";
import { useTranslation } from "react-i18next";

import { muiFi } from "../../constants/muiTranslations";
import { breederColumns } from "../../components/datagridColumns/BreederColumns";

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Recoil
import { allBreedersState } from "../../recoil/publicStates";
import { useRecoilValue } from "recoil";

export default function BreederList() {
  const { i18n } = useTranslation();

  const breeders = useRecoilValue(allBreedersState);

  const handleRowClick = (params) => {
    window.location = `/breeder/${params.row._id}`;
  };

  return (
    <DataGrid
      autoHeight
      getRowId={(row) => row._id}
      onRowClick={handleRowClick}
      rows={breeders}
      columns={breederColumns}
      localeText={i18n.language === "fi" ? muiFi : null}
      components={{ Toolbar: GridToolbar }}
    />
  );
}
