import React from "react";

import { Paper, Dialog } from "@mui/material";

import { kuvaServer } from "../../constants/values";
import defaultImgUrl from "../../images/placeholder.png";

function PhotoDialog(props) {
  const { onClose, url, open, dogName } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Paper
        component="img"
        src={url}
        alt={`Koiran ${dogName} rakennekuva`}
        sx={{ width: "100%" }}
      />
    </Dialog>
  );
}

export default function PhotoModal({ dog }) {
  const [open, setOpen] = React.useState(false);

  const imgUrl = dog?.hasPhoto ? kuvaServer + dog._id + ".jpg" : defaultImgUrl;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper
        component="img"
        src={imgUrl}
        alt={`Koiran ${dog?.name} rakennekuva`}
        sx={{ width: "100%", height: "auto", display: "flex" }}
        onClick={handleClickOpen}
      />
      <PhotoDialog
        url={imgUrl}
        dogName={dog?.name}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
