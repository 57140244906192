import { selector, selectorFamily } from "recoil";
import {
  getTempDogById,
  getUsersLitters,
  getUsersOwnedDogs,
  getUsersTempDogs,
  getUsersTempLitters,
  getVerification,
} from "../api/privateRoutes";
import { accessTokenState } from "./globalStates";

export const usersOwnedDogsState = selector({
  key: "usersOwnedDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersOwnedDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const usersBredLittersState = selector({
  key: "usersBredLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const usersTempLittersState = selector({
  key: "usersTempLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersTempLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const usersTempDogsState = selector({
  key: "usersTempDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersTempDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});
export const tempDogByIdState = selectorFamily({
  key: "tempDogByIdState",
  get:
    (id) =>
      async ({ get }) => {
        const accessToken = get(accessTokenState);
        const response = await getTempDogById(id, { accessToken });
        if (response.error) {
          throw response;
        }
        return response;
      },
});

export const verificationByIdState = selectorFamily({
  key: "verificationByIdState",
  get:
    (_id) =>
      async ({ get }) => {
        const accessToken = get(accessTokenState);

        const response = await getVerification(_id, { accessToken });
        if (response.error) {
          throw response;
        }
        return response;
      },
});
