import * as React from "react";
import i18next from "i18next";

import KoiraCard from "../../elements/DogCard";

import { Typography, Divider } from "@mui/material";

export default function ParentsComp({ sire, dam }) {
  return (
    <>
      <Divider textAlign="left" sx={{ mb: 2 }}>
        <Typography variant="h3">{i18next.t("sire")}</Typography>
      </Divider>
      <KoiraCard dog={sire} />
      <Divider textAlign="left" sx={{ mb: 2 }}>
        <Typography variant="h3">{i18next.t("dam")}</Typography>
      </Divider>
      <KoiraCard dog={dam} />
    </>
  );
}
