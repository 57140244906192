import React from "react";

// Components
import TempLittersWidget from "./dashboardWidgets/tempLitters";
import TempDogsWidget from "./dashboardWidgets/tempDogs";

// MUI
import { Grid, Divider, Typography } from "@mui/material";

function AdminDashboard() {
  return (
    <Grid
      container
      direction={{ xs: "column", sm: "row" }}
      spacing={1}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h2">Dashboard</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <TempLittersWidget />
        <TempDogsWidget />
      </Grid>
    </Grid>
  );
}

export default AdminDashboard;
