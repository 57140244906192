import React, { Fragment, Suspense } from "react";

import { Loader } from "../../elements/Loader";
import KoiraCard from "../../elements/DogCard";

// Recoil
import { useRecoilValue } from "recoil";
import { newestDogsState } from "../../recoil/publicStates";

export default function NewestDogs() {
  const dogs = useRecoilValue(newestDogsState);

  return (
    <Suspense fallback={<Loader />}>
      {dogs.map((dog) => (
        <Fragment key={dog._id}>
          <KoiraCard dog={dog} showAddedDate={true} />
        </Fragment>
      ))}
    </Suspense>
  );
}
