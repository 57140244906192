import React, { useState } from "react";
import dayjs from "dayjs";

import { registries } from "../../../constants/registries";
import { countries } from "../../../constants/countries";

// Routes
import {
  acceptTempDog,
  deleteDog,
  deleteTempDog,
} from "../../../api/adminRoutes";

// Components
import BreedTestComponent from "./breedtestForm";
import FormModal from "../../../components/adminDashboardComponents/formModal";
import ParentAutocomplete from "../../../components/formComponents/parentAutocomplete";
import CountryAutocomplete from "../../../components/formComponents/countryAutocomplete";

// MUI
import {
  Grid,
  TextField,
  FormControl,
  Divider,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  Box,
  InputLabel,
  Checkbox,
  ListItem,
  List,
  ListItemText,
  Button,
  InputAdornment,
  ListSubheader,
  Stack,
  IconButton,
  FormControlLabel,
  ListItemButton,
  ListItemIcon,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Delete, DeleteForever, Add, Save, Launch } from "@mui/icons-material";
import CertificatesList from "../../../components/formComponents/certificatesList";
import { useTranslation } from "react-i18next";
import AttachmentList from "../../../components/formComponents/attachmentList";
import { constSelector, useRecoilValue } from "recoil";
import { accessTokenState, kruValuesState } from "../../../recoil/globalStates";
import {
  adminAllCertsState,
  adminAllOrgsState,
  adminAllUsersState,
  adminDogsByGenderState,
  adminGetBreederByIdState,
  adminGetOwnersByIdState,
} from "../../../recoil/adminStates";

// This form is also used for tempDogs
function DogForm({
  dog,
  formType,
  action,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { i18n } = useTranslation();
  const [showSubmit, setShowSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const accessToken = useRecoilValue(accessTokenState);
  const kruValues = useRecoilValue(kruValuesState);
  const users = useRecoilValue(adminAllUsersState);

  const [initialDog, setInitialDog] = useState(action === "edit" ? dog : null);
  const [updatedDog, setUpdatedDog] = useState(null);

  const [selectedBreeder, setSelectedBreeder] = useState(
    useRecoilValue(
      dog?.breeder ? adminGetBreederByIdState(dog.breeder) : constSelector(null)
    )
  );

  const [owners, setOwners] = useState(
    useRecoilValue(
      dog?.owners ? adminGetOwnersByIdState(dog.owners) : constSelector([])
    )
  );

  const femaleDogs = useRecoilValue(adminDogsByGenderState("female"));
  const maleDogs = useRecoilValue(adminDogsByGenderState("male"));

  const organizations = useRecoilValue(adminAllOrgsState);
  const [dogsOrgs, setDogsOrgs] = useState(dog?.organizations || []);

  const [otherReg, setOtherReg] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    dog?.countryOfOrigin
      ? countries.find((c) => c.code === dog.countryOfOrigin.toUpperCase())
      : countries.find((c) => c.code === i18n.language.toUpperCase())
  );
  const [selectedSire, setSelectedSire] = useState(dog?.sire || null);
  const [selectedDam, setSelectedDam] = useState(dog?.dam || null);

  const certificates = useRecoilValue(adminAllCertsState);
  const [newCert, setNewCert] = useState({
    certificate: "",
    issuingDate: "",
  });

  // most of the fields that do not require special treatment are handled here
  // initial dog is the "original" full dog document, while updatedDog contains only the
  // updated data we want to send to patch api endpoint
  const handleChange = (event) => {
    setInitialDog({
      ...initialDog,
      [event.target.name]: event.target.value,
    });
    setUpdatedDog({
      ...updatedDog,
      [event.target.name]: event.target.value,
    });
    setShowSubmit(true);
  };

  const handleAutocompleteChange = (event, newValue, name) => {
    if (name === "breeder") {
      setSelectedBreeder(newValue);
      if (newValue) {
        setUpdatedDog({
          ...updatedDog,
          breeder: newValue._id,
        });
      }
      setShowSubmit(true);
    } else if (name === "countryOfOrigin") {
      setSelectedCountry(newValue);
      if (newValue) {
        setUpdatedDog({
          ...updatedDog,
          countryOfOrigin: newValue.code,
        });
      }
      setShowSubmit(true);
    } else if (name === "sire") {
      setSelectedSire(newValue);
      if (newValue) {
        setUpdatedDog({
          ...updatedDog,
          sire: newValue._id,
        });
      }
      setShowSubmit(true);
    } else if (name === "dam") {
      setSelectedDam(newValue);
      if (newValue) {
        setUpdatedDog({
          ...updatedDog,
          dam: newValue._id,
        });
      }
      setShowSubmit(true);
    }
  };

  // handle datepicker separately, since it needs to be transformed to ISO string
  // and also they do not have name prop, so their object key needs to be assigned manually
  const handleDobChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setInitialDog({
        ...initialDog,
        dateOfBirth: dayjs(newValue).toISOString(),
      });
      setUpdatedDog({
        ...updatedDog,
        dateOfBirth: dayjs(newValue).toISOString(),
      });
      setShowSubmit(true);
    }
  };

  const handleOwnerChange = (event, newValue) => {
    const ownerIds = newValue.map((owner) => owner._id);
    setOwners(newValue);
    setUpdatedDog({
      ...updatedDog,
      owners: ownerIds,
    });
    setShowSubmit(true);
  };

  const handleOrgChange = (event, newValue) => {
    const orgIds = newValue.map((org) => org._id);
    setDogsOrgs(newValue);
    setUpdatedDog({
      ...updatedDog,
      organizations: orgIds,
    });
    setShowSubmit(true);
  };

  // handle datepicker separately, since it needs to be transformed to ISO string
  // and also they do not have name prop, so their object key needs to be assigned manually
  const handleDeathdateChange = (newValue) => {
    if (dayjs(newValue).isValid()) {
      setInitialDog({
        ...initialDog,
        dateOfDeath: dayjs(newValue).toISOString(),
      });
      setUpdatedDog({
        ...updatedDog,
        dateOfDeath: dayjs(newValue).toISOString(),
      });
      setShowSubmit(true);
    }
  };

  // checkbox boolean handling for deceased dog
  const handleDeceasedCheckBox = (e) => {
    setUpdatedDog({
      ...updatedDog,
      isDeceased: e.target.checked,
    });
    setInitialDog({
      ...initialDog,
      isDeceased: e.target.checked,
    });
    setShowSubmit(true);
  };

  // Handle change of "regOther" fields, we are also checking if e.target.name exists -> if it does not
  // we assume that the field is autocomplete, which does not have name prop and we need to set it to state
  // manually
  const handleOtherReg = (event, newValue) => {
    if (!event.target.name) {
      setOtherReg({
        ...otherReg,
        rekisteri: newValue,
      });
    } else {
      setOtherReg({
        ...otherReg,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Handle add of "regOther" fields - join the fields and set in state
  const handleOtherRegAdd = () => {
    if (otherReg?.regNumber && otherReg?.rotu && otherReg?.rekisteri) {
      const regString =
        otherReg.regNumber + ", " + otherReg.rotu + ", " + otherReg.rekisteri;

      setUpdatedDog({
        ...updatedDog,
        regOther: [...initialDog?.regOther, regString],
      });
      setShowSubmit(true);
    }
  };

  // Handle delete of "regOther" row - splice and set state with sliced array
  const handleOtherRegDelete = (i) => {
    const regOtherArray = initialDog.regOther;
    regOtherArray.splice(i, 1);
    setInitialDog({
      ...initialDog,
      regOther: regOtherArray,
    });
    setUpdatedDog({
      ...updatedDog,
      regOther: regOtherArray,
    });
    setShowSubmit(true);
  };

  // Open breed test modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  // Close breed test modal
  const handleBreedtestModalClose = () => {
    setModalOpen(false);
  };

  // Handle form submit, call the parent components handleSubmit function that send the document to API patch
  const handleSubmit = () => {
    emitHandleSubmit(accessToken, updatedDog, initialDog._id);
    setShowSubmit(false);
  };

  // Handle deletion of a dog/tempdog, prompt for confirm before the api call is done,
  // after removal, redirect user to listing
  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete ${initialDog.name} ? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteDog(initialDog._id, { accessToken }).then((res) => {
          if (res.error) {
            throw res;
          } else {
            alert(`${res.name} has been removed.`);
            window.location = `/admin/alldogs`;
          }
        });
      };
      const deleteTempData = async () => {
        await deleteTempDog(initialDog._id, { accessToken }).then((res) => {
          if (res.error) {
            throw res;
          } else {
            alert(`${res.name} has been removed.`);
            window.location = `/admin/alltempdogs`;
          }
        });
      };
      if (formType === "dog") {
        deleteData();
      } else if (formType === "tempdog") {
        deleteTempData();
      } else {
        throw { error: true, message: "Error in DogForm Formtype" };
      }
    } else {
      alert(`Cancelled deletion`);
    }
  };

  const handleAccept = () => {
    if (
      confirm(
        "Are you sure you want to accept this dog? Dog is going to be published in the public database."
      )
    ) {
      acceptTempDog(initialDog._id, { accessToken }).then((res) => {
        if (res.error) {
          throw res;
        } else {
          alert("Dog has been accepted and transferred to public database.");
          window.location.replace(`/admin/all${formType}s`);
        }
      });
    } else {
      alert(`Cancelled accepting dog`);
    }
  };

  // Handle value changes in newCert object
  const handleNewCertChange = (e) => {
    setNewCert({ ...newCert, [e.target.name]: e.target.value });
  };

  const onAddNewCert = () => {
    let initCerts = initialDog.certificates || [];
    initCerts.push(newCert);

    setInitialDog({
      ...initialDog,
      certificates: initCerts,
    });

    // mapping just the cert id for updating certificates
    const certsWithIds = initCerts.map((c) => ({
      certificate: c.certificate._id,
      issuingDate: c.issuingDate,
    }));

    setUpdatedDog({
      ...updatedDog,
      certificates: certsWithIds,
    });

    setNewCert({
      certificate: "",
      issuingDate: "",
    });
    setShowSubmit(true);
  };

  // Handle deleting a cert object from dog.certificates
  const onDeleteCert = (i) => {
    if (confirm("Are you sure you want to delete certificate?")) {
      const certs = initialDog.certificates;
      certs.splice(i, 1);
      setInitialDog({ ...initialDog, certificates: certs });
      const certsWithIds = certs.map((c) => ({
        certificate: c.certificate._id,
        issuingDate: c.issuingDate,
      }));
      setUpdatedDog({ ...updatedDog, certificates: certsWithIds });
      setShowSubmit(true);
    }
  };

  return (
    <>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {action.toUpperCase()} {formType.toUpperCase()} {initialDog?._id}
            </Typography>
            {action === "add" && (
              <Button onClick={handleModalClose}>Close</Button>
            )}
          </Stack>
        </Grid>
        {action === "edit" && (
          <>
            <Grid item xs={7}>
              <Typography variant="caption">
                Created on{" "}
                {initialDog?.createdAt &&
                  dayjs(initialDog?.createdAt).format("DD.MM.YYYY")}
              </Typography>
              <br />
              <Typography variant="caption">
                Updated on{" "}
                {initialDog?.updatedAt &&
                  dayjs(initialDog?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                {formType === "tempdog" && (
                  <>
                    <Typography variant="body">
                      <b>Registration status</b>
                      <br /> {initialDog?.registrationStatus || "-"}
                    </Typography>
                    <Button variant="contained" onClick={handleAccept}>
                      Accept registration
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="right" sx={{ mb: 2 }}>
                <Typography variant="h5">
                  {initialDog?.regNumber || "No reg. no"}
                </Typography>
              </Divider>
            </Grid>{" "}
          </>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Dog's name"
            name="name"
            placeholder="Dog's name"
            value={initialDog?.name || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Microchip number"
            name="microchipNumber"
            placeholder="Microchip number"
            value={initialDog?.microchipNumber || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Date of Birth"
            name="dateOfBirth"
            value={initialDog?.dateOfBirth || null}
            onChange={handleDobChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Gender</InputLabel>
            <Select
              fullWidth
              name="sex"
              value={initialDog?.sex || ""}
              label="Gender"
              onChange={handleChange}
            >
              <MenuItem value="female">female</MenuItem>
              <MenuItem value="male">male</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <CountryAutocomplete
            label="Country of origin"
            name="countryOfOrigin"
            handleChange={handleAutocompleteChange}
            options={countries}
            value={selectedCountry}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  variant="contained"
                  color="primary"
                  checked={initialDog?.isDeceased || false}
                  onClick={handleDeceasedCheckBox}
                />
              }
              label="Mark as deceased"
            />
          </FormControl>

          {initialDog?.isDeceased && (
            <>
              <DatePicker
                label="Date of death"
                name="dateOfDeath"
                value={initialDog?.dateOfDeath || null}
                onChange={handleDeathdateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label="Cause of death"
                name="causeOfDeath"
                placeholder="Cause of death"
                value={initialDog?.causeOfDeath || ""}
                onChange={handleChange}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Registration</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            multiple
            label="Organizations"
            name="organizations"
            placeholder="Organizations"
            options={organizations}
            isOptionEqualToValue={(option, value) => option._id == value._id}
            getOptionLabel={(option) => (option ? option.name : "")}
            value={dogsOrgs}
            onChange={handleOrgChange}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Organizations"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Registration type</InputLabel>
            <Select
              fullWidth
              label="Registration type"
              name="regType"
              placeholder="Registration type"
              onChange={handleChange}
              value={initialDog?.regType || ""}
            >
              {kruValues
                .filter((i) => i.category === "reg.types")
                .map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.en.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {initialDog?.regType === "reg.types.litter" && (
          <Grid item xs={6}>
            <List>
              <ListItem>
                <Typography variant="h5">Part of Litter</Typography>
              </ListItem>
              <ListItem>{initialDog?.litter || "No litter found"}</ListItem>
            </List>
          </Grid>
        )}
        {action === "edit" && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Other registrations</Typography>
            </Grid>
            <Grid item xs={6}>
              <List>
                {initialDog.regOther?.length > 0
                  ? initialDog.regOther?.map((rek, i) => {
                    return (
                      <ListItem
                        key={i}
                        secondaryAction={
                          <IconButton onClick={() => handleOtherRegDelete(i)}>
                            <Delete />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={<>{rek}</>} />
                      </ListItem>
                    );
                  })
                  : "No other registrations"}
              </List>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="regNumber"
                name="regNumber"
                placeholder="regNumber"
                onChange={handleOtherReg}
                value={otherReg?.regNumber || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="rotu"
                name="rotu"
                placeholder="rotu"
                onChange={handleOtherReg}
                value={otherReg?.rotu || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                freeSolo
                label="rekisteri"
                name="rekisteri"
                placeholder="rekisteri"
                onChange={handleOtherReg}
                options={registries}
                getOptionLabel={(option) => option}
                value={otherReg?.rekisteri || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="rekisteri"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOtherRegAdd}
                sx={{ mr: 2, float: "right" }}
              >
                <Add fontSize="small" sx={{ mr: 1 }} /> Add row
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Certificates</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <CertificatesList
            certs={initialDog?.certificates}
            onDeleteCert={onDeleteCert}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 1 }}>
                  Add new Certificate
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Certificate</InputLabel>
                  <Select
                    fullWidth
                    key={initialDog?.certificates}
                    label="Certificate"
                    name="certificate"
                    placeholder="Certificate"
                    onChange={handleNewCertChange}
                    value={newCert.certificate}
                  >
                    {certificates.map((cert) => (
                      <MenuItem key={cert._id} value={cert}>
                        {cert.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <DatePicker
                  label="Issuing date"
                  name="issuingDate"
                  value={newCert?.issuingDate || null}
                  onChange={(newVal) => {
                    setNewCert({
                      ...newCert,
                      issuingDate: dayjs(newVal).format(),
                    });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  onClick={onAddNewCert}
                  startIcon={<Add />}
                >
                  Add certificate
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Owners</Typography>
          </Divider>{" "}
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            label="Owners"
            name="owners"
            placeholder="Owners"
            onChange={handleOwnerChange}
            options={users}
            isOptionEqualToValue={(option, value) => option._id == value._id}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            value={owners}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name} {" ("}
                {option.email}
                {") "}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Owners"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Owners preview</Typography>
          <List>
            {owners &&
              owners?.map((owner) => {
                return (
                  <ListItem key={owner._id} secondaryAction={owner.breederId}>
                    <ListItemText
                      primary={`${owner.name}, ${owner.city}`}
                      secondary={`${owner.email} 
                            (${owner._id})`}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Breeder</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            label="Breeder"
            name="breeder"
            placeholder="Breeder"
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, "breeder")
            }
            options={users}
            isOptionEqualToValue={(option, value) => option._id == value}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            value={selectedBreeder}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name} {" ("}
                {option.email}
                {") "}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Breeder"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Breeder preview</Typography>

          <List>
            {selectedBreeder && (
              <ListItem secondaryAction={selectedBreeder.regNumber}>
                <ListItemText
                  primary={`${selectedBreeder.name}, ${selectedBreeder.city || "no city"
                    }`}
                  secondary={`${selectedBreeder.email} (${selectedBreeder._id})`}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Family</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <ParentAutocomplete
            label="Sire"
            name="sire"
            handleChange={handleAutocompleteChange}
            options={maleDogs}
            value={selectedSire}
          />
          <List sx={{ mt: 2 }}>
            <ListItem>
              <Typography variant="h5">Sire preview</Typography>
            </ListItem>
            {selectedSire && (
              <ListItem>
                <ListItemButton
                  href={`/admin/dog/${selectedSire._id}`}
                  target="_blank"
                >
                  <ListItemIcon>
                    <Launch />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${selectedSire.name} ${selectedSire.regNumber}`}
                    secondary={`${selectedSire._id}`}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={6}>
          <ParentAutocomplete
            label="Dam"
            name="dam"
            handleChange={handleAutocompleteChange}
            options={femaleDogs}
            value={selectedDam}
          />
          <List sx={{ mt: 2 }}>
            <ListItem>
              <Typography variant="h5">Dam preview</Typography>
            </ListItem>
            {selectedDam && (
              <ListItem>
                <ListItemButton
                  href={`/admin/dog/${selectedDam._id}`}
                  target="_blank"
                >
                  <ListItemIcon>
                    <Launch />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${selectedDam.name} ${selectedDam.regNumber}`}
                    secondary={`${selectedDam._id}`}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Appearance</Typography>
          </Divider>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Color</InputLabel>
            <Select
              fullWidth
              multiple
              label="Color"
              name="color"
              placeholder="Color"
              onChange={handleChange}
              value={initialDog?.color || []}
            >
              <ListSubheader>Colors (select one)</ListSubheader>
              {kruValues
                .filter((i) => i.category === "coat.colors")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color.en.name}
                  </MenuItem>
                ))}
              <ListSubheader>Markings (select multiple)</ListSubheader>
              {kruValues
                .filter((i) => i.category === "coat.markings")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color.en.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Coat</InputLabel>
            <Select
              fullWidth
              multiple
              label="Coat"
              name="coat"
              placeholder="Coat"
              onChange={handleChange}
              value={initialDog?.coat || []}
            >
              <ListSubheader>Coat types (select one)</ListSubheader>
              {kruValues
                .filter((i) => i.category === "coat.types")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color.en.name}
                  </MenuItem>
                ))}
              <ListSubheader>Coat qualities (select multiple)</ListSubheader>
              {kruValues
                .filter((i) => i.category === "coat.qualities")
                .map((color) => (
                  <MenuItem key={color._id} value={color._id}>
                    {color.en.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Height at withers (cm)"
            name="height"
            placeholder="Height at withers (cm)"
            onChange={handleChange}
            value={initialDog?.height || ""}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Weight (kg)"
            name="weight"
            placeholder="Weight (kg)"
            onChange={handleChange}
            value={initialDog?.weight || ""}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">Genetic Breed Test</Typography>
          </Divider>
        </Grid>
        {initialDog?.breedtest ? (
          <>
            <Grid item xs={6}>
              <Typography variant="caption" sx={{ display: "block" }}>
                Source
              </Typography>
              <Typography variant="body">
                {initialDog?.breedtest?.source}
              </Typography>
              <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                Date
              </Typography>
              <Typography variant="body">
                {initialDog?.breedtest?.date &&
                  dayjs(initialDog?.breedtest?.date).format("DD.MM.YYYY")}
              </Typography>

              <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                Subject
              </Typography>
              <Typography variant="body">
                {initialDog?.breedtest?.subject}
              </Typography>

              <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
                Additional info
              </Typography>
              <Typography variant="body">
                {initialDog?.breedtest?.additionalInfo}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <List>
                <ListItem>
                  <Typography variant="h5">Breeds</Typography>
                </ListItem>
                {initialDog?.breedtest?.breeds?.map((breed, i) => {
                  return (
                    <ListItem key={i}>
                      <Typography variant="body" sx={{ mr: 2 }}>
                        {breed?.name}
                      </Typography>
                      <Typography variant="body">
                        {breed.percentage} {"%"}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="body">
              {"Dog doesn't have breed test"}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack alignItems="flex-start">
            <Button variant="outlined" onClick={handleModalOpen}>
              Edit breed test
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormModal open={modalOpen} onClose={handleBreedtestModalClose}>
            <BreedTestComponent
              initialDog={initialDog}
              formType={formType}
              handleModalClose={handleBreedtestModalClose}
            />
          </FormModal>
        </Grid>
        {action === "edit" && (
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Attachments</Typography>
            </Divider>
            <AttachmentList parentDoc={initialDog} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Changes preview (raw JSON)
            </Typography>
          </Divider>
          <Box sx={{ width: "400px", my: 4 }}>
            {updatedDog
              ? JSON.stringify(updatedDog, null, "\t")
              : "No changes to update"}
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {action === "edit" && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              sx={{ mr: 2, float: "left" }}
            >
              <DeleteForever fontSize="small" sx={{ mr: 1 }} /> Delete
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!showSubmit}
            onClick={handleSubmit}
            sx={{ mr: 2, float: "right" }}
          >
            <Save fontSize="small" sx={{ mr: 1 }} /> Save changes
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default DogForm;
