import React from "react";

import { getLitterColumns } from "../../../components/adminDashboardComponents/adminDatagridColumns";

// MUI
import { Typography, Divider, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { adminAllLittersState } from "../../../recoil/adminStates";
import { useRecoilValue } from "recoil";

function AdminAllLitters() {
  const litters = useRecoilValue(adminAllLittersState);

  const handleRowClick = (params) => {
    window.location = `/admin/litter/${params.row._id}`;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">All litters</Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={litters}
            getRowId={(row) => row._id}
            columns={getLitterColumns("litter")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminAllLitters;
