import React from "react";
import DogInformation from "../formComponents.js/dogInformation";

// Steps
import DogOwner from "../formComponents.js/dogOwner";

export default function DogRegistrationForm({
  dog,
  setDog,
  formErrors,
  checkboxes,
  setCheckboxes,
}) {
  return (
    <>
      <DogOwner
        dog={dog}
        setDog={setDog}
        formErrors={formErrors}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
      <DogInformation dog={dog} setDog={setDog} formErrors={formErrors} />
    </>
  );
}
