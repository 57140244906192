import {
  createCheckout,
  createStatistic,
  createVerification,
  registerLitter,
  registerPuppy,
} from "../../api/privateRoutes";

export const sendLitterRegDataToApi = async (litter, user, accessToken) => {
  try {
    const puppyIds = [];

    // Map puppies into correct form
    // Note: owners -field is not needed, API assigns the current user automatically as an owner
    const mappedPuppies = litter.puppies.map((puppy) => {
      puppy.markings.unshift(puppy.color);
      puppy.coatqualities.unshift(puppy.coattype);
      return {
        name: puppy.name,
        microchipNumber: puppy.microchipNumber,
        color: puppy.markings,
        coat: puppy.coatqualities,
        regType: "reg.types.litter",
        regClass: "reg.classes.pet",
        organizations: [litter.registry._id],
        countryOfOrigin: litter.countryOfOrigin.code,
        breeder: user,
        sire: litter.sire._id,
        dam: litter.dam._id,
        dateOfBirth: litter.dateOfBirth,
      };
    });

    // Create puppies as tempdogs
    for (const puppy of mappedPuppies) {
      const newpuppy = await registerPuppy(puppy, { accessToken });
      puppyIds.push(newpuppy._id);
    }

    // Map litter into correct form
    // Do not send _id
    const { _id, ...transformedLitter } = {
      ...litter,
      puppies: puppyIds,
      sire: litter.sire._id,
      dam: litter.dam._id,
      dateOfBirth: litter.dateOfBirth,
      breeder: user,
    };
    // Create templitter
    const newLitter = await registerLitter(transformedLitter, { accessToken });

    // Create statistics
    await createStatistic(litter.stats, { accessToken });

    const verifications = [];

    // Create verification objects based on needed verifications
    if (litter.verificationNeeded.sire === "email") {
      const verification = {
        operation: "verifyLitterSire",
        parentDoc: litter._id,
        parentDocType: "templitter",
      };
      verifications.push(createVerification(verification, { accessToken }));
    }
    if (litter.verificationNeeded.dam === "email") {
      const verification = {
        operation: "verifyLitterDam",
        parentDoc: newLitter._id,
        parentDocType: "templitter",
      };
      verifications.push(createVerification(verification, { accessToken }));
    }
    const savedAttachments = await Promise.all(verifications);

    // Create checkout for payment and redirect to Stripe
    const order = await createCheckout({
      accessToken,
      parentDoc: newLitter._id,
      parentDocType: "templitter",
    });
    if (!order.url) throw Error(`Cannot redirect to Stripe`);
    window.location.assign(order.url);
  } catch (error) {
    console.error(error);
    return error;
  }
};
